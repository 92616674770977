<template lang="pug">
  .multi-factor-authentication
    .title-container
      .title {{ $t('profile.security.mfa') }}
      fpui-input-toggle(
        :key="mfaActivate"
        :value="mfaActivate"
        @input="activateMfa()"
      )
    .text {{ $t('profile.security.mfa.content') }}
    .factor-choice(v-if="mfaActivate && !isMfaActive")
      .factors-container
        .factor(:class="{active: activedMfa === mfa.id, disabled: mfa.disabled && mfa.disabled() }" v-for="mfa in mfaList" @click="onSelect(mfa.id,mfa.disabled && mfa.disabled())", v-tooltip="mfa.tooltip && mfa.tooltip()")
          i(:class="mfa.icon")
          .name {{ mfa.name }}

      .mfa-authenticator(v-if="activedMfa === 'authenticator'")
        .qr-code-container(v-if="config.qr")
          img(:src="config.qr")
          fpui-helper(
            v-if="config.base32"
            :text="$t('profile.mfa.qr_helper',[config.base32])"
          )
        .code-container
          .code-label
            .text {{ $t('profile.mfa.authenticator.code') }}
            fpui-helper(:text="$t('profile.mfa.authenticator.code.helper')")
          fpui-input-text(
            :placeholder="$t('profile.mfa.authenticator.code.placeholder')"
            v-model="code"
          )
      .mfa-sms(v-if="activedMfa === 'sms'")
        .send-button
          fpui-button(
            color='blue'
            @click="sendSMS()"
            :disabled="!canSend"
            auto-width
          ) {{ $t('profile.mfa.sms.send') }}
        .code-container
          .code-label
            .text {{ $t('profile.mfa.authenticator.code') }}
            fpui-helper(:text="$t('profile.mfa.sms.code.helper')")
          fpui-input-text(
            :placeholder="$t('profile.mfa.authenticator.code.placeholder')"
            v-model="code"
          )
      //- .mfa-yubikey(v-if="activedMfa === 'yubikey'")
      //-   .code-container
      //-     .code-label
      //-       .text {{ $t('profile.mfa.yubikey.code') }}
      //-     fpui-input-text(
      //-       :placeholder="$t('profile.mfa.authenticator.code.placeholder')"
      //-       v-model="code"
      //-     )
      .confirm-button(v-if="mfaActivate")
        fpui-button(
          color='green'
          @click="confirm()"
          :disabled="!code"
          auto-width
        ) {{ $t('confirm') }}

    .mfa-active-container(v-if="isMfaActive")
      .mfa-active
        .info
          i(:class="getMfaByType(isMfaActive.type).icon")
          span {{ getMfaByType(isMfaActive.type).name }}
        .actions
          i.fp4.fp4-copy(v-if="authenticatorResponseCodes.length" @click="copy()",v-tooltip="$t('profiles.mfa.codes.copy.tooltip')")
          i.fa.fa-print(v-if="authenticatorResponseCodes.length" @click="print()",v-tooltip="$t('profiles.mfa.codes.print.tooltip')")
          i.fp4.fp4-down-to-line(v-if="authenticatorResponseCodes.length" @click="download()",v-tooltip="$t('profiles.mfa.codes.download.tooltip')")
          i.fp4.fp4-trash-can(v-if="!authenticatorResponseCodes.length" @click="removeMfa()",v-tooltip="$t('profiles.mfa.codes.remove.tooltip')")
      .codes-container(v-if="authenticatorResponseCodes.length")
        .codes-description {{ $t('profile.mfa.codes.description') }}
        ul.codes(ref="codes-print")
          li.code(v-for="code in authenticatorResponseCodes") {{ code }}
        .codes-warning {{ $t('profile.mfa.codes.warning') }}
        .codes-button
          fpui-button(
            color='green'
            :disabled="!canClose"
            @click="clearCodes()"
            auto-width
          ) {{ $t('profile.mfa.codes.done') }}
</template>
<script>
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  data () {
    return {
      mfaActivate: false,
      activedMfa: 'authenticator',
      config: {},
      authenticatorResponseCodes: [],
      code: '',
      canClose: false,
      canSend: true,
      mfaList: [{
        id: 'authenticator',
        name: this.$t('profile.mfa.authenticator'),
        icon: 'fp4 fp4-authenticator'
      }, {
        id: 'sms',
        name: this.$t('profile.mfa.sms'),
        tooltip: () => {
          return this.$t('profile.mfa.sms.required.phone')
        },
        disabled: () => {
          return !this.user.getMainProfile().phone?.number
        },
        img: 'sms.png',
        icon: 'fp4 fp4-message-lines'
      }]
      // , {
      //   id: 'yubikey',
      //   name: this.$t('profile.mfa.yubikey'),
      //   icon: 'fp4 fp4-usb-drive'
      // }]
    }
  },
  computed: {
    user () {
      return this.$store.getters.SESSION
    },
    isMfaActive () {
      return this.user.mfa.find(mfa => mfa.active)
    }
  },
  mounted () {
    this.mfaActivate = this.user.mfa.filter(mfa => mfa.active).length > 0
  },
  methods: {
    async activateMfa () {
      if (this.isMfaActive) {
        const res = await this.removeMfa()
        this.mfaActivate = !res
      } else {
        this.mfaActivate = !this.mfaActivate
        if (this.mfaActivate && this.activedMfa === 'authenticator') this.onSelect('authenticator')
      }
    },
    async getQrConfig () {
      const res = await this.user.addMfaConfiguration(this.activedMfa)
      if (res.qr && res.qr.indexOf('data:image/png;base64') === -1) res.qr = 'data:image/png;base64,' + res.qr
      this.config = res
    },
    async onSelect (id, disabled = false) {
      if (disabled) return
      if (this.activedMfa !== id || id === 'authenticator') {
        this.activedMfa = id
        this.code = ''
        if (id === 'authenticator') {
          await this.getQrConfig()
        }
      }
    },
    async confirm () {
      if (this.code && this.mfaActivate) {
        try {
          const res = await this.user.activateMfaConfiguration(this.activedMfa, { code: this.code, type: this.activedMfa })
          // Refresh session data
          await this.$store.dispatch('REFRESH')
          this.authenticatorResponseCodes = res.content.codes
          // clear input codes
          this.codes = ''
          this.$fpuiMessageBlock.success('Success')
        } catch (err) {
          this.$fpuiMessageBlock.error(err)
        }
      }
    },
    getMfaByType (type) {
      return this.mfaList.find(mfa => mfa.id === type)
    },
    copy () {
      const tmpStr = this.authenticatorResponseCodes.join('\n')
      this.$copyText(tmpStr)
      this.canClose = true
      this.$fpuiMessageBlock.pop('success', this.$t('profiles.mfa.codes.copy.success'))
    },
    print () {
      const content = this.$refs['codes-print']
      const newWin = window.open('', 'Print-Window')
      newWin.document.open()
      newWin.document.write('<html><body onload="window.print()">' + content.innerHTML + '</body></html>')
      newWin.document.close()
      this.canClose = true
    },
    download () {
      const link = document.createElement('a')
      link.setAttribute('href', 'data:text;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.authenticatorResponseCodes))
      link.setAttribute('download', 'codes.txt')
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.canClose = true
    },
    clearCodes () {
      this.authenticatorResponseCodes = []
      this.canClose = false
    },
    async removeMfa () {
      if (this.isMfaActive?.type) {
        const confirmResult = await ConfirmModal(this, {
          contentHtml: true,
          content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} <span style="font-weight:600"> ${this.isMfaActive.type}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
          title: this.$t('profile.mfa.codes.delete'),
          yes: {
            text: this.$t('fpui-modal-confirm.remove'),
            color: 'red'
          }
        })
        if (!confirmResult) return false
        try {
          await this.user.removeMfa(this.isMfaActive.type)
          // Refresh session data
          await this.$store.dispatch('REFRESH')
          this.onSelect('authenticator')
          this.$fpuiMessageBlock.success('Success')
          return true
        } catch (err) {
          this.$fpuiMessageBlock.error(err)
          return false
        }
      }
    },
    async sendSMS () {
      this.canSend = false
      try {
        await this.user.addMfaConfiguration(this.activedMfa)
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      // Wait 5s for the next send action
      setTimeout(() => {
        this.canSend = true
      }, 5000)
    }
  }
}
</script>
<style lang="less">
  .multi-factor-authentication {
    width: 100%;
    .title-container {
      display: flex;
      justify-content: space-between;
      .fpui-input-toggle {
        width: auto;
      }
    }
    .factor-choice {
      .factors-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        .factor {
          margin-right: 30px;
          width: 146px;
          height: 83px;
          border-radius: 5px;
          border: 0.5px solid rgba(151,167,183,0.21);
          box-shadow: 0 2px 4px 0 rgba(52,65,78,0.05);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: @text-color;
          i {
            font-size: 30px;
            margin-bottom: 2px;
          }
          &.active, &:hover {
            cursor: pointer;
            color: @blue !important;
            font-weight: 600;
            border: 2px solid @blue;
            i {
              color: @blue;
            }
          }
          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
        .factor:last-child {
          margin-right: 0px;
        }
      }
      .mfa-authenticator {
        min-height: 300px;
        margin-top: 30px;
        text-align: center;
        .qr-code-container {
          margin: 10px;
          margin-bottom: 0;
          position:relative;
          .fpui-helper {
            position:absolute;
            right: 134px;
            bottom: 15px;
          }
          img {
            opacity: 0.7;
            width: 207px;
          }
        }
        .code-container {
          width: 211px;
          margin: auto;
          margin-top: 20px;
          .code-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
          }
        }
      }
      .mfa-sms {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .send-button {
          margin-top: 50px;
          margin-bottom: 20px;
        }
        .code-container {
          width: 211px;
          margin: auto;
          margin-top: 20px;
          .code-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
          }
        }
      }
      // .mfa-yubikey {
      //   width: 211px;
      //   margin: auto;
      //   margin-top: 40px;
      //   .code-label {
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     margin-bottom: 10px;
      //   }
      // }
      .confirm-button {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .mfa-active-container {
      box-shadow: 0 2px 4px 0 rgba(52,65,78,0.05);
      border: 0.5px solid rgba(151,167,183,0.21);
      border-radius: 5px;
      width: 100%;
      margin-top: 28px;
      .mfa-active {
        padding: 11px 11px 11px 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info {
          display: flex;
          align-items: center;
          color: @blue;
          font-size: 14px;
          font-weight: 600;
          i {
            font-size: 36px;
            color: @blue;
            margin-right: 5px;
          }
        }
        .actions {
          display: flex;
          align-items: center;
          i {
            font-size: 20px;
            color: rgba(151,167,183,0.5);
          }
          :hover {
            color: @blue;
          }
          .fp4-trash-can:hover {
            color: @pink;
          }
          .fp4-copy, .fa-print {
            margin-right: 10px;
          }
        }
      }
      .codes-container {
        padding: 20px 30px 20px 30px;
        border-top: 1px solid rgba(151,167,183,0.21);
        .codes-description, .codes-warning {
          font-size: 14px;
          line-height: 16px;
        }
        .codes {
          padding: 20px 0px 10px 0;
          margin: auto;
          .code {
            text-align:left;
            display:inline-block;
            width: 33%;
            line-height: 24px;
            font-size: 15px;
            color: @grey;
          }
          .code:before {
            position: relative;
            top: -2px;
            margin-right: 7px;
            font-size: 7px;
            color: @grey;
            content: "\25CF";
          }
        }
        .codes-button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
        }
      }
    }
  }
</style>
