<template lang="pug">
  .data-storage-engine-configuration.fp-container
    fp-loader(v-if="loading")
    .fp-column
      .fp-box
        .box-title.with-helper
          span {{ $t('organization.data_storage_engine.default_configuration') }}
          fpui-helper(
            position='top'
            :text="$t('organization.data_storage_engine.default_configuration.helper')"
            :title="$t('organization.data_storage_engine.default_configuration.helper_title')"
          )
        .box-content
          .config
            label {{ $t('organization.data_storage_engine.configuration.data_prim') }}
            img.loading(
              v-if="isUpdating('data_prim')"
              src="@/core/assets/img/ic_spinner.png"
            )
            fpui-input-select(
              :value='configrurationDetails.data_prim'
              :options="instancesOptions"
              @input="(value) => update('data_prim', value)"
              :disabled="isUpdating('data_prim') || !$acl(organizationId).can('core', 'storage_config', 'update')"
              :clearable="false"
            )
          .config
            label {{ $t('organization.data_storage_engine.configuration.data_mart') }}
            img.loading(
              v-if="isUpdating('data_mart')"
              src="@/core/assets/img/ic_spinner.png"
            )
            fpui-input-select(
              :value='configrurationDetails.data_mart'
              :options="instancesOptions"
              @input="(value) => update('data_mart', value)"
              :disabled="isUpdating('data_mart') || !$acl(organizationId).can('core', 'storage_config', 'update')"
              :clearable="false"
            )
          .config
            label {{ $t('organization.data_storage_engine.configuration.data_ml') }}
            img.loading(
              v-if="isUpdating('data_ml')"
              src="@/core/assets/img/ic_spinner.png"
            )
            fpui-input-select(
              :value='configrurationDetails.data_ml'
              :options="instancesOptions"
              @input="(value) => update('data_ml', value)"
              :disabled="isUpdating('data_ml') || !$acl(organizationId).can('core', 'storage_config', 'update')"
              :clearable="false"
            )
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    instances: { type: Array, default: () => [] },
    configuration: { type: Object, default: () => {} },
    configurationNotReady: { type: Object, default: () => ({}) },
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    configrurationDetails () {
      return this.configuration?.configuration || {}
    },
    instancesOptions () {
      return this.instances.map(instance => {
        return {
          label: instance.display_name,
          value: instance.id,
          image: require(`@/core/assets/img/engines/${instance.engine}@2x.png`)
        }
      })
    }
  },
  methods: {
    async update (key, val) {
      this.configuation?.backup()
      const newConfig = _cloneDeep(this.configuration)
      newConfig.configuration[key] = val
      this.configuration.update('configuration', newConfig.configuration, false)
      this.loading = true
      try {
        await this.configuration.save()
        this.$store.commit('STORAGE_CONFIGURATIONS_UPDATE', newConfig)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        this.configuation.restore()
      }
      this.loading = false
    },
    isUpdating (key) {
      return this.configurationNotReady[key]
    }
  }
}
</script>

<style lang="less">
.data-storage-engine-configuration {
  .fp-box {
    height: 170px;

    .box-content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .config {
        width: 50%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &:nth-child(2n) {
          margin-left: 63px;
          width: calc(~"50% - 63px");
        }

        label {
          color         : #3E4550;
          font-size     : 14px;
          vertical-align: middle;
          font-weight   : 500;
          line-height   : 34px;
        }

        .fpui-input-select-container {
          width: 211px;

          .fpui-input-select > .image {
            width: 17px;
          }
          li > .image {
            width: 17px;
          }
        }

        .loading {
          animation: rotating 1s infinite;
          -webkit-animation: rotating 1s infinite;
          width: 20px;
          position: absolute;
          right: 220px;
        }
      }
    }
  }

  @keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
  }
}
</style>
