<template lang="pug">
.waiting-for-approval
  h4 {{ $t('waiting_approval.title') }}
  .text {{ $t('waiting_approval.text') }}
  .button
    fpui-button(
      color="blue-flash"
      @click="requestToJoin()"
      auto-width
      v-if="!requestSent"
    ) {{ $t('waiting_approval.resend') }}
    .pending(v-else)
      i.fp4.fp4-circle-check
      span {{ $t('waiting_approval.resend.request_sent') }}
</template>

<script>
export default {
  props: {
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      requestSent: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params)
    }
  },
  methods: {
    async requestToJoin () {
      this.loading = true
      try {
        const req = await this.organization.addRequest()
        this.$fpuiMessageBlock.pop('success', req.message)
        this.$analytics.track('Resend request access to an organization', {
          organization_id: this.organization._id
        })
        this.requestSent = true
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
.waiting-for-approval {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-image: url('./assets/organization@2x.png');
  background-position: 50% 50px;
  background-repeat: no-repeat;
  background-size: 682px 256px;

  h4 {
    font-size: 32px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    line-height: 40px;
    color: #3E4550;
    margin-bottom: 21px;
    margin-top: 295px !important;
  }

  .text {
    width: 337px;
    color: #3E4550;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
  }

  .button {
    display: flex;
    justify-content: center;
    .fpui-button {
      width: 124px;
      margin-top: 44px;
    }

    .pending {
      display: flex;
      align-content: center;
      margin-top: 44px;
      i {
        font-size: 16px;
        color: #9EDF10;
      }
      span {
        margin-left: 5px;
        color: #97A7B7;
        font-style: italic;
        line-height: 15px;
      }
    }
  }
}
</style>
