<template lang="pug">
.preferences.fp-container
  fp-loader(v-if="loading")
  .title {{ $t('profile.preferences.contact_info') }}
  .name-picture
    .name-phone-gender
      .name
        fpui-input-text(
          :label="$t('profile.preferences.firstname')"
          :value="localProfile.given_name"
          @input="(value) => updateProfile('given_name', value)"
        )
        fpui-input-text(
          :label="$t('profile.preferences.lastname')"
          :value="localProfile.family_name"
          @input="(value) => updateProfile('family_name', value)"
        )
      .phone-gender
        .phone
          .input-title {{ $t('profile.preferences.phone') }}
          fpui-input-phone(
            :showErrorMessage="false"
            :value="localProfile.phone.number"
            @input="(value) => updateProfile('phone.number', value)"
            :preferedCountries="['fr', 'us']"
          )
        .gender
          .input-title {{ $t('profile.preferences.gender') }}
          fpui-input-radio(
            :value="localProfile.gender"
            @input="(value) => updateProfile('gender', value)"
            :options="genderOptions"
          )
    .picture.edition
      input.upload-button(type="file" accept="image/*" @change="onFileUpload($event.target.files)")
      .delete-button(@click="removePicture()")
      img.center-cropped(:src="picture")
      .img-overlay
        label.fp4.fp4-pencil
        label.fp4.fp4-trash-can

  .input-container
    fpui-input-email(
      :label="$t('profile.preferences.email')"
      disabled
      v-model="user.email"
    )

  .input-container
    fpui-input-select(
      :label="$t('profile.preferences.role')"
      :value="localProfile.company_role"
      @input="(value) => updateProfile('company_role', value)"
      :options="roleOptions"
    )
  .information
    .title {{ $t('profile.preferences.preferences') }}
    fpui-input-toggle(
      :label="$t('profile.preferences.receive')"
      :helper="$t('profile.preferences.popover_content')"
      :helperTitle="$t('profile.preferences.popover_title')"
      :value="user.consent_details.receive_promotional_content"
      @input="(value) => user.update('consent_details.receive_promotional_content', value, false)"
    )
    fpui-button.cookies-settings(
      auto-width
      color="white"
      @click="openAxeptioCookies"
    ) {{ $t('profile.preferences.cookies-settings') }}


  .organizations
    .title-container
      .title {{ $t('profile.preferences.organizations') }}
      fpui-helper(:text="$t('profile.preferences.organizations.tooltip')")
    fpui-table(
      :columns='organizations.columns'
      :actions='organizations.actions'
      :data='organizations.data'
      sortOn="name"
      :pagination='{ perPage: 5 }'
    )
  .related-organizations
    .title-container
      .title {{ $t('profile.preferences.related_organizations') }}
      fpui-helper(:text="$t('profile.preferences.related_organizations.tooltip')")
    .related-organization.content(
      v-for="orga in relatedOrganizations"
      v-if="relatedOrganizations.length"
    )
      .name {{ orga.name }}
      .actions
        //- fpui-button(
        //-   color="white"
        //-   @click="hideRelatedOrganization(orga)"
        //-   auto-width
        //-   noshadow
        //- ) {{ $t('profile.preferences.related_organizations.hide') }}
        fpui-button.done(
          color="blue-flash"
          @click="requestToJoin(orga)"
          auto-width
        ) {{ $t('profile.preferences.related_organizations.request') }}
    .no-data(v-if="!relatedOrganizations.length") {{ $t('profile.preferences.related_organizations_no_date') }}
  .invites-pending
    .title-container
      .title {{ $t('profile.preferences.invites_pending') }}
      fpui-helper(:text="$t('profile.preferences.invites_pending.tooltip')")
    .invite-pending.content(
      v-for="orga in invitesPending"
      v-if="invitesPending.length"
    )
      .name {{ orga.name }}
      .actions
        fpui-button(
          color="white"
          @click="cancelInvitePending(orga)"
          auto-width
          noshadow
        ) {{ $t('profile.preferences.invites_pending.cancel') }}
        fpui-button.done(
          color="blue-flash"
          @click="requestToJoin(orga, true)"
          auto-width
        ) {{ $t('profile.preferences.invites_pending.resend') }}
    .no-data(v-if="!invitesPending.length") {{ $t('profile.preferences.invites_pending_no_date') }}
  .invites-received
    .title-container
      .title {{ $t('profile.preferences.invites_received') }}
        fpui-helper(:text="$t('profile.preferences.invites_received.tooltip')")
    .invite-received.content(
      v-for="orga in invitesReceived"
      v-if="invitesReceived.length"
    )
      .name {{ orga.name }}
      .actions
        fpui-button(
          color="white"
          @click="rejectInviteInOrganization(orga)"
          auto-width
          noshadow
        ) {{ $t('profile.preferences.invites_received.cancel') }}
        fpui-button.done(
          color="blue-flash"
          @click="acceptInviteInOrganization(orga)"
          auto-width
        ) {{ $t('profile.preferences.invites_received.accept') }}
    .no-data(v-if="!invitesReceived.length") {{ $t('profile.preferences.invites_received_no_date') }}
</template>
<script>
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import _findIndex from 'lodash/findIndex'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  props: {
    user: { type: Object, required: true }
  },
  data () {
    return {
      localProfile: { phone: { number: '' } },
      picture: require('@/core/assets/img/default_avatar.png'),
      roleOptions: [
        { value: 'innovation', label: this.$t('profile.preferences.role.innovation') },
        { value: 'data_scientist', label: this.$t('profile.preferences.role.data_scientist') },
        { value: 'data_engineer', label: this.$t('profile.preferences.role.data_engineer') },
        { value: 'software_engineer', label: this.$t('profile.preferences.role.software_engineer') },
        { value: 'software_engineer_front', label: this.$t('profile.preferences.role.software_engineer-front') },
        { value: 'software_engineer_back', label: this.$t('profile.preferences.role.software_engineer-back') },
        { value: 'data_analyst', label: this.$t('profile.preferences.role.data_analyst') },
        { value: 'data_viz', label: this.$t('profile.preferences.role.data_viz') },
        { value: 'software_architect', label: this.$t('profile.preferences.role.software_architect') },
        { value: 'it_manager', label: this.$t('profile.preferences.role.it_manager') },
        { value: 'product_manager', label: this.$t('profile.preferences.role.product_manager') },
        { value: 'project_manager', label: this.$t('profile.preferences.role.project_manager') },
        { value: 'business_user_leader', label: this.$t('profile.preferences.role.business_user-leader') },
        { value: 'business_user_manager', label: this.$t('profile.preferences.role.business_user-manager') },
        { value: 'growth_engineer', label: this.$t('profile.preferences.role.growth_engineer') }
      ],
      genderOptions: [
        { value: 'male', label: this.$t('profile.preferences.male') },
        { value: 'female', label: this.$t('profile.preferences.female') },
        { value: 'other', label: this.$t('profile.preferences.other') }
      ],
      loading: false
    }
  },
  computed: {
    relatedOrganizations () {
      return this.$store.getters.AVAILABLE_ORGANIZATIONS
    },
    invitesPending () {
      return this.$store.getters.REQUESTED_ORGANIZATIONS
    },
    invitesReceived () {
      return this.$store.getters.INVITING_ORGANIZATIONS.filter(organization => {
        if (organization.accounts) return organization.accounts.find(acc => acc.account?._id === this.user._id && !acc.accepted)
        if (organization.users) return organization.users.find(u => u.user?._id === this.user._id && !u.accepted)
        return false
      })
    },
    organizationUserIds () {
      return this.$store.getters.ORGANIZATIONS.map(orga => orga._id)
    },
    organizations () {
      const organizations = {
        data: this.user.organizations.filter(orga => this.organizationUserIds.includes(orga._id)),
        actions: [
          {
            id: 'go',
            icon: 'fp4 fp4-eye',
            click: async (row) => {
              this.$router.push(`/organization/${row._id}`)
            },
            tooltip: this.$t('profile.preferences.organizations.go_tooltip')
          }, {
            id: 'leave',
            tooltip: this.$t('profile.preferences.organizations.leave_tooltip'),
            if: (row) => {
              const orga = this.$store.getters.ORGANIZATIONS.find(orga => orga._id === row._id)
              const userLastOrga = this.user.organizations ? this.user.organizations.length <= 1 : true
              const users = orga?.accounts || orga?.users
              const orgaLastUser = users?.length <= 1
              return !userLastOrga && !orgaLastUser
            },
            icon: 'fp4 fp4-up-from-line',
            click: async row => {
              const orga = this.$store.getters.ORGANIZATIONS.find(orga => orga._id === row._id)
              const confirmResult = await ConfirmModal(this, {
                contentHtml: true,
                title: this.$t('organization.settings.delete_organization.title', [orga.name]),
                yes: {
                  text: this.$t('fpui-modal-confirm.yes'),
                  color: 'red'
                }
              },
              {
                height: 'auto',
                width: 550
              })
              if (!confirmResult) return
              try {
                this.loading = true
                const response = await orga.removeUser(this.user.email)
                this.$store.commit('REMOVE_ORGANIZATION', orga._id)
                const newOrganizations = this.user.organizations.filter(org => org._id !== row._id)
                this.user.update('organizations', newOrganizations, true)
                this.$fpuiMessageBlock.pop('success', response.message)
              } catch (err) {
                this.$fpuiMessageBlock.error(err)
              }
              this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
              this.loading = false
            }
          }
        ],
        columns: [{
          name: 'name',
          label: this.$t('profile.preferences.organizations.name'),
          align: 'left',
          target: 'name',
          paddingLeft: 17
        }, {
          name: 'role',
          label: this.$t('profile.preferences.organizations.role'),
          align: 'left',
          target: row => this.$t(`profile.preferences.organization.${row.role}`)
        }, {
          name: 'join_on',
          label: this.$t('profile.preferences.organizations.join_on'),
          align: 'left',
          target: row => {
            const orga = this.$store.getters.ORGANIZATIONS.find(orga => orga._id === row._id)
            if (!orga) return ''
            const users = orga.accounts || orga.users
            const user = users.find(acc => acc.account?._id === this.user._id || acc.user?._id === this.user._id)
            return user?.joinAt ? `${moment(user.joinAt).format('DD.MM.YYYY')}` : ''
          }
        }]
      }
      return organizations
    }
  },
  created () {
    this.localProfile = _cloneDeep(this.user.profile.find(p => p.main) || this.user.profile[0])
    this.localProfile.phone = this.localProfile.phone || {}
    this.localProfile.gender = this.localProfile.gender || 'other'
    if (this.localProfile.picture) this.picture = this.localProfile.picture
  },
  async mounted () {
    if (!this.$store.getters.AVAILABLE_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
    if (!this.$store.getters.INVITING_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_INVITING_ORGANIZATIONS')
  },
  methods: {
    openAxeptioCookies () {
      window.openAxeptioCookies()
    },
    updateProfile (key, value) {
      if (key === 'phone.number') this.localProfile.phone.number = value
      else this.localProfile[key] = value
      const profileIndex = _findIndex(this.user.profile, { _id: this.localProfile._id })
      if (profileIndex !== -1) {
        this.user.update(`profile[${profileIndex}]`, this.localProfile, false)
      }
    },
    onFileUpload (files) {
      const file = files[0]
      if (!file) return
      const _this = this
      // Base64 read img
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        const dataURL = reader.result
        const scale = 0.9
        _this.compressImg(dataURL, scale, function (result) {
          _this.picture = result
          _this.updateProfile('picture', result)
        })
      }
    },
    compressImg (base64, scale, callback) {
      const img = new Image()
      img.src = base64
      img.onload = function () {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.setAttribute('width', this.width)
        canvas.setAttribute('height', this.height)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        // Transfer to base64
        let tmpImg = canvas.toDataURL('image/jpeg')
        // Limit < 256k
        while (tmpImg.length > 1024 * 100) {
          scale -= 0.1
          tmpImg = canvas.toDataURL('image/jpeg', scale)
        }
        callback(tmpImg)
      }
    },
    async removePicture () {
      if (this.localProfile.picture) {
        const confirmResult = await ConfirmModal(this, {
          contentHtml: true,
          content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} ${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
          title: this.$t('profile.preferences.image.remove'),
          yes: {
            text: this.$t('fpui-modal-confirm.remove'),
            color: 'red'
          }
        })
        if (!confirmResult) return
        this.updateProfile('picture', '')
        this.picture = require('@/core/assets/img/default_avatar.png')
      }
    },
    hideRelatedOrganization (organization) {

    },
    async requestToJoin (organization, resend = false) {
      this.loading = true
      try {
        const response = await organization.addRequest()
        this.$fpuiMessageBlock.pop('success', response.message)

        const messageTracking = resend ? 'Resend request access to an organization' : 'Request access to an organization'
        this.$analytics.track(messageTracking, {
          organization_id: organization._id
        })
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        this.loading = false

        if (!resend) {
          await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
          await this.$store.dispatch('LOAD_ORGANIZATIONS')
          this.$store.commit('SET_ACTIVE_ORGANIZATION', organization._id)
        }
      }
    },
    async cancelInvitePending (organization) {
      this.loading = true
      try {
        const response = await organization.deleteJoin(this.user._id)
        this.$fpuiMessageBlock.pop('success', response.message)
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
        await this.$store.dispatch('LOAD_REQUESTED_ORGANIZATIONS')
        this.loading = false
      }
    },
    async rejectInviteInOrganization (organization) {
      this.loading = true
      try {
        const response = await this.$api.KING.organizations.userRejectJoinFromProfile(organization._id)
        this.$fpuiMessageBlock.pop('success', response.message)
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
        console.error(err)
      } finally {
        this.$store.commit('REMOVE_INVITING_ORGANIZATION', organization._id)
        await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
        this.loading = false
      }
    },
    async acceptInviteInOrganization (organization) {
      this.loading = true
      try {
        const response = await this.$api.KING.organizations.userJoinsFromProfile(organization._id)
        this.$fpuiMessageBlock.pop('success', response.message)
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
        console.error(err)
      } finally {
        await this.$store.dispatch('LOAD_ORGANIZATIONS')
        // If no organization active, we set it when request so the user can see the status in the selector
        this.$store.commit('SET_ACTIVE_ORGANIZATION', organization._id)
        this.$store.commit('REMOVE_INVITING_ORGANIZATION', organization._id)
        this.loading = false
      }
    }
  }
}
</script>
<style lang="less">
@import "~@/shared/styles/_variables.less";

.preferences {
  display: flex;
  flex-direction: column;
  margin: 60px auto;
  width: 550px;
  .title {
    font-size: 12px;
    color: @blue;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 22px;
  }
  .cookies-settings {
    // font-size: 14px;
    // color: #3E4550;
    // cursor: pointer;
    margin-top: 20px;
  }
  .title-helper {
    display: flex;
  }
  .input-container {
    margin-bottom: 15px;
  }
  .input-title {
    font-size: 12px;
    line-height: 15px;
    color: #748294;
  }
  .name-picture {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name-phone-gender {
      width: 420px;
      >.name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        input {
          width: 204px;
        }
      }
      .phone-gender {
        display: flex;
        justify-content: space-between;
        .phone {
          width: 233px;
          .fpui-input-phone {
            width: 100%;
            padding: 0px;
          }
        }
        .gender{
          width: 167px;
          display: flex;
          flex-direction: column;
          .fpui-input-radios {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .fpui-input-radio {
              label:last-child {
                padding-right: 0px;
              }
            }
          }
        }
      }
    }
    .picture {
      position: relative;
      width: 115px;
      height: 115px;
      top: 2px;
      border-radius: 2px;
      .upload-button {
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 5;
        cursor: pointer;
      }
      .delete-button {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        top: 0;
        opacity: 0;
        z-index: 5;
        cursor: pointer;
      }
      .center-cropped {
        object-fit: cover;
        object-position: center;
        height: 115px;
        width: 115px;
      }
      &.edition {
        overflow: hidden;
        &:hover .img-overlay {
          top: 0;
          bottom: 0;
        }
        .img-overlay {
          position: absolute;
          transition: all 300ms ease-in-out;
          top: 100%;
          bottom: -100%;
          left: 0;
          right: 0;
          background-color: rgba(62,69,80,0.78);
          color: @white;
          label {
            position:absolute;
            margin:auto;
            top: 50px;
            font-size: 1.5em;
            width: 40px;
            height: 40px;
            display: block;
            text-align: center;
            transition: all 200ms ease-in-out;
            &:hover {
              background-color: transparent;
              color: #fff;
            }
            &.fp4.fp4-pencil {
              left: 15px
            }
            &.fp4.fp4-trash-can {
              right: 15px
            }
          }
        }
      }
    }
  }
  .information {
    margin-top: 49px;
  }

  .organizations, .related-organizations, .invites-pending, .invites-received {
    margin-top: 49px;

    .title-container {
      display: flex;
      align-items: flex-start;
      .title {
        margin-bottom: 18px;
      }
      .fpui-helper {
        margin-left: 5px;
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      .name {
        color: #3E4550;
        font-size: 16px;
      }

      .actions {
        display: flex;
        align-items: center;
        .fpui-button {
          height: 25px;
          font-size: 11px;
          letter-spacing: 0;
          padding: 5px 8px 6px 8px;
          width: 130px;
          &:last-of-type {
            margin-left: 13px;
          }
        }
      }
    }
    .no-data {
      color: #B2BECA;
      font-size: 12px;
      margin: 0;
      margin-top: -10px;
    }
  }

  .organizations {
    .row-action-leave {
      transform: rotate(90deg);
      .fp4-up-from-line {
        &:hover {
          color: #FF6F90;
        }
      }
    }
  }
}
</style>
