
import Vue from 'vue'

export default {
  state: {
    tickets: [],
    ticketsListFilter: 'open',
    watcher: null,
    watcherId: null
  },
  mutations: {
    SET_TICKETS (state, tickets) {
      state.tickets = tickets
    },
    CREATE_TICKET (state, ticket) {
      state.tickets.push(ticket)
    },
    SET_TICKETS_LIST_FILTER (state, status) {
      state.ticketsListFilter = status
    }
  },
  actions: {
    async LOAD_TICKETS ({ commit, state }) {
      commit('SET_TICKETS', await Vue.api.SUPPORT.list(state.ticketsListFilter))
    },
    async WATCH_TICKETS ({ dispatch, commit, state }) {
      dispatch('UNWATCH_TICKETS')
      const timeoutId = setTimeout(async _ => {
        const tickets = await Vue.api.SUPPORT.list(state.ticketsListFilter)
        if (timeoutId === this.watcher) {
          commit('SET_TICKETS', tickets)
          dispatch('WATCH_TICKETS')
        }
      }, 60000)
      this.watcher = timeoutId
    },
    async UNWATCH_TICKETS () {
      if (this.watcher) clearTimeout(this.watcher)
    },
    async WATCH_TICKET ({ dispatch }, ticket) {
      dispatch('UNWATCH_TICKETS')
      const timeoutId = setTimeout(async _ => {
        await ticket.load()
        if (timeoutId === this.watcher) {
          dispatch('WATCH_TICKET', ticket)
        }
      }, 30000)
      this.watcher = timeoutId
    },
    async UNWATCH_TICKET ({ dispatch }) {
      dispatch('UNWATCH_TICKETS')
    }
  },
  getters: {
    SUPPORT_TICKETS (state) {
      return state.tickets
    },
    SUPPORT_TICKET_BY_ID (state) {
      return (id) => {
        return state.tickets.find(ticket => ticket.id === id)
      }
    },
    TICKETS_LIST_FILTER (state) {
      return state.ticketsListFilter
    }
  }
}
