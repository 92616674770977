<template lang="pug">
  .organization-users
    fp-title(
      :value="userName"
      :parent="$t('organization.users')"
      @parent="$router.push('/organization/'+ organizationId +'/members')"
      :border="true"
      :disable-edit="true"
    )
      .actions-buttons
        fpui-button(
          :auto-width="true"
          color='white'
          @click="cancel"
        ) {{ $t('organization.users.edit_user.title.cancel') }}
        fpui-button(
          :auto-width="true"
          color='blue-flash'
          :disabled="disabled"
          @click="save"
        ) {{ $t('organization.users.edit_user.title.save') }}
    fp-loader(v-if="!user")
    .fp-container(v-else)
      fp-loader(v-if="loading")
      .fp-column
        .fp-box.informations
          .box-title {{ $t('organization.users.edit_user.title.information') }}
          .name-image
            .name-family
              .name
                fpui-input-text(
                  :label="$t('organization.users.edit_user.information.name')"
                  v-if="userId && user.accepted"
                  :disabled="true"
                  :value="userName"
                )
              .family
                fpui-input-text(
                  :disabled="true"
                  v-if="userId"
                  :label="$t('organization.users.edit_user.information.email')"
                  v-model="user.email"
                )
                fpui-input-tags(
                  v-if="!userId"
                  :label="$t('organization.users.edit_user.information.emails')"
                  v-model="user.emails"
                  :validation="emailsValidations"
                  :placeholder="$t('organization.users.edit_user.emails_placeholder')"
                  :error="errorEmailFormat || errorMemberAlreadyInOrganization"
                  :error-message="errorMessage"
                )
            img(:src="imageProfile").image
          br
          .input-container
            fpui-input-select(
              :clearable="false"
              :label="$t('organization.users.edit_user.information.role')"
              :options="[{ label: $t('organization.users.role.user'), value: 'user' }, { label: $t('organization.users.role.admin'), value: 'admin' }]"
              v-model="user.role"
              :disabled="!isAdmin"
            )
          br
          .joined-status(v-if="userId")
            .joined
              fpui-input-text(
                :label="$t('organization.users.edit_user.information.joined')"
                :value="joined"
                disabled
              )
            .status
              i.fp4(:class="status")
          .actions-user(v-if="userId")
            fpui-button.resend-invite(
              v-if='!user.accepted'
              color='blue-flash'
              icon="fp4 fp4-paper-plane"
              icon-left
              @click="resendInvite"
              auto-width
              noshadow
              reverse
            ) {{ $t('organization.users.edit_user.information.resend_invite') }}
            fpui-button.remove-from-organization(
              v-if="isAdmin"
              color='red'
              icon="fp4 fp4-trash-can"
              icon-left
              @click="removeFromOrganization"
              auto-width
              noshadow
              reverse
            ) {{ $t('organization.users.edit_user.information.remove_from_organization') }}
      .fp-column(v-if="user.role==='user'")
        .fp-box.permissions
          .box-title {{ 'organization.users.edit_user.title.permissions' | translate }}
            access-manager(:organization-id="organizationId" resource="dataplants" v-model="user.access")
            access-manager(:organization-id="organizationId" resource="storage_config" v-model="user.access")
            access-manager(:organization-id="organizationId" resource="storage_instance" v-model="user.access")
            //- access-manager(:organization-id="organizationId" resource="dns" v-model="user.access")
            //- access-manager(:organization-id="organizationId" resource="store-packages" v-model="user.access")
            //- access-manager(:organization-id="organizationId" resource="store-versions" v-model="user.access")
</template>

<script>
import emailValidator from 'email-validator'
import _cloneDeep from 'lodash/cloneDeep'
import neutralImg from '@/core/assets/img/users/neutral@2x.png'
import man1Img from '@/core/assets/img/users/man_1@2x.png'
import man2Img from '@/core/assets/img/users/man_2@2x.png'
import woman1Img from '@/core/assets/img/users/woman_1@2x.png'
import woman2Img from '@/core/assets/img/users/woman_2@2x.png'
import AccessManager from './AccessManager.vue'
import _isEqual from 'lodash/isEqual'
import moment from 'moment'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  components: {
    AccessManager
  },
  props: {
    organizationId: { type: String, required: true },
    userId: { type: String, required: false, default: null }
  },
  data () {
    return {
      user: null,
      loading: false
    }
  },
  computed: {
    disabled () {
      if (this.errorEmailFormat) return true
      if (this.errorMemberAlreadyInOrganization) return true
      if (!this.userId) return !this.user?.emails.length
      if (!this.user) return true
      return !this.hasChanges
    },
    hasChanges () {
      const users = this.organization.accounts || this.organization.users
      const original = users.find(u => u._id === this.userId || u.email === this.userId)
      if (!original) return true
      const toCheck = [
        'role',
        'access'
      ]
      for (const k of toCheck) {
        if (!_isEqual(this.user[k], original[k])) {
          return true
        }
      }
      return false
    },
    joined () {
      if (!this.user.joinAt) return ''
      // Handle timestamp in string (old version of King)
      if (moment(this.user.joinAt).isValid()) return moment(this.user.joinAt).format(this.$t('date.formatter'))
      if (moment(parseInt(this.user.joinAt)).isValid()) return moment(parseInt(this.user.joinAt)).format(this.$t('date.formatter'))
      return ''
    },
    imageProfile () {
      const manImages = [man1Img, man2Img]
      const womanImages = [woman1Img, woman2Img]
      const usr = this.user?.account || this.user?.user
      const mainProfile = usr?.profile.find(p => p.authentication === '5910ea60000eaf1000000001')
      if (mainProfile) {
        if (mainProfile.picture) {
          return mainProfile.picture
        }
        if (mainProfile.gender === 'male') {
          const idx = Math.floor(Math.random() * manImages.length)
          return manImages[idx]
        }
        if (mainProfile.gender === 'female') {
          const idx = Math.floor(Math.random() * womanImages.length)
          return womanImages[idx]
        }
        if (mainProfile.gender === 'other') {
          return neutralImg
        }
      } else {
        return neutralImg
      }
      return neutralImg
    },
    status () {
      return this.user?.accepted ? 'fp4-circle-check' : 'fp4-hourglass'
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    userName () {
      let fullname = ''
      const usr = this.user?.account || this.user?.user
      const mainProfile = usr?.profile.find(p => p.authentication === '5910ea60000eaf1000000001')
      if (mainProfile?.given_name || mainProfile?.family_name) {
        fullname = [mainProfile?.given_name, mainProfile?.family_name].filter(e => e).join(' ')
      } else {
        fullname = this.user?.email || ''
      }
      return fullname
    },
    organizationEmails () {
      const users = this.organization?.accounts || this.organization?.users
      return users.map(u => u.email) || []
    },
    emailsValidations () {
      return [{
        classes: 'email-validation-rule',
        rule: ({ text }) => !emailValidator.validate(text) || this.organizationEmails.includes(text)
      }]
    },
    errorEmailFormat () {
      if (!this.user?.emails) return false
      let error = false
      this.user?.emails.forEach(email => {
        if (!emailValidator.validate(email)) error = true
      })

      return error
    },
    errorMemberAlreadyInOrganization () {
      if (!this.user?.emails) return false
      let error = false
      this.user?.emails.forEach(email => {
        if (this.organizationEmails.includes(email)) error = true
      })

      return error
    },
    errorMessage () {
      if (this.errorEmailFormat) return this.$t('organization.users.edit_user.emails_error')
      if (this.errorMemberAlreadyInOrganization) return this.$t('organization.users.edit_user.emails_error_already_in_organization')
      return ''
    },
    isAdmin () {
      const organizationFromSession = this.$store.getters.SESSION.organizations.find(acc => acc._id === this.organization._id)
      return organizationFromSession?.role === 'admin'
    }
  },
  async mounted () {
    this.init()
  },
  methods: {
    init () {
      const users = this.organization.accounts || this.organization.users
      this.user = this.userId
        ? _cloneDeep(users.find(u => u._id === this.userId || u.email === this.userId))
        : {
            emails: [],
            role: 'user'
          }
    },
    cancel () {
      this.$router.push(`/organization/${this.organizationId}/members`)
    },
    async save () {
      this.loading = true
      try {
        if (this.userId) {
          await this.organization.editUser(this.user)
          this.$fpuiMessageBlock.success(this.$t('organization.users.edit_user.save.success'))
          this.init()
        } else {
          await this.organization.addUser(this.user)
          this.$router.push(`/organization/${this.organizationId}/members`)
        }
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    async resendInvite () {
      const confirmResult = await ConfirmModal(this, {
        contentHtml: true,
        title: this.$t('organization.send_invit.modal.title'),
        content: this.$t('organization.send_invit.modal.content', [this.user.email]),
        yes: {
          text: this.$t('organization.send_invit.modal.yes'),
          color: 'blue'
        }
      })
      if (!confirmResult) return
      this.loading = true
      try {
        await this.organization.sendInvitation(this.user.email)
        this.$fpuiMessageBlock.success(this.$t('organization.send_invit.success'))
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    async removeFromOrganization () {
      try {
        let content = []
        content.push(`<span style="font-size:14px">${this.$t('organization.remove.user.modal_text')} <span style="font-weight:600"> ${this.userName} </span>${this.$t('organization.remove.user.modal_text_end')}</span>`)
        content = ['<div>', ...content, '</div>']
        content = content.join('')
        const confirmResult = await ConfirmModal(this, {
          title: this.$t('treeview.users.remove.modal.title'),
          content,
          contentHtml: true,
          yes: {
            text: this.$t('fpui-modal-confirm.remove'),
            color: 'red'
          }
        })
        if (!confirmResult) return

        const response = await this.organization.removeUser(this.user.email)
        this.$fpuiMessageBlock.pop('success', response.message)
        this.$router.push(`/organization/${this.organizationId}/members`)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.organization-users {

  .name-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name-family {
      flex: 1;
      margin-right: 10px;
      .name {
        margin-bottom: 15px;
      }
    }
    .image {
      position: relative;
      width: 115px;
      height: 115px;
      top: 2px;
      object-fit: cover;
      border-radius: 2px;
    }
  }
  .role {
    margin-top: 5px;
  }
  .joined-status {
    display: flex;
    justify-content: space-between;
    .joined {
      width: calc(~"100% - 40px");
    }
    .status{
      margin-top: 23px;
      height: 30px;
      i.fp4-hourglass{
        line-height: 45px;
        font-size: 25px;
        color: @yellow;
      }
      i.fp4-circle-check{
        line-height: 45px;
        font-size: 25px;
        color: @green;
      }
    }
  }
  .actions-user {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .fpui-button {
      margin-left: 0px;
      margin-bottom: 10px;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .resend-invite {
      margin-right: 30px;
    }
  }

  .permissions {
    padding-bottom: 20px;
  }
}
</style>
