<template lang="pug">
.account
  fpui-tabs(v-if="account")
    fpui-tab(:header="$t('accounts.contact')")
      contact(
        :key="accountId"
        :account="account"
        :contactChanged.sync="contactChanged"
      )
    fpui-tab(:header="$t('accounts.billing_info')")
      billing-infos(
        :key="accountId"
        :account="account"
        :contactChanged.sync="contactChanged"
      )
    fpui-tab(:header="$t('accounts.receipts')")
      receipts(
        :key="accountId"
        :id="accountId"
        type="account"
      )
    fpui-tab(:header="$t('accounts.subscriptions')")
      subscriptions(
        :key="accountId"
        :account-id="accountId"
      )
</template>

<script>
import Contact from './Contact'
import BillingInfos from './BillingInfos'
import Receipts from './Receipts'
import Subscriptions from './Subscriptions'
import AccountSelect from './AccountSelect'

export default {
  components: {
    Contact,
    BillingInfos,
    Receipts,
    Subscriptions,
    AccountSelect
  },
  props: {
    accountId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      contactChanged: false
    }
  },
  computed: {
    account () {
      return this.$store.getters.ACCOUNT_BY_ID(this.accountId)
    }
  },
  async mounted () {
    this.account.backup()
  },
  async destroyed () {
    this.account.restore()
  },
  methods: {
  }
}
</script>
