import { Account } from '@/shared/plugins/Api/KING/Accounts'
import { Organization } from '@/shared/plugins/Api/KING/Organizations'
import Store from '@/shared/store'
import _flatten from 'lodash/flatten'
import _uniq from 'lodash/uniq'
import Vue from 'vue'
export default {
  state: {
    organizations: [],
    activeOrganization: null,
    accounts: null,
    allSubscriptions: [],
    allOrganizationsSubscriptions: [],
    availableOrganizations: [],
    requestedOrganizations: [],
    invitingOrganizations: []
  },
  mutations: {
    SET_ORGANIZATIONS (state, organizations) {
      state.organizations = organizations
    },
    SET_ACTIVE_ORGANIZATION (state, organizationId) {
      state.activeOrganization = organizationId
      localStorage.setItem('ACTIVE_ORGANIZATION', organizationId)
    },
    CREATE_ORGANIZATION (state, data) {
      state.organizations = state.organizations.filter(o => data._id !== o._id)
      state.organizations.push(new Organization(data))
    },
    UPDATE_ORGANIZATION (state, data) {
      const updatedOrgaIndex = state.organizations.findIndex(o => data._id === o._id)
      if (updatedOrgaIndex !== -1) {
        Vue.set(state.organizations, updatedOrgaIndex, new Organization(data))
      } else {
        state.organizations.push(new Organization(data))
      }
    },
    REMOVE_ORGANIZATION (state, organizationId) {
      state.organizations = state.organizations.filter(o => organizationId !== o._id)
    },
    SET_ACCOUNTS (state, accounts) {
      state.accounts = accounts
    },
    ADD_ACCOUNT (state, account) {
      state.accounts.push(account)
    },
    REMOVE_ACCOUNT (state, accountId) {
      state.accounts = state.accounts.filter(a => accountId !== a._id)
    },
    SET_ALL_SUBSCRIPTIONS (state, subscriptions) {
      state.allSubscriptions = subscriptions
    },
    SET_ALL_ORGANIZATIONS_SUBSCRIPTIONS (state, subscriptions) {
      state.allOrganizationsSubscriptions = subscriptions
    },
    SET_AVAILABLE_ORGANIZATIONS (state, organizations) {
      state.availableOrganizations = organizations
    },
    SET_REQUESTED_ORGANIZATIONS (state, organizations) {
      state.requestedOrganizations = organizations
    },
    SET_INVITING_ORGANIZATIONS (state, organizations) {
      state.invitingOrganizations = organizations
    },
    REMOVE_INVITING_ORGANIZATION (state, organizationId) {
      state.invitingOrganizations = state.invitingOrganizations.filter(o => organizationId !== o._id)
    }
  },
  actions: {
    async LOAD_ORGANIZATIONS ({ commit }) {
      const [orga, requested] = await Promise.all([
        await Vue.api.KING.organizations.list(),
        await Vue.api.KING.organizations.listRequested()
      ])
      commit('SET_ORGANIZATIONS', [...orga, ...requested])
      commit('SET_REQUESTED_ORGANIZATIONS', requested)
    },
    async LOAD_ACCOUNTS ({ commit }) {
      commit('SET_ACCOUNTS', await Vue.api.KING.accounts.list())
    },
    async LOAD_ALL_SUBSCRIPTIONS ({ commit }) {
      commit('SET_ALL_SUBSCRIPTIONS', await Vue.api.KING.accounts.allSubscriptions())
    },
    async LOAD_ALL_ORGANIZATIONS_SUBSCRIPTIONS ({ commit }) {
      commit('SET_ALL_ORGANIZATIONS_SUBSCRIPTIONS', await Vue.api.KING.users.subscriptionsFromAllOrganizations())
    },
    async CREATE_ACCOUNT ({ commit }) {
      const session = Store.getters.SESSION
      const profile = session.profile.find(p => p.main)
      const account = new Account({
        billing_address: {
          last_name: profile?.family_name,
          first_name: profile?.given_name,
          phone: profile?.phone?.number,
          email: session.email,
          company: profile?.company_name,
          line1: profile?.line1,
          line2: profile?.line2,
          line3: profile?.line3,
          city: profile?.city,
          country: profile?.country,
          state: profile?.state,
          zip: profile?.zip
        },
        last_name: profile?.family_name,
        first_name: profile?.given_name,
        phone: profile?.phone?.number,
        email: session.email,
        company: profile?.company_name,
        company_size: profile?.company_size,
        company_industry: profile?.company_industry,
        line1: profile?.line1,
        line2: profile?.line2,
        line3: profile?.line3,
        city: profile?.city,
        country: profile?.country,
        state: profile?.state,
        zip: profile?.zip
      })
      await account.create()
      commit('ADD_ACCOUNT', account)
      return account
    },
    async LOAD_AVAILABLE_ORGANIZATIONS ({ commit }) {
      commit('SET_AVAILABLE_ORGANIZATIONS', await Vue.api.KING.organizations.available())
    },
    async LOAD_REQUESTED_ORGANIZATIONS ({ commit }) {
      commit('SET_REQUESTED_ORGANIZATIONS', await Vue.api.KING.organizations.listRequested())
    },
    async LOAD_INVITING_ORGANIZATIONS ({ commit }) {
      commit('SET_INVITING_ORGANIZATIONS', await Vue.api.KING.organizations.invites())
    }
  },
  getters: {
    ACTIVE_ORGANIZATION (state) {
      return (routeParams = {}) => {
        let organization = null
        if (routeParams?.organizationId) {
          // from routeParams
          organization = Store.getters.ORGANIZATION_BY_ID(routeParams?.organizationId)
        }
        if (!organization) {
          // from dataplantRouteParams
          const dataplant = (routeParams?.dataplantId) ? Store.getters.DATAPLANT_BY_ID(routeParams?.dataplantId) : null
          if (dataplant) organization = Store.getters.ORGANIZATION_BY_ID(dataplant.organization_id)
        }
        if (!organization && state?.activeOrganization) {
          // From last use orga
          organization = Store.getters.ORGANIZATIONS.find(org => org._id === state.activeOrganization)
        }

        if (!organization && localStorage.getItem('ACTIVE_ORGANIZATION')) {
          organization = Store.getters.ORGANIZATIONS.find(org => org._id === localStorage.getItem('ACTIVE_ORGANIZATION'))
        }
        if (!organization) {
          // First of list
          if (Store.getters.ORGANIZATIONS.length) organization = Store.getters.ORGANIZATIONS[0]
        }
        return organization
      }
    },
    ORGANIZATIONS (state) {
      return state.organizations
    },
    ORGANIZATION_BY_ID (state) {
      return (id) => {
        return state.organizations.find(organization => organization._id === id)
      }
    },
    ORGANIZATIONS_EMAILS (state) {
      const emails = _uniq(_flatten(state.organizations.map(organization => {
        const users = organization.accounts || organization.users || []
        return users.map(user => user.email)
      })))
      return emails || []
    },
    AVAILABLE_ORGANIZATIONS (state) {
      return state.availableOrganizations
    },
    REQUESTED_ORGANIZATIONS (state) {
      return state.requestedOrganizations
    },
    INVITING_ORGANIZATIONS (state) {
      return state.invitingOrganizations
    },
    // -------- Accounts --------
    ACCOUNT_BY_ID (state) {
      return (id) => {
        return state.accounts.find(account => account._id === id)
      }
    },
    ACCOUNTS (state) {
      return state.accounts
    },
    ALL_SUBSCRIPTIONS (state) {
      return state.allSubscriptions
    },
    ALL_ORGANIZATIONS_SUBSCRIPTIONS (state) {
      return state.allOrganizationsSubscriptions
    }
  }
}
