<template lang="pug">
  .validation-storage-engine
    .header
      .title {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}

    .container-validation-storage-engine
      .map-content
      .content-infos
        .row.first-summary
          .left-summary
            .line
              label {{ $t('organization.data_storage_engine.modal.storage_system_name') }}
              span.bold {{ item.display_name }}
            .line
              label {{ $t('organization.data_storage_engine.modal.description') }}
              span {{ item.description || '-' }}
          .right-summary
            .provider-informations
              .engine {{ $t(`dataplant.creation.engine.${item.engine}`) }}
              .region {{ displayRegion }}
            .provider-image
              img(:src="require(`@/core/assets/img/engines/${item.engine}@2x.png`)")


        .row.bottom-summary
          .line.instance-size
            label {{ $t('organization.data_storage_engine.modal.instance_size') }}
            span(v-if="item.engine !== 'polar-data'") {{ instanceCount }} x {{ fpu }} FPU
            span(v-else) {{ $t('dataplant.fpu.free') }}
          .line.provider
            label {{ $t('organization.data_storage_engine.modal.provider') }}
            .provider-name-container
              img(:src="require(`@/core/assets/img/providers/${item.provider}.png`)")
              span {{ $t(`organization.data_storage_engine.${item.provider}`) }}
</template>

<script>
export default {
  props: {
    organizationId: { type: String, required: true },
    item: { type: Object, default: () => ({}) },
    title: { type: String, default: () => '' },
    subtitle: { type: String, default: () => '' },
    isEditing: { type: Boolean, default: false }
  },
  computed: {
    fpu () {
      return this.item.desired_state?.fpu || this.item.fpu
    },
    ha () {
      if (this.item?.desired_state && Object.keys(this.item?.desired_state).includes('ha')) return this.item.desired_state.ha
      return this.item.ha
    },
    engines () {
      return this.$store.getters.STORAGE_ENGINES_BY_ORGANIZATION_ID(this.organizationId)
    },
    displayRegion () {
      const engine = this.engines.find(e => e.region === this.item.region)
      return engine.display_region || this.item.region
    },
    instanceCount () {
      if (this.ha && this.item.engine === 'snowflake') return 1
      return this.ha ? 2 : 1
    }
  }
}
</script>

<style lang="less">
.validation-storage-engine {
  .container-validation-storage-engine {
    display        : flex;
    justify-content: center;
    align-content  : center;
    align-items    : center;
    margin         : 0;
    flex-wrap     : wrap;
    flex-direction: column;
    margin-top: 30px;

    .map-content {
      border-top             : 0.5px solid rgba(151, 167, 183, 0.21);
      border-right           : 0.5px solid rgba(151, 167, 183, 0.21);
      border-left            : 0.5px solid rgba(151, 167, 183, 0.21);
      border-top-left-radius : 5px;
      border-top-right-radius: 5px;
      height                 : 175px;
      width                  : 470px;
      padding                : 0;
      background-color       : #00CFFE;
      position               : relative;
      background-image       : url('./img/blue_map.svg');
      margin-top             : 2px;
      background-repeat      : no-repeat;
      background-size        : contain;
      background-position    : center;

      img {
        width: 100%;
      }
    }

    .content-infos {
      border          : 0.5px solid rgba(151, 167, 183, 0.21);
      border-radius   : 5px;
      border-top-left-radius : 0;
      border-top-right-radius: 0;
      border-top             : 0;
      padding                : 21px 16px 0 16px;
      min-height             : auto;
      width           : 470px;
      background-color: white;
      margin          : 0 0 47px 0;

      .row {
        margin-bottom: 16px;
      }

      .line {
        &.instance-size {
          text-transform: capitalize;
        }
      }

      .left-summary {
        border-right: 1px solid #E9ECF0;
      }

      label {
        font-size: 11px;
      }

      .row.first-summary {
        margin-bottom: 10px;
        display: flex;
        align-items: baseline;

        // .line {
        //   width: 100%;
        // }

        .left-summary {
          width: 50%;
          div:first-child {
            label {
              margin-top: 0;
            }
          }
        }
        .right-summary {
          display        : flex;
          flex-direction : row;
          flex-wrap      : nowrap;
          justify-content: flex-end;
          align-content  : center;
          align-items    : center;
          width: 50%;
          .provider-informations {
            display: flex;
            align-items: flex-end;
            .engine {
              color         : #3E4550;
              font-size     : 16px;
              text-transform: capitalize;
            }
            .region {
              color    : #B2BECA;
              font-size: 14px;
            }
          }
          .provider-image {
            margin-left: 12px;
            img {
              width: 38px;
            }
          }
        }
      }

      .row.bottom-summary {
        display: flex;
        justify-content: space-between;

        .line {
          width: 50%;
        }

        .provider {
          text-align: right;
          img {
            width: 22px;
          }
        }
      }

      .line {
        label {
          text-transform: uppercase;
          color         : #C9D3DB;
          font-size     : 12px;
          font-weight   : 600;
          padding-bottom: 0px;
          margin-bottom : 5px;
          margin-top    : 15px;
          display       : block;
        }

        span {
          font-size: 14px;

          img {
            margin-right: 10px;
          }
        }

        .bold {
          color      : #3E4550;
          font-size  : 16px;
          font-weight: 600;

          &.price {
            font-size: 18px;
          }
        }
        .provider-name-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
