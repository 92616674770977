<template lang="pug">
.subscription-new-step-custom
  .header
    h4(v-html="$t('subscription.new.custom',[plan])")
    p {{ 'subscription.new.custom.details' | translate([plan]) }}

  .fp-card
    fpui-input-select(
      :value="support"
      :label="$t('subscription.new.custom.support')"
      :options="supports"
      :clearable="false"
      @input="support=>$emit('support',support)"
    )
    fpui-input-label {{ 'subscription.new.custom.support.cost' | translate }}
    p {{price | price(currencyCode,0)}}
    fpui-input-label {{ 'subscription.new.custom.support.description' | translate }}
    p {{ `subscription.new.custom.support.${support}.details` | translate }}
</template>
<script>

export default {
  props: {
    support: {
      type: String,
      required: true
    },
    plan: {
      type: String,
      required: true
    },
    billingPeriod: {
      type: String,
      required: true
    }
  },
  computed: {
    billingPeriodId () {
      return this.billingPeriod === 'year' ? 'yearly' : 'monthly'
    },
    currencyCode () {
      return this.$store.getters.SESSION.currency
    },
    price () {
      const id = `support-${this.support}-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`
      return this.$store.getters.ADDON_BY_ID(id)?.price
    },
    supports () {
      return ['basic', 'advanced', 'businesscritical'].map(s => ({ value: s, label: this.$t(`subscription.new.custom.support.${s}`) }))
    }
  }
}
</script>
<style lang="less">
.subscription-new-step-custom {
  .fpui-input-label {
    color: #748294;
    margin-top: 5px;
    font-variant: small-caps;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 14px!important;
    margin-bottom: 0;
  }
  .fp-card {

    margin          : 0 auto;
    width           : 408px;
    border          : 0.5px solid rgba(151, 167, 183, 0.21);
    border-radius   : 5px;
    position        : relative;
    background-color: rgba(255, 255, 255, 0.9);
    margin-top      : 60px;
    margin-bottom   : 24px;
    padding         : 18px 20px;

    .fpui-input-label {
      color: rgba(151,167,183,0.5);
      font-weight: 600;
    }
    .fpui-input-select-container {
      display: flex;
      > div {
        width :50%;
      }
      .fpui-input-label {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        color: #3E4550;
        font-variant: inherit;
        text-transform: none;
        font-weight: normal;
      }
    }

    p {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      margin-top: 0;
    }
  }
}
</style>
