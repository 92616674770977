<template lang="pug">
  .organization-deletion-modal
    fp-loader(v-show="loading")
    .fpui-modal
      fpui-modal-header(
        :title="$t('organization.deletion.title', [organization.name])"
        @close='$emit("close")'
        closeTopButton
      )
      fpui-modal-body.no-padding
        .content
          span {{ $t('organization.deletion.content') }}
          fpui-input-text(
            v-model="userInput"
            :placeholder="$t('organization.deletion.placeholder')"
          )
      fpui-modal-footer(:buttons="buttons")
</template>

<script>

export default {
  props: {
    organization: { type: Object, required: true }
  },
  data () {
    return {
      userInput: '',
      loading: false,
      buttons: [{
        label: this.$t('button.cancel'),
        type: 'close',
        handler: () => {
          this.$emit('close')
        }
      }, {
        label: this.$t('button.delete'),
        color: 'red',
        disabled: () => {
          if (this.userInput.toLowerCase() === this.organization.name.toLowerCase()) return false
          return true
        },
        handler: this.removeOrganization
      }]
    }
  },
  async mounted () {
  },
  methods: {
    async removeOrganization () {
      this.loading = true
      try {
        const deleteOrg = await this.organization.remove(this.organization._id)
        if (deleteOrg) {
          this.$fpuiMessageBlock.pop('success', 'Organization removed')
          this.$store.commit('REMOVE_ORGANIZATION', this.organization._id)
          this.$router.push('/home')
        }
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="less">
  .organization-deletion-modal {
    .content {
      text-align: center;
      .fpui-input-text {
        margin-top: 15px;
      }
    }
  }
</style>
