<template lang="pug">
  .data-storage-engine
    fp-loader(v-if="loading")
    fp-title(
      :parent="$t('organization.storage_engine')"
      :border="true"
    )
      .actions-buttons
        fpui-input-search(
          v-model="search"
          :placeholder='$t("organization.data_storage_engine.search.placeholder")'
        )
        fpui-button.add-storage-engine(
          color="blue-flash"
          @click="addEditInstance()"
          auto-width
          icon-left
          icon="fp4 fp4-plus"
          v-if="$acl(organizationId).can('core', 'storage_instance', 'create')"
        ) {{ $t('organization.data_storage_engine.new_storage_engine') }}

    .page-content
      storage-engine-table(
          :search="search"
          :instances="instances"
          :configuration="configuration"
          :organizationId="organizationId"
          @addEditInstance="addEditInstance($event)"
          v-if="$acl(organizationId).can('core', 'storage_instance', 'read')"
        )
      storage-engine-configuration(
        :instances="instances"
        :configuration="configuration"
        :configuration-not-ready="configurationNotReady"
        :organizationId="organizationId"
        v-if="$acl(organizationId).can('core', 'storage_config', 'read')"
      )
</template>

<script>
import StorageEngineTable from './StorageEngineTable'
import StorageEngineConfiguration from './StorageEngineConfiguration'
import StorageEngineAddEditInstanceModal from './StorageEngineAddEditInstanceModal'
import CannotDoInTrialSubscription from '@/core/components/CannotDoInTrialSubscription.vue'

export default {
  components: {
    StorageEngineTable,
    StorageEngineConfiguration
  },
  props: {
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      search: '',
      loading: false,
      configurationNotReady: {
        data_prim: false,
        data_mart: false,
        data_ml: false
      },
      timeout: null
    }
  },
  computed: {
    instances () {
      return this.$store.getters.STORAGE_INSTANCES_BY_ORGANIZATION_ID(this.organizationId)
    },
    configuration () {
      return this.$store.getters.STORAGE_CONFIGURATION_BY_ORGANIZATION_ID(this.organizationId) || {}
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    }
  },
  watch: {
    organizationId (val) {
      this.initStorageEngineOnActiveOrganization(true)
    }
  },
  async mounted () {
    // Loading is set to true here to not be display every 10 seconds on screen
    this.loading = true
    await this.initStorageEngineOnActiveOrganization(true)
    if (!this.organization.subscription) await this.organization.loadSubscription()
  },
  destroyed () {
    if (this.timeout) clearTimeout(this.timeout)
  },
  methods: {
    addEditInstance (instance = null) {
      if ((['active', 'non_renewing'].includes(this.subscription?.status)) || (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')) {
        const item = !instance ? this.$api.STORAGE.instances.new({ organization_id: this.organizationId }) : instance
        this.$modal.show(StorageEngineAddEditInstanceModal, {
          item,
          afterComplete: () => this.setLevelDefaultDisabledBecauseInstanceNotReady(),
          organizationId: this.organizationId
        }, {
          height: 'auto',
          width: 900,
          clickToClose: false
        })
      } else {
        this.$modal.show(CannotDoInTrialSubscription, {
          organizationId: this.organizationId,
          text: 'home.dataplant.cannot_add_storage_in_trial_description'
        }, {
          height: '323px',
          width: '472px'
        })
      }
    },
    async initStorageEngineOnActiveOrganization (loadEngines = false) {
      try {
        if (loadEngines) await this.$store.dispatch('REFRESH_STORAGE_ENGINES_BY_ORGANIZATION', this.organizationId)
        await this.$store.dispatch('REFRESH_STORAGE_CONFIGURATIONS_BY_ORGANIZATION', this.organizationId)
        await this.$store.dispatch('REFRESH_STORAGE_INSTANCES_BY_ORGANIZATION', this.organizationId)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      if (this.loading) this.loading = false
      this.setLevelDefaultDisabledBecauseInstanceNotReady()

      this.timeout = setTimeout(() => {
        this.initStorageEngineOnActiveOrganization()
      }, 10000)
    },
    setLevelDefaultDisabledBecauseInstanceNotReady () {
      let checkPrim = false
      let checkMart = false
      let checkMl = false
      this.instances.forEach(instance => {
        const levels = instance.levels || []
        if (['Creating', 'Updating'].includes(instance.status) && levels.length) {
          if (levels.includes('data_prim')) checkPrim = true
          if (levels.includes('data_mart')) checkMart = true
          if (levels.includes('data_ml')) checkMl = true
        }
      })
      this.configurationNotReady.data_prim = checkPrim
      this.configurationNotReady.data_mart = checkMart
      this.configurationNotReady.data_ml = checkMl
    }
  }
}
</script>

<style lang="less">
.data-storage-engine {
  height: 100%;
  background-color: rgba(151,167,183,.05);
  overflow: hidden;

  .actions-buttons {
    .fpui-input-search {
      width: 195px;
    }
  }

  .page-content {
    height: calc(100% - 87px);
    overflow: auto;
  }
}
</style>
