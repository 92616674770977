<template lang="pug">
.dataplant-creation__modal-fullscreen
  fp-loader(v-show="loading || initialLoading")
  .fpui-modal(v-show="!initialLoading")
    fpui-steps(
      ref="dataplantCreation"
      @complete='onComplete()'
      :cancel="() => $emit('close')"
      :stepper-progression='true'
    )

      //- Provider
      fpui-step(
        :description='$t("dataplant.creation.steps.provider.description")'
        :validate='() => validateProviderSelection'
      )
        fpui-modal-header(
          :title="$t('home.dataplant.label.new_dataplant')"
          @close='$emit("close")'
          closeTopButton
          unbordered
        )
          .subtitle
            span {{ $t('dataplant.creation.provider.title') }}
        fpui-modal-body
          provider-selection-map(:options='options' v-if='options.providers.length' @update='updateValue')
          provider-selection-choices(:options='options' :organization-id='organizationId' @update='updateValue')
          provider-selection-personalize(:options='options' :organization-id='organizationId' @update='updateValue')

      //- Storage Engine
      fpui-step(
        :description='$t("dataplant.creation.steps.storage-engine.description")'
        :validate='() => validateStorageEngine'
        :tooltip-next='storageEngineTooltip'
      )
        fpui-modal-header(
          :title="$t('home.dataplant.label.new_dataplant')"
          @close='$emit("close")'
          closeTopButton
          unbordered
        )
          .subtitle
            span {{ $t('dataplant.creation.engine.title') }}
          .description
            span {{ $t('dataplant.creation.engine.description') }}
        fpui-modal-body
          storage-engine-choices(:options='options' :organization-id='organizationId' @update='updateValue')

      //- Summary
      fpui-step(
        :description='$t("dataplant.creation.steps.summary.description")'
      )
        fpui-modal-header(
          :title="$t('home.dataplant.label.new_dataplant')"
          @close='$emit("close")'
          closeTopButton
          unbordered
        )
          .subtitle
            span {{ $t('dataplant.creation.summary.title') }}
        fpui-modal-body
          provider-selection-map(:options='options' :read-only='true')
          dataplant-creation-summary(:options='options')

</template>

<script>
import _set from 'lodash/set'
import _find from 'lodash/find'
// ProviderSelection components
import ProviderSelectionChoices from '@/core/components/Dataplant/DataplantCreationFullscreen/ProviderSelection/ProviderSelectionChoices.vue'
import ProviderSelectionMap from '@/core/components/Dataplant/DataplantCreationFullscreen/ProviderSelection/ProviderSelectionMap.vue'
import ProviderSelectionPersonalize from '@/core/components/Dataplant/DataplantCreationFullscreen/ProviderSelection/ProviderSelectionPersonalize.vue'
// StorageEngine components
import StorageEngineChoices from '@/core/components/Dataplant/DataplantCreationFullscreen/StorageEngine/StorageEngineChoices.vue'
// Summary components
import DataplantCreationSummary from '@/core/components/Dataplant/DataplantCreationFullscreen/Summary.vue'

export default {
  components: {
    ProviderSelectionChoices,
    ProviderSelectionMap,
    ProviderSelectionPersonalize,
    StorageEngineChoices,
    DataplantCreationSummary
  },
  props: {
    organizationId: { type: String, required: true },
    afterConfirm: { type: Function, default: null }
  },
  data () {
    return {
      loading: false,
      initialLoading: true,
      options: {
        providers: [],
        engines: ['shared', 'default'],
        instances: [],
        domainAvailable: null,
        defaultStorageEngineConfigurations: {
          default: {},
          shared: {
            data_mart: 'shared',
            data_prim: 'shared',
            data_ml: 'shared'
          }
        },
        configuration: {
          storageEngine: {
            engine: 'default',
            instance: null,
            configuration: null
          },
          dataplant: {
            name: '',
            description: '',
            tags: [],
            domain: '',
            subdomain: '',
            provider: null,
            region: null
          }
        }
      }
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    },
    isFreeTrial () {
      return this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled'
    },
    isStandardPlan () {
      return this.subscription?.plan === 'standard'
    },
    canUseCurrentProvider () {
      const provider = this.options.configuration.dataplant.provider
      if (provider !== 'automatic' && (this.isFreeTrial || this.isStandardPlan)) return false
      return true
    },
    validateProviderSelection () {
      const conf = this.options.configuration.dataplant
      return conf.name !== '' &&
        conf.domain &&
        conf.subdomain.length > 2 &&
        conf.provider &&
        this.options.domainAvailable &&
        this.canUseCurrentProvider &&
        (conf.provider === 'automatic' || conf.region)
    },
    validateStorageEngine () {
      const storageEngine = this.options.configuration.storageEngine
      const instance = ['default', 'shared'].includes(storageEngine.engine) ? true : storageEngine.instance
      return storageEngine.engine !== '' &&
        instance &&
        storageEngine.configuration
    },
    storageEngineTooltip () {
      return !this.validateStorageEngine && this.isFreeTrial ? this.$t('dataplant.creation.provider.disable_dedicated_free_trial') : ''
    }
  },
  async mounted () {
    try {
      await this.$store.dispatch('REFRESH_STORAGE_CONFIGURATIONS_BY_ORGANIZATION', this.organizationId)
      await this.$store.dispatch('REFRESH_STORAGE_ENGINES_BY_ORGANIZATION', this.organizationId)
      await this.$store.dispatch('REFRESH_STORAGE_INSTANCES_BY_ORGANIZATION', this.organizationId)
      await this.$store.dispatch('REFRESH_PROVIDERS_BY_ORGANIZATION', this.organizationId)
      await this.$store.dispatch('LOAD_OFFERS')
      const engines = this.$store.getters.STORAGE_ENGINES_BY_ORGANIZATION_ID(this.organizationId)
      const additionnalEngines = []
      engines.forEach(engine => {
        if (!additionnalEngines.includes(engine.engine)) additionnalEngines.push(engine.engine)
      })
      // Order the engines depending on the organization plan
      // ['shared', 'default'] will be at the end if the oranization is in free trial
      // otherwise the engines array will start with those
      if (this.isFreeTrial) {
        this.options.engines = this.options.engines.concat(additionnalEngines)
      } else {
        this.options.engines = additionnalEngines.concat(this.options.engines)
      }
      this.options.providers = this.$store.getters.PROVIDERS_BY_ORGANIZATION_ID(this.organizationId)
      // Automatic provider for free trial and standard plan
      this.options.providers.unshift({
        active: true,
        display_options: {
          display_name: this.$t('dataplant.creation.automatic_provider')
        },
        name: 'automatic',
        options: null,
        status: 'available',
        regions: []
      })
      this.options.instances = this.$store.getters.STORAGE_INSTANCES_BY_ORGANIZATION_ID(this.organizationId)
      const storageConfigurations = this.$store.getters.STORAGE_CONFIGURATIONS
      this.options.defaultStorageEngineConfigurations.default = storageConfigurations[0].configuration
      this.options.configuration.storageEngine.configuration = storageConfigurations[0].configuration
      await this.organization.loadSubscription(true)
      // Defaults to automatic provider when in free trial or paid standard plan
      if ((this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled') || (this.subscription?.plan === 'standard')) {
        this.options.configuration.dataplant.provider = 'automatic'
        this.options.configuration.storageEngine.engine = 'shared'
      }
      this.initialLoading = false
      this.$analytics.track('Open new Dataplant funnel', {
        organization_id: this.organizationId
      })
    } catch (err) {
      this.$fpuiMessageBlock.error(err)
      console.error(err)
    }
  },
  methods: {
    async onComplete () {
      try {
        this.loading = true
        if (!this.subscription || this.subscription.error) throw new Error(this.$t('dataplant.creation.no_sub'))

        if (this.options.configuration.storageEngine.instance) {
          // When the user has created a storage engine instance from the Dataplant Creation UI,
          // begin the creation on Dataplant creation confirmation
          if (this.options.configuration.storageEngine.instance.startsWith('tmp_instance-')) {
            const instanceFromList = _find(this.options.instances, { tmpId: this.options.configuration.storageEngine.instance })
            const createdInstance = await instanceFromList.create()
            this.$analytics.track('Storage Engine Created', {
              instance_id: createdInstance.id,
              cloud_provider_name: createdInstance.provider,
              cloud_provider_location: createdInstance.region,
              resources_allocated: createdInstance.fpu,
              resources_type: createdInstance.fpu_type,
              engine: createdInstance.engine,
              origin: 'Dataplant',
              organization_id: this.organizationId,
              high_availability: createdInstance.ha
            })
            this.updateValue({
              'configuration.storageEngine.configuration': {
                data_mart: createdInstance.id,
                data_prim: createdInstance.id,
                data_ml: createdInstance.id
              }
            })
          }
        }

        const provider = this.options.configuration.dataplant.provider
        const dataplant = {
          organization_id: this.organization._id,
          name: this.options.configuration.dataplant.name,
          description: this.options.configuration.dataplant.description,
          template: null, // TODO
          domain: this.options.configuration.dataplant.domain,
          subdomain: this.options.configuration.dataplant.subdomain,
          provider: provider === 'automatic' ? this.$store.getters.OFFERS.standard.provider : provider,
          region: provider === 'automatic' ? this.$store.getters.OFFERS.standard.region : this.options.configuration.dataplant.region?.id,
          storageEngine: this.options.configuration.storageEngine.configuration,
          plan: this.subscription.plan,
          datatank: 50
        }

        this.$analytics.track('Dataplant creation start', {
          organization_id: this.organization._id,
          provider: dataplant.provider,
          region: dataplant.region,
          default_storage_engine_config: this.options.configuration.storageEngine.engine === 'default'
        })
        await this.$api.FPAPI.dataplants.create(dataplant)
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.organization._id, ignoreCache: true })
        // this.$fpuiMessageBlock.pop('success', 'Dataplant is being created')
        if (this.afterConfirm) this.afterConfirm()
        this.$emit('close')
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err)
      } finally {
        this.loading = false
      }
    },
    updateValue (obj) {
      for (const [key, value] of Object.entries(obj)) {
        _set(this.options, key, value)
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__modal-fullscreen {
    height: 100vh;
    .fpui-modal {
      border-radius: 0;
    }
    .modal-header {
      height: auto;
      border-bottom: none;
      text-align: center;
      position: absolute;
      h3 {
        color: #00CCF9;
        justify-content: center;
        .modal-title {
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
      .actions {
        i {
          color: white;
        }
      }
      .subtitle {
        color: white;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
        margin: auto;
      }
      .description {
        margin-top: 0;
        color: white;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
      }
    }
    .modal-body {
      padding: 100px 0 0 0;
      background: radial-gradient(circle at center calc(~"50% + 100px"), #00BEEE 0%, #035599 80%);
      height: calc(~"100vh - 96px");
    }
    .modal-footer {
      padding: 0;
    }
    .fpui-steps {
      .fpui-steps-footer {
        height: 95px;
        position: relative;
        padding: 16px 0;
        .actions {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          .next {
            &:only-child {
              margin-left: auto;
            }
            button:not(.reverse) {
              background-color: #00CCF9;
            }
          }
        }
        .stepper {
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          .step {
            opacity: 0.5;
            background: #00CCF9;
            width: 5px;
            height: 5px;
            margin: 0 6px;
            &.active {
              width: 11px;
              height: 11px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>
