<template lang="pug">
  .organization-consumption-chart
    fp-loader(
      type="chart"
      v-if="loading"
    )
    .placeholder(v-if="noData && !loading")
      img(:src="require('@/core/assets/img/placeholder_chart.png')")
      .text {{ 'echarts.no-activity' | translate }}
    fpui-echarts(
      v-if="!noData && !loading"
      :options="options"
      style="height: 100%;"
    )
</template>

<script>
import _merge from 'lodash/merge'
import _concat from 'lodash/concat'
import _flatten from 'lodash/flatten'
import _uniq from 'lodash/uniq'
import moment from 'moment'
import hatch1 from '@/core/assets/img/hatch-1.svg'
import hatch2 from '@/core/assets/img/hatch-2.svg'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    organizationId: {
      type: String,
      required: true
    }
  },
  computed: {
    noData () {
      return !this.organization.consumptions
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    series () {
      const hatchedPattern1 = new Image()
      const hatchedPattern2 = new Image()
      hatchedPattern1.src = hatch1
      hatchedPattern2.src = hatch2
      const colors = ['rgb(0,204,246)', 'rgb(0, 137, 192)']
      const patterns = [hatchedPattern1, hatchedPattern2]
      const data = this.organization?.consumptions?.byDay || {}

      if (this.type === 'fpu' || this.type === 'fpug') {
        const providers = _uniq(_flatten(_concat(Object.values(data).map(d => Object.keys(d.fpu)))))
        return providers.map((provider, i) => {
          return {
            name: this.$t(`organization.chart.${this.type}`) + ` (${provider})`,
            type: 'bar',
            stack: true,
            data: Object.keys(data).map(d => {
              const isPrevision = moment(d).isSameOrAfter(moment(), 'day')
              const currentData = {
                value: data[d][this.type][provider]?.days
              }
              if (isPrevision) {
                currentData.itemStyle = {
                  color: {
                    image: isPrevision ? patterns[i % colors.length] : null,
                    value: !isPrevision ? colors[i % colors.length] : null,
                    color: colors[i % colors.length],
                    repeat: 'repeat'
                  }
                }
              }
              return currentData
            }),
            itemStyle: {
              color: () => {
                return colors[i % colors.length]
              }
            }
          }
        })
      }

      return [
        {
          name: this.$t(`organization.chart.${this.type}`),
          type: 'bar',
          data: Object.keys(data).map(d => {
            const isPrevision = moment(d).isSameOrAfter(moment(), 'day')
            const currentData = { value: data[d][this.type].days }
            if (isPrevision) {
              currentData.itemStyle = {
                color: {
                  image: isPrevision ? patterns[0] : null,
                  value: !isPrevision ? colors[0] : null,
                  color: colors[0],
                  repeat: 'repeat'
                }
              }
            }
            return currentData
          }),
          itemStyle: {
            color: colors[0]
          }
        }
      ]
    },
    options () {
      const data = this.organization?.consumptions?.byDay || {}
      return _merge(this.barChartOptions, {
        noData: false,
        xAxis: {
          data: Object.keys(data).map(v => moment(v).format('DD/MM'))
        },
        yAxis: [
          {
            axisLabel: {
              formatter: (value) => value
            }
          }
        ],
        series: this.series,
        tooltip: {
          formatter: (params) => {
            const tooltipTitle = `<div style="font-weight: bold; line-height: 20px; font-size: 13px;">${params[0].axisValueLabel}</div>`
            const tooltipTitlePrevision = `<div style="font-weight: bold; line-height: 20px; font-size: 13px;">${params[0].axisValueLabel} ${this.$t('organization.plan.billing.predicted')}</div>`
            let series = ''
            let hasPrevision = false
            params.forEach(serie => {
              if (!serie.value) return
              if (!hasPrevision) hasPrevision = !!serie.color?.image
              const serieColorStyle = `font-size: 30px; line-height: 25px; color: ${serie.color?.image ? serie.color.color : serie.color}; margin-right: 5px;`
              const serieColorElement = `<span style="${serieColorStyle}">•</span>`
              const serieName = `<div>${serie.seriesName}</div>:`
              const serieValue = `<div style="margin-left: 5px; font-weight: bold">${Math.floor(serie.value * 10) / 10}</div>`
              series += `<div style="display: flex; justify-content: space-between; align-items: center;"><div style="display: flex; align-items: center;">${serieColorElement} ${serieName}</div>${serieValue}</div>`
            })
            return (hasPrevision ? tooltipTitlePrevision : tooltipTitle) + series
          }
        }
      })
    }
  },
  barChartOptions () {
    return {
      yAxis: {
        splitNumber: 2,
        type: 'value',
        axisLine: {
          show: false
        },
        axisLabel: {
          inside: true,
          fontFamily: 'Source Sans Pro',
          padding: [0, 0, 3, 0],
          verticalAlign: 'bottom',
          color: ['rgb(151,167,183)']
        },
        splitLine: {
          lineStyle: {
            shadowColor: '#eee',
            shadowOffsetX: -13,
            color: ['#eee']
          }
        },
        offset: 40,
        axisTick: {
          show: false
        }
      },
      xAxis: {
        type: 'category',
        axisLine: {
          show: false
        },
        axisLabel: {
          color: ['rgb(151,167,183)']
        },
        axisTick: {
          show: false
        }
      },
      tooltip: {
        trigger: 'axis',
        show: true
      },
      series: [{
        barMaxWidth: 40,
        type: 'bar'
      }]
    }
  }
}
</script>

<style lang="less">
.organization-consumption-chart {
  position: relative;
  height:155px;
  .placeholder {
    text-align: center;
    img {
      margin-top: 50px;
      width: 100px;
      display: inline-block
    }
    .text {
      color: #97a7b7;
      font-weight: 700;
      font-size: 12px;
    }
  }
}
</style>
