<template lang="pug">
  .appservice-github
    fp-loader(v-if="loading")
    .text-center(v-if="!isLinked")
      fpui-button(
        color="blue-flash"
        auto-width
        @click="linkAccount"
      ) {{ $t(`appservice.create.link_account`,['Github']) }}
    div(v-else)
      fpui-input-label {{$t('appservice.create.branch.repository')}}
      fpui-table(
        :height="'200px'"
        :columns='columns'
        :actions='actions'
        :data='repositories'
      )
      br
      fpui-input-select(
        :placeholder="$t('appservice.create.branch.placeholder')"
        v-if="branches"
        :options="branches.map(b=>({label:b,value:b}))"
        :label="$t('appservice.create.branch.label')"
        :value="value.repository.branch"
        @input="(v)=>value.update('repository.branch',v,false)"
      )
      br(v-if="branches")

</template>

<script>
import Config from '@/shared/Config'
import moment from 'moment'
import qs from 'qs'
export default {
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      token: null,
      branches: null,
      repository: null,
      repositories: []
    }
  },
  computed: {
    profile () {
      return this.$store.getters.SESSION.profile.find(p => p.authentication === '5910ea60000eaf1000000004')
    },
    isLinked () {
      return !!this.profile
    },
    actions () {
      return [{
        id: 'select',
        icon: 'fp4 fp4-eye',
        click: async (row) => {
          this.openRepo(row)
        }
      }]
    },
    columns () {
      return [{
        name: 'name',
        label: this.$t('appservice.repository.name'),
        align: 'left',
        sortable: true,
        target: (row) => {
          return row.name
        }
      }, {
        name: 'url',
        label: this.$t('appservice.repository.url'),
        align: 'left',
        sortable: true,
        target: (row) => {
          return row.url
        }
      }, {
        name: 'created_at',
        label: this.$t('appservice.repository.created_at'),
        align: 'left',
        sortable: (row) => row.created_at,
        target: (row) => {
          return moment(row.created_at).format(this.$t('date.formatter.short'))
        }
      }]
    }
  },
  async created () {
    window.addEventListener('message', this.eventListener, false)
  },
  destroyed () {
    // On destroy, remove message event listener
    window.removeEventListener('message', this.eventListener)
  },
  mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      if (!this.isLinked) return
      this.loading = true
      try {
        this.token = await this.$api.KING.getToken('5910ea60000eaf1000000004')
        const { repositories } = await this.$api.APPSERVICE.request({
          method: 'GET',
          url: '/repo/explore',
          params: {
            provider: 'github',
            access_token: this.token
          }
        })
        this.repositories = repositories
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
      this.loading = false
    },
    async openRepo (repository) {
      this.loading = true
      try {
        this.branches = null
        this.repository = repository
        this.value.update('repository.url', this.repository.url, false)
        this.value.update('repository.user', this.profile.login, false)
        this.value.update('repository.password', this.token, false)
        this.value.update('repository.authentication', false, false)
        this.value.update('repository.type', 'git', false)
        const { branches } = await this.$api.APPSERVICE.request({
          method: 'GET',
          url: '/repo/git',
          params: {
            url: encodeURIComponent(repository.url),
            provider: 'github',
            access_token: this.token
          }
        })
        this.branches = branches
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
      this.loading = false
    },
    eventListener (event) {
      if (event?.data?.id === 'auth' && event?.data?.op === 'ADD_LINKED_ACCOUNT') {
        this.$store.dispatch('REFRESH')
        setTimeout(() => {
          this.refresh()
        }, 500)
      }
    },
    async linkAccount () {
      const config = await Config()
      const queryString = qs.stringify({
        email: this.$store.getters.SESSION.email,
        popup: true,
        link: true,
        id: 'github',
        redirect_uri: window.location.origin + '/auth/#/iframe-callback'
      })
      const url = `${config.KING}/v1/login?${queryString}`
      window.open(url, '', 'menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=500')
    }
  }
}
</script>

<style lang="less">
.appservice-github {
  .text-center {
    .button-container {
      button {
        margin: 20px;
        display: inline-block!important;
      }
    }
  }
}
</style>
