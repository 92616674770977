<template lang="pug">
  .home-block-chart(:class='{ hovering: hover }')
    .dataplant-block-chart(:class='{ "full-height": !dataplantReadyOrLimited }')
      .detailed-circle-container(v-if="dataplantReadyOrLimited")
        .detailed-circle.fpu(
          v-for="s in monitoringCircle"
          :style="s"
        )
        .detailed-circle.storage(
          v-for="s in monitoringCircle"
          :style="s"
        )
      .green-circle-container(v-if="!dataplantReadyOrLimited")
        img.circle-loader(src="@/core/assets/img/dataplant_green_circles.svg")
        .detailed-circle(
          v-for='s in monitoringCategories'
          :style='s'
        )
      .provider(:class='{ shadow: dataplantReadyOrLimited }')
        .provider-logo
          img(v-if='!providerLogo' src="@/core/assets/img/providers/standard.png")
          img(v-else :src="providerLogo")
        .provider-location(
          v-if='(subscription && subscription.plan !== "standard") || !subscription'
          :title='dataplant.region'
        ) {{ dataplant.region_name || dataplant.region }}
      a.open-dataplant(
        v-if='dataplantReadyOrLimited && !dataplant.block_mfa'
        :href="`#/dataplant/${dataplant.dataplant_id}`"
      ) OPEN
</template>

<script>

export default {
  name: 'HomeBlockChart',
  props: {
    dataplant: {
      type: Object,
      required: true
    },
    subscription: {
      type: Object,
      required: false,
      default: () => null
    },
    hover: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dataplantReadyOrLimited () {
      return this.dataplant.isReady || this.dataplant.isLimited
    },
    monitoringCircle () {
      return Array.from(Array(16)).map((_, i) => {
        let borderColor = false
        try {
          const v = 100
          const sv = Math.sqrt(v) * 10
          if (sv < 100 - (i + 1) * 6) return false
          if (sv < 0) return false
          borderColor = '#00ccf9'
        } catch (err) {
          borderColor = '#F9FAFB'
        }
        return {
          width: `${108 - (i * 3)}px`,
          height: `${216 - (i * 6)}px`,
          'border-color': borderColor
        }
      })
    },
    monitoringCategories () {
      return Object.values(this.dataplant?.monitoring?.categories || []).map((component, i) => {
        return {
          width: `${208 - (i * 6)}px`,
          height: `${208 - (i * 6)}px`,
          'border-color': component.ready ? '#9EDF10' : 'transparent'
        }
      })
    },
    providerLogo () {
      const provider = this.dataplant.provider.toLowerCase()
      let img = null
      try {
        img = require(`@/core/assets/img/providers/${provider}.png`)
      } catch (err) { img = null }
      return img
    }
  }
}
</script>

<style lang="less">
  .home-block-chart {
    .dataplant-block-chart {
      height: 243px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .detailed-circle-container {
        display: flex;
        align-items: center;
        // animation: appear-dataplant 0.5s forwards;
        .detailed-circle {
          position: absolute;
          background-color: unset;
          border: 2px solid #F9FAFB;
          &.fpu {
            border-top-left-radius: 110px;
            border-bottom-left-radius: 110px;
            border-right: 0;
            right: 170px;
          }
          &.storage {
            border-top-right-radius: 110px;
            border-bottom-right-radius: 110px;
            border-left: 0;
            left: 170px;
          }
        }
      }
      .green-circle-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        img {
          vertical-align: middle;
          width: 250px;
          height: 250px;
          position: absolute;
        }
        .detailed-circle {
          position: absolute;
          background-color: unset;
          border: 2px solid #9EDF10;
          border-radius: 50%;
        }
      }
      .provider {
        z-index: 2;
        border-radius: 50%;
        width: 118px;
        height: 118px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        text-align: center;
        &.shadow {
          box-shadow: 0 0 30px 15px #FFF;
        }
        .provider-logo {
          img {
            max-width: 70px;
            max-height: 70px;
            vertical-align: middle;
          }
        }
        .provider-location {
          margin-top: 5px;
          font-size: 12px;
          font-weight: 600;
          width: 90px;
          margin: 0 auto;
          letter-spacing: 0;
          line-height: 18px;
          color: #97a7b7;
        }
      }
      .open-dataplant {
        color: white;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        display: flex;
        position: absolute;
        border-radius: 50%;
        width: 118px;
        height: 118px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: rgba(0, 204, 249, 0.9);
        z-index: 3;
        opacity: 0;
        transition: opacity 100ms;
      }
    }
    &.hovering {
      .open-dataplant {
        opacity: 1;
      }
    }
  }
</style>
