<template lang="pug">
  .provider-instance-informations-edit
    .header
      .title {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
        img(
          :src="require(`@/core/assets/img/engines/${item.engine}@2x.png`)"
          :class="item.engine"
        )
        span.engine {{ $t(`organization.data_storage_engine.${item.engine}`) }}
    .content
      instance-informations(
        :item="item"
        :isEditing="isEditing"
        :organizationId="organizationId"
      )
      provider-informations(
        :item="item"
        :isEditing="isEditing"
      )
</template>

<script>
import ProviderInformations from './ProviderInformations'
import InstanceInformations from './InstanceInformations'

export default {
  components: { ProviderInformations, InstanceInformations },
  props: {
    item: { type: Object, default: () => ({}) },
    title: { type: String, default: () => '' },
    subtitle: { type: String, default: () => '' },
    organizationId: { type: String, required: true },
    isEditing: { type: Boolean, default: false }
  }
}
</script>

<style lang="less">
.provider-instance-informations-edit {
  display        : flex;
  flex-direction : column;
  flex-wrap      : nowrap;
  justify-content: center;
  align-content  : center;
  align-items    : center;
  margin         : 0;

  .subtitle {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
      margin-left: 5px;

      &.snowflake {
        width: 15px;
        margin-left: 7px;
      }
    }

    .engine {
      font-weight: 600;
      margin-left: 5px;
    }
  }

  .content {
    border          : 0.5px solid rgba(151, 167, 183, 0.21);
    border-radius   : 5px;
    min-height      : auto;
    background-color: white;
    margin-top      : 30px;

    .content {
      border: none;
      margin-top: 0;
      margin-bottom: 0;
    }

    .provider-informations {
      .content {
        padding-top: 0;
      }
    }
  }
}
</style>
