<template lang="pug">
  .dataplant-deletion-confirmation
    fp-loader(v-if="loading")
    .content(v-if="dataplant")
      .panel
        .img-container
          img(src="@/core/assets/img/providers/forepaas_with_label.png")
        .text {{ $t('dataplant.deletion.confirm.text', [dataplant.name]) }}
        fpui-button(
          color="red"
          @click="deleteDataplant()"
        ) {{ $t('confirm') }}
    .content(v-else)
      .panel
        .img-container
          img(src="@/core/assets/img/providers/forepaas_with_label.png")
          .text {{ $t('dataplant.deletion.already_done.text') }}
        fpui-button(
          color="white"
          @click="$router.push('/')"
        ) {{ $t('return') }}


</template>

<script>

export default {
  props: {
    dataplantId: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    dataplant () {
      return this.$store.getters.DATAPLANT_BY_ID(this.dataplantId) || null
    }
  },
  mounted () {
    // TODO: Check email parameter
  },
  methods: {
    async deleteDataplant () {
      this.loading = true
      try {
        await this.$api.STORAGE.databases.remove(this.dataplantId)
        const deleteDp = await this.dataplant.delete(this.token)
        if (deleteDp) {
          this.$analytics.track('Delete Dataplant', {
            dataplant_id: this.dataplantId
          })
          this.$fpuiMessageBlock.pop('success', 'Dataplant deleted')
          this.$store.commit('DELETE_DATAPLANT', this.dataplantId)
          this.$router.push('/')
        }
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
  .dataplant-deletion-confirmation {
    .content {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        width: 400px;
        height: auto;
        border-radius: 5px;
        text-align: center;
        align-items: center;
        .img-container {
          padding: 15px;
          width: 105px;
          height: 105px;
          display: flex;
          justify-content: center;
          img {
            width: 80px;
            height: 80px;
          }
        }
        .text {
          padding: 20px;
          min-width: 200px;
          font-size: 14px;
        }
        .fpui-button {
          padding: 20px 0;
          margin: 10px 0 30px 0;
        }
      }
    }
  }
</style>
