<template lang="pug">
.subscription-new-estimated-price
  .title {{ $t('subscription.new.cost.estimated_cost') }}
  .value
    span.big {{ price | price(currencyCode,0) }}
    span {{ 'subscription.new.period_unit.'+billingPeriod | translate }}


</template>
<script>

export default {
  props: {
    support: {
      type: String,
      required: true
    },
    plan: {
      type: String,
      required: true
    },
    billingPeriod: {
      type: String,
      required: true
    }
  },
  computed: {
    billingPeriodId () {
      return this.billingPeriod === 'year' ? 'yearly' : 'monthly'
    },
    price () {
      const supportId = `support-${this.support}-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`
      const supportPrice = this.$store.getters.ADDON_BY_ID(supportId)?.price || 0
      const planId = `${this.plan}-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`
      const planPrice = this.$store.getters.PLAN_BY_ID(planId).price
      return planPrice + supportPrice
    },
    currencyCode () {
      return this.$store.getters.SESSION.currency
    }
  }
}
</script>
<style lang="less">
.subscription-new-estimated-price {
  padding: 14px 0 23px;
  border-top: rgba(151,167,183,0.21) 1px solid;

  .title {
    color: #B2BECA;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }
  .value {
    margin-top: 16px;
    color: #3E4550;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    .big {
      font-size: 28px;
      line-height: 16px;
    }
  }
}

</style>
