<template lang="pug">
  .provider-informations
    .header(v-if="!isEditing")
      .title {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
        img(
          :src="engineImage(item.engine)"
          :class="item.engine"
        )
        span.engine {{ $t(`organization.data_storage_engine.${item.engine}`) }}
    .content
      .light-mode-row
        fpui-input-text(
          v-if='lightMode'
          :label="$t('organization.data_storage_engine.modal.display_name')"
          :placeholder="$t('organization.data_storage_engine.modal.display_name')"
          :value="item.display_name"
          @input="(value) => item.update('display_name', value, false)"
        )
      .row
        label {{ $t('organization.data_storage_engine.modal.provider') }}
        fpui-input-select(
          :value='item.provider'
          :options="filteredProviders"
          @input="(value) => providerSelection(value)"
          :disabled="isEditing"
        )
      .row
        label {{ $t('organization.data_storage_engine.modal.region') }}
        fpui-input-select(
          :value='item.region'
          :options="filteredRegions"
          @input="(value) => regionSelection(value)"
          :disabled="isEditing"
        )
      div(v-if="canCustomize")
        .row.instance-size
          .instance-count
            label {{ $t('organization.data_storage_engine.modal.instance_count') }}
            fpui-input-number(
              :value='instanceCount'
              :min="1"
              :max="2"
              disabled
            )
          .separator
            span x
          .instance-size
            label {{ $t('organization.data_storage_engine.modal.instance_size') }}
            fpui-input-select(
              :value='fpu'
              :options="instanceSizeOptions"
              @input="(value) => fpu = value"
              :disabled="isEditing"
            )
          .separator
            span =
          .total-fpu
            label {{ $t('organization.data_storage_engine.modal.total_fpu') }}
            fpui-input-number(
              :value='totalFpu'
              disabled
            )

        .instance-slider
          vue-slider(
            width="95%"
            height="8px"
            v-model="fpu"
            :adsorb="true"
            :data="filteredFpusSlider"
            :marks="true"
            :contained="true"
          )

        .options
          .row.title
            .box-title
              span {{ $t('organization.data_storage_engine.modal.options')}}

          .row.high-availability(v-tooltip="{ content: haToggleTooltip, delay: 0 }")
            label {{ $t('organization.data_storage_engine.modal.high_availability') }}
            .toggle
              fpui-input-toggle(
                v-if='ha !== undefined'
                :value='ha'
                @input="(value) => toggleHighAvailability(value)"
                :disabled="!(highAvailabilityOptions > 1)"
                :sync="true"
              )
        .description
          label(v-if='item.engine === "snowflake"') {{ $t('organization.data_storage_engine.modal.replicated_information_snowflake') }}
          label(v-else) {{ $t('organization.data_storage_engine.modal.replicated_information') }}
        .informations
          .row
            ul
              li
                i.fp4.fp4-clock-dark
                span {{ $t('organization.data_storage_engine.modal.replicated_information.recovery') }}
              li(v-if="item && item.provider !== 'ovh'")
                i.fp4.fp4-shield-check
                span {{ $t('organization.data_storage_engine.modal.replicated_information.private') }}
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: { VueSlider },
  props: {
    item: { type: Object, default: () => ({}) },
    title: { type: String, default: () => '' },
    subtitle: { type: String, default: () => '' },
    isEditing: { type: Boolean, default: false },
    lightMode: { type: Boolean, default: false }
  },
  data () {
    return {
      filteredRegions: [],
      filteredFpus: [],
      filteredFpusSlider: [],
      regionsPricesFiltered: [],
      fpuType: 'fpu-s',
      supportedModes: []
    }
  },
  computed: {
    ha () {
      if (this.item?.desired_state && Object.keys(this.item?.desired_state).includes('ha')) return this.item.desired_state.ha
      return this.item.ha
    },
    haToggleTooltip () {
      if (this.supportedModes.length < 2 && this.supportedModes.includes('ha')) return this.$t('organization.data_storage.ha_not_available', [this.$t(`organization.data_storage_engine.${this.item.provider}`)])
      return ''
    },
    engines () {
      return this.$store.getters.STORAGE_ENGINES_BY_ORGANIZATION_ID(this.$route.params.organizationId)
    },
    regionsPricesByProviders () {
      const regionsPricesByProvidersObject = {}

      // Reformate engines details to have details for specific engine and provider
      this.engines.forEach(e => {
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`] = regionsPricesByProvidersObject[`${e.engine}-${e.provider}`] || {}
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].engine = e.engine
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].provider = e.provider
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].regions = regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].regions || []
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].regions.push({ label: e.display_region, value: e.region })
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].prices = regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].prices || {}
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].prices[e.region] = e.templates[this.fpuType]
        regionsPricesByProvidersObject[`${e.engine}-${e.provider}`].supported_modes = e.supported_modes?.map(m => m.toLowerCase())
      })

      return Object.values(regionsPricesByProvidersObject)
    },
    filteredProviders () {
      if (!this.engines.length) return []

      // Filter provider for a specific engine
      const filteredProviders = this.engines.filter((provider) => {
        return provider.engine === this.item.engine
      })

      // Get unique list of provider
      return [...new Set(filteredProviders.map(provider => provider.provider))].map(p => {
        return {
          label: this.$t(`organization.data_storage_engine.${p}`),
          value: p,
          image: this.providerImage(p)
        }
      })
    },
    fpu: {
      get () {
        return `${this.item.desired_state?.fpu || this.item.fpu}`
      },
      set (value) {
        const newFpu = parseInt(value)
        if (!value || Number.isNaN(newFpu)) return
        this.item.update('price', this.filteredFpus[newFpu].instance_pricing * 100, false)
        this.item.update('fpu', newFpu, false)
        this.item.update('desired_state.fpu', newFpu, false)
      }
    },
    price () {
      return this.item.price || 0
    },
    instanceSizeOptions () {
      return this.filteredFpusSlider.map(fpu => {
        return {
          label: `${fpu} FPU-S`,
          value: fpu
        }
      })
    },
    totalFpu () {
      return (this.fpu * (this.instanceCount || 1)) || ''
    },
    instanceCount () {
      return this.item.desired_state?.instance_count || this.item.instance_count
    },
    highAvailabilityOptions () {
      const filteredRegions = this.regionsPricesByProviders.filter(p => p.engine === this.item.engine && p.provider === this.item.provider)
      return filteredRegions[0]?.supported_modes?.length || null
    },
    randomValue () {
      return Math.floor(Math.random())
    },
    canCustomize () {
      return this.item?.engine !== 'polar-data'
    }
  },
  mounted () {
    if (!this.item.provider) this.item.update('provider', this.filteredProviders[0].value, false)
    this.setOptionsByEngineAndProvider(this.item.provider, false, true)
  },
  methods: {
    setOptionsByEngineAndProvider (provider, updateItem = true, firstTime = false) {
      const filteredRegions = this.regionsPricesByProviders.filter(p => p.engine === this.item.engine && p.provider === provider)
      if (filteredRegions.length) {
        this.regionsPricesFiltered = filteredRegions[0]
        this.filteredRegions = filteredRegions[0].regions
        this.filteredFpus = filteredRegions[0].prices[filteredRegions[0].regions[0].value]
        this.filteredFpusSlider = Object.keys(this.filteredFpus)

        if (!this.item.region || updateItem) {
          this.item.update('region', filteredRegions[0].regions[0].value, false)
          this.item.update('display_region', filteredRegions[0].regions[0].label, false)
        }
        const fpu = this.item.desired_state?.fpu || this.item.fpu // We do not use this.fpu here because it is a string
        if (!fpu || updateItem) {
          if (!Object.keys(this.filteredFpus).includes(this.fpu)) this.fpu = Object.keys(this.filteredFpus)[0]
          this.item.update('price', this.filteredFpus[this.fpu].instance_pricing * 100, false)
        }
        if (!firstTime || this.ha === undefined) {
          this.supportedModes = filteredRegions[0].supported_modes
          let activateHa = false
          if (this.supportedModes.length < 2 && this.supportedModes.includes('ha')) activateHa = true
          this.item.update('ha', activateHa, false)
        }
        this.updateInstanceCount()
      } else {
        this.filteredFpus = []
        this.filteredFpusSlider = []
      }
    },
    providerSelection (value) {
      if (!value) return
      this.item.update('ha', undefined, false)
      this.setOptionsByEngineAndProvider(value)
      if (this.filteredRegions.length) {
        this.item.update('region', this.filteredRegions[0].value, false)
        this.item.update('display_region', this.filteredRegions[0].label, false)
      }
      this.item.update('provider', value, false)
    },
    regionSelection (value) {
      if (!value) return
      this.filteredFpus = this.regionsPricesFiltered.prices[value]
      if (!Object.keys(this.filteredFpus).includes(this.fpu)) this.fpu = Object.keys(this.filteredFpus)[0]
      this.item.update('price', this.filteredFpus[this.fpu].instance_pricing * 100, false)
      const regionObject = this.filteredRegions.find((region) => region.value === value)
      this.item.update('region', value, false)
      if (regionObject) this.item.update('display_region', regionObject.label, false)
    },
    learnMore () {
      window.open('https://docs.forepaas.io/#/en/product/billing/resources/index', '_blank')
    },
    toggleHighAvailability (val) {
      this.item.update('ha', val, false)
      this.item.update('desired_state.ha', val, false)
      this.updateInstanceCount()
    },
    updateInstanceCount () {
      let instanceCount = 1
      // Display only 1 instance when HA is enabled for Snowflake
      // This is because the number of instance is not available for Snowflake
      if (this.ha && this.item.engine === 'snowflake') instanceCount = 1
      else instanceCount = this.ha ? 2 : 1
      this.item.update('instance_count', instanceCount, false)
    },
    engineImage (engine) {
      try {
        return require(`@/core/assets/img/engines/${engine}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/engines/default.png')
      }
    },
    providerImage (provider) {
      try {
        return require(`@/core/assets/img/providers/${provider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    }
  }
}
</script>

<style lang="less">
.provider-informations {
  display        : flex;
  flex-direction : column;
  flex-wrap      : nowrap;
  justify-content: center;
  align-content  : center;
  align-items    : center;
  margin         : 0;

  .subtitle {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
      margin-left: 5px;

      &.snowflake {
        width: 15px;
        margin-left: 7px;
      }
    }

    .engine {
      font-weight: 600;
      margin-left: 5px;
    }
  }

  > .content {
    border          : 0.5px solid rgba(151, 167, 183, 0.21);
    border-radius   : 5px;
    width           : 478px;
    min-height      : auto;
    padding         : 25px 20px 5px 20px;
    background-color: white;
    margin          : 30px 0 47px 0;

    &.no-padding {
      padding       : 0px 24px 0px 26px;
    }

    .light-mode-row {
      margin-bottom: 16px;
    }

    .row {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before, &::after {
        display: none;
      }

      label {
        color         : #3E4550;
        font-size     : 14px;
        vertical-align: middle;
        font-weight   : 500;
        line-height   : 34px;

        &.fpu {
          height: 16px;
        }
      }

      .fpui-input-select-container {
        width: 234px;
      }
    }

    .instance-size {
      label {
        color: #97A7B7;
      }
      .separator {
        align-self: flex-end;
        padding: 0 7px 10px;
        span {
          font-size: 14px;
        }
      }
    }

    .instance-slider {
      width: 100%;
      border: 0.5px solid rgba(151,167,183,0.21);
      border-radius: 9px;
      background-color: #FFFFFF;
      box-shadow: 0 6px 10px 0 rgba(116,130,148,0.29);
      display: flex;
      justify-content: center;
      height: 94px;
      padding: 0 21px;
      margin-top: 26px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        left: calc(~"(100% - 25px)/2");
        top: -13px;
        background: white;
        transform: rotate(45deg);
        border: 0.5px solid rgba(151,167,183,0.21);
        border-bottom: none;
        border-right: none;
      }

      .recommended {
        color: #97a7b7;
        font-size: 10px;
        font-style: italic;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        position: absolute;
        top: 5px;
        left: 97px;
        z-index: 2;
      }
      .recommanded-highlight {
        border-radius: 100%;
        width: 20px;
        height: 20px;
        position:absolute;
        top: 26px;
        left: 118px;
        background: #E9ECF0;
        opacity: 0.5;
      }

      .vue-slider {
        margin-top: 25px;
        .vue-slider-rail {
          background-color: #E9ECF0;
        }
        .vue-slider-mark {
          width: 12px !important;
          height: 12px !important;
          border-radius: 50%;
          background-color: #E9ECF0;
          cursor: pointer;

          .vue-slider-mark-step {
            background-color: transparent;
          }
          .vue-slider-mark-label {
            color: #3E4550;
            font-family: "Source Sans Pro";
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 15px;
          }
          .vue-slider-mark-step-active, &.vue-slider-mark-active {
            background-color: #02CDFB;
          }
        }
        .vue-slider-process {
          background-color: #02CDFB;
        }
        .vue-slider-dot {
          width: 18px !important;
          height: 18px !important;
        }
        .vue-slider-dot-handle {
          cursor: grab;
          box-shadow: 0px 1px 5px 0px #b2beca;
        }
        .vue-slider-dot-tooltip-inner {
          display: none;
        }
      }

      .display-modal-in-trial {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
    }

    .options {
      margin-top: 25px;
      .row.title {
        margin-bottom: 0;
      }
      .high-availability {
        margin-bottom: 0;
      }
    }

    .description {
      color: #97A7B7;
      font-size: 12px;
    }

    .informations {
      margin-top: 10px;
      .row {
        background: #F6F9FC;
        border: 1px solid #CBD3DB;
        border-radius: 5px;
        ul {
          list-style: none;
          padding-inline-start: 0;
          li {
            display: flex;
            color: #97A7B7;
            &:not(:last-child) {
              margin-bottom: 5px;
            }
            i {
              font-size: 16px;
              padding: 0 5px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
