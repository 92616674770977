<template lang="pug">
  .security.fp-container
    .password-reset-container
      .title {{ $t('profile.security.update_password') }}
      form
        .line
          .old-password(v-if="user.has_password")
            fpui-input-password(
              :label="$t('profile.security.oldpassword')"
              :placeholder="$t('profile.security.oldpassword')"
              v-model="password.oldpassword"
            )
          //- .link(@click="forgetPassword") {{ $t('profile.security.forget') }}
        .line
          fpui-input-password-strength(
            :label="$t('profile.security.password')"
            :placeholder="$t('profile.security.password')"
            v-model="password.password"
            :error="hasPasswordError"
          )
          .error-message(v-if="hasPasswordError") {{ $t('profile.security.password_error') }}
        fpui-input-password(
          :label="$t('profile.security.repeat_password')"
          :placeholder="$t('profile.security.repeat_password')"
          v-model="password.repeatpassword"
          :error="hasPasswordError"
        )
        .error-message(v-if="hasPasswordError") {{ $t('profile.security.password_error') }}
        .button-container
          fpui-button(
            color='green'
            v-if="!isPasswordEmpty"
            :disabled="hasPasswordError || user.has_password && !password.oldpassword"
            @click="resetPassword"
            auto-width
          ) {{ $t('button.update') }}

    .linked-account
      .title {{ $t('profile.security.linked_account') }}
      fpui-table(
        :columns='authentications.columns'
        :actions='authentications.actions'
        :data='authentications.data'
      )
      .link-account-container()
        .provider(v-for="provider in restProviders")
          img(@click="linkAccount(provider)" :src='providerIcons[provider.name]')
        //- .link-text {{ $t('profile.security.more') }}

    //- .security-questions
    //-   .title {{ $t('profile.security.security_questions') }}
    //-   fpui-input-toggle(
    //-     :value="user.question_activated"
    //-     @input="(value) => user.update('question_activated',value , false)"
    //-   )
    //- .text {{ $t('profile.security.security_questions.content') }}

    .mfa
      multi-factor-authentication

    .recent-session
      .title {{ $t('profile.security.recent_session') }}
      fpui-table(
        :columns='recentSession.columns'
        :actions='recentSession.actions'
        :data='recentSession.data'
        sortOn="last_connection"
        :pagination='{ perPage: 9 }'
      )
</template>
<script>
import CellImageAndText from './CellImageAndText'
import MultiFactorAuthentication from './MultiFactorAuthentication'
import Config from '@/shared/Config'
import ConfirmModal from '@/shared/components/modals/Confirm'
import _findIndex from 'lodash/findIndex'
import _cloneDeep from 'lodash/cloneDeep'
import UAParser from 'ua-parser-js'
import moment from 'moment'
import qs from 'qs'
export default {
  components: { MultiFactorAuthentication },
  props: {
    user: { type: Object, required: true }
  },
  data () {
    return {
      password: {
        oldpassword: '',
        password: '',
        repeatpassword: ''
      },
      authenticationsList: [],
      providerIcons: {
        azure: require('@/core/assets/img/oauth/azure.svg'),
        bitbucket: require('@/core/assets/img/oauth/bitbucket.svg'),
        github: require('@/core/assets/img/oauth/github.svg'),
        gitlab: require('@/core/assets/img/oauth/gitlab.svg'),
        google: require('@/core/assets/img/oauth/google.svg')
      },
      listSession: []
    }
  },
  computed: {
    hasPasswordError () {
      if (this.isPasswordEmpty) return false
      return this.password.repeatpassword === '' || this.password.password === '' || this.password.repeatpassword !== this.password.password
    },
    isPasswordEmpty () {
      if (this.user.has_password) {
        if (this.password.repeatpassword === '' && this.password.password === '' && this.password.oldpassword === '') return true
        return false
      } else {
        if (this.password.repeatpassword === '' && this.password.password === '') return true
        return false
      }
    },
    mainProfile () {
      return this.user.profile.find(p => p.main) || this.user.profile[0]
    },
    restProviders () {
      const providerIds = this.user.profile.filter(p => p.authentication).map(p => p.authentication)
      return this.authenticationsList.filter(auth => providerIds.indexOf(auth.id) === -1)
    },
    authentications () {
      return {
        data: this.user.profile.filter(p => p.authentication && !p.main),
        actions: [{
          id: 'delete',
          icon: 'fp4 fp4-trash-can',
          click: async (row) => {
            const confirmResult = await ConfirmModal(this, {
              contentHtml: true,
              content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} <span style="font-weight:600"> ${row.email}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
              title: this.$t('profile.security.unlink'),
              yes: {
                text: this.$t('fpui-modal-confirm.remove'),
                color: 'red'
              }
            })
            if (!confirmResult) return
            const idx = _findIndex(this.user.profile, row)
            if (idx !== -1) {
              const profileCopy = _cloneDeep(this.user.profile)
              profileCopy.splice(idx, 1)
              this.user.update('profile', profileCopy)
            }
          }
        }],
        columns: [{
          name: 'authentication',
          label: this.$t('profile.security.application'),
          align: 'left',
          component: CellImageAndText,
          target: (row) => {
            const auth = this.authenticationsList.find(auth => auth.id === row.authentication)
            let image = null
            try {
              image = auth ? require(`@/core/assets/img/oauth/${auth.name}.svg`) : ''
            } catch (err) {
              image = null
            }
            return {
              text: auth ? auth.name : row.authentication,
              image: image
            }
          },
          paddingLeft: 17,
          grow: 0.8
        }, {
          name: 'email',
          label: this.$t('profile.security.email'),
          align: 'left',
          target: 'email',
          paddingLeft: 20
        }]
      }
    },
    recentSession () {
      return {
        data: this.listSession.filter(s => s.client_id === 'forepaas'),
        actions: [{
          id: 'delete',
          if: (row) => {
            return !row.active
          },
          icon: 'fp4 fp4-trash-can',
          click: async (row) => {
            const confirmResult = await ConfirmModal(this, {
              contentHtml: true,
              content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')}${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
              title: this.$t('profile.security.revoke'),
              yes: {
                text: this.$t('fpui-modal-confirm.remove'),
                color: 'red'
              }
            })
            if (!confirmResult) return
            try {
              await this.user.revokeSession(row._id)
              // Reload recent sessions data for updating table
              this.listSession = await this.user.listSession()
              this.$fpuiMessageBlock.success('Success')
            } catch (err) {
              this.$fpuiMessageBlock.error(err)
            }
          }
        }],
        columns: [{
          name: 'device',
          label: this.$t('profile.security.device'),
          align: 'left',
          target: row => {
            const parser = new UAParser()
            parser.setUA(row.user_agent)
            const result = parser.getResult()
            return `${result.os.name} - ${result.browser.name}`
          },
          paddingLeft: 20,
          grow: 0.8
        }, {
          name: 'first_connection',
          label: this.$t('profile.security.first_connection'),
          align: 'left',
          target: (row) => {
            return moment(row.createdAt).format(this.$t('date.formatter.short'))
          },
          sortable: row => row.createdAt,
          paddingLeft: 20
        }, {
          name: 'last_connection',
          label: this.$t('profile.security.last_connection'),
          align: 'left',
          target: (row) => {
            return moment(row.updatedAt).format(this.$t('date.formatter.short'))
          },
          sortable: row => row.updatedAt,
          reverse: true,
          paddingLeft: 20
        }]
      }
    }
  },
  async mounted () {
    // Load recent sessions data
    this.listSession = await this.user.listSession()
  },
  async created () {
    // Get all provider id and name for linked account
    this.authenticationsList = await this.$api.KING.authentications.list()
    // Add message listener for getting the result of window.open
    window.addEventListener('message', this.eventListener, false)
  },
  destroyed () {
    // On destroy, remove message event listener
    window.removeEventListener('message', this.eventListener)
  },
  methods: {
    async resetPassword () {
      try {
        if (!this.hasPasswordError) {
          await this.user.resetPassword({
            oldpassword: this.password.oldpassword,
            password: this.password.password
          })
          this.$fpuiMessageBlock.success(this.$t('profile.security.reset_password_success'))
          const config = await Config()
          setTimeout(() => {
            document.location.href = `${config.KING}/v1/logout?redirect_uri=${encodeURIComponent(window.location.origin)}`
          }, 3000)
        }
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    },
    forgetPassword () {
      // TODO Open forget pwd link
    },
    async linkAccount (provider) {
      const config = await Config()
      const queryString = qs.stringify({
        email: this.$store.getters.SESSION.email,
        popup: true,
        link: true,
        id: provider._id,
        redirect_uri: window.location.origin + '/auth/#/iframe-callback'
      })
      const url = `${config.KING}/v1/login?${queryString}`
      window.open(url, '', 'menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=500')
    },
    eventListener (event) {
      if (event?.data?.id === 'auth' && event?.data?.op === 'ADD_LINKED_ACCOUNT') {
        this.$store.dispatch('REFRESH')
      }
    }
  }
}
</script>
<style lang="less">
@import "~@/shared/styles/_variables.less";

.security {
  display: flex;
  flex-direction: column;
  margin: 42px auto;
  width: 556px;
  .title {
    font-size: 12px;
    color: @blue;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 22px;
  }
  .text {
    font-size: 14px;
  }
  .line {
    margin-bottom: 15px;
  }
  .password-reset-container {
    .error-message {
      color: @red;
    }
    .old-password {
      .link {
        position: relative;
        top: -55px;
        left: 435px;
        color: @grey;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 600;
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
  }
  .linked-account {
    margin-top: 52px;
    .link-account-container {
      margin-top: 29px;
      margin-left: 17px;
      display: flex;
      align-items: center;
      height: 30px;
      color: @grey;
      cursor: pointer;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0;
      .provider {
        img {
          width: 30px;
          margin-right: 10px;
        }
      }
      .link-text {
        margin-left: 15px;
      }
    }
  }
  .security-questions {
    margin-top: 59px;
    display: flex;
    justify-content: space-between;
    .fpui-input-toggle {
      width: auto;
    }
  }
  .mfa {
    margin-top: 59px;
  }
  .recent-session {
    margin-top: 59px;
  }
}
</style>

