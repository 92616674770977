<template lang="pug">
  .fp-app.no-aside(:class="$route.meta.theme||'v4'")
    fp-loader(v-if="loading")
    fp-header(v-if="$store.getters.SESSION && !fullscreen")
    fp-aside(
      :aside='aside'
      v-if="aside && $store.getters.SESSION && !fullscreen"
    )
    main(
      v-if="$store.getters.SESSION || noNewAccount"
      :style="{left,top}",
      :class="{bordered:$route.meta.bordered}"
    )
      router-view
</template>

<script>
import FpHeader from './components/FpHeader.vue'
import _get from 'lodash/get'
import Vue from 'vue'
import Config from '@/shared/Config'
export default {
  components: {
    FpHeader
  },
  data () {
    return {
      loading: false,
      noNewAccount: false
    }
  },
  computed: {
    fullscreen () {
      return this.$route.meta.fullscreen || false
    },
    aside () {
      if (!this.$route.meta.aside) return null
      return this.$route.meta.aside(this.$route)
    },
    left () {
      return this.aside ? '124px' : (this.$route.meta.bordered ? '20px' : 0)
    },
    top () {
      return !this.fullscreen ? '60px' : 0
    },
    session () {
      return this.$store.getters.SESSION
    }
  },
  async created () {
    let lang = _get(this.$route, 'query.lang') || window.localStorage.getItem('hq_lang') || 'en'
    try {
      lang = JSON.parse(lang)
    // eslint-disable-next-line no-empty
    } catch (err) {}
    Vue.i18n.set(lang)
    window.localStorage.setItem('hq_lang', lang)
  },
  async mounted () {
    // Redirect if no new account
    if (this.$route.path.includes('no_new_account')) {
      this.noNewAccount = true
      this.$router.push('/no_new_account')
      return
    }

    // We check the route params to see if a user wants to join an organization
    // If yes, he join it and then we check his session (redirect to login page or home)
    let joinOrga = false
    const { id, organizationId, tokenJoin, email, signup } = this.$route.query
    const orgaId = id || organizationId
    if (orgaId && tokenJoin && email) {
      joinOrga = true
      try {
        await this.$api.KING.organizations.userJoins(orgaId, tokenJoin)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
    }

    // If signup, redirect to signup page
    if (signup) {
      const config = await Config()
      const urlSignup = `${config.KING}/v1/login?signup=true&email=${encodeURIComponent(email)}`
      document.location.href = `${config.KING}/v1/logout?redirect_url=${encodeURIComponent(urlSignup)}`
      return
    }

    this.loading = true
    await this.$store.dispatch('CHECK_CMP_SESSION')
    this.loading = false

    // If mail in link to join orga is not the email in session, we logout
    if (joinOrga && email !== this.session?.email) this.$store.dispatch('LOGOUT_CMP')
    if (joinOrga) this.$store.commit('SET_ACTIVE_ORGANIZATION', orgaId)
  }
}
</script>

<style lang="less">
  main {
    position: absolute;
    right:0;
    bottom: 0;
    &.bordered {
      right: 20px;
      box-shadow: 0 3px 13px 0 rgb(151 167 183 / 30%);
      background:white;
    }
    .router-wrapper {
      height: 100%;
      overflow: auto;
    }
  }
</style>
