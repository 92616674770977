<template lang="pug">
  .orga-cell-name.cell-tags.td.fp-tree-cell
    img(style='vertical-align: middle;height: 31px; width: 31px; border-radius: 3px; margin-right: 13px; object-fit: contain;' :src="imageProvider")
    span {{this.value.object.name}}
</template>

<script>
import aws from '@/core/assets/img/providers/aws.png'
import gcp from '@/core/assets/img/providers/gcp.png'
import ovh from '@/core/assets/img/providers/ovh.png'
import azure from '@/core/assets/img/providers/azure.png'
import forepaas from '@/core/assets/img/providers/forepaas.png'


export default {
  props: {
    value: { type: Object, default: () => {} }
  },
  computed: {
    imageProvider () {
      if (this.value.object.provider === 'forepaas') { return forepaas }
      if (this.value.object.provider === 'azure') { return azure }
      if (this.value.object.provider === 'aws') { return aws }
      if (this.value.object.provider === 'gcp') { return gcp }
      if (this.value.object.provider === 'ovh') { return ovh }
      return null
    }
  }
}
</script>

<style lang="less">
.orga-cell-name {
  line-height: 45px;
}
</style>
