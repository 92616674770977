<template lang="pug">
  .data-storage-engine-instance-modal
    fp-loader(v-if="loading")
    fpui-modal
      fpui-steps(
        ref="dataplantCreation"
        @complete='onComplete()'
        :cancel="() => $emit('close')"
        :stepper="!lightMode"
        v-if="steps.length"
      )
        fpui-step(
          v-for="(step, i) in steps"
          :beforeLeave="step.onLeave"
          :onNext="step.onNext"
          :onPrevious="step.onPrevious"
          :disabled="step.disabled"
          :validate="step.validate"
          :key='i',

        )
          fpui-modal-header(
            @close='$emit("close")'
            closeTopButton
            unbordered
          )
          fpui-modal-body
            component(
              :is='step.component'
              :item='item'
              :configuration='defaultConfiguration'
              :title='step.title'
              :subtitle='step.subtitle'
              :isEditing='isEditing'
              :organizationId="organizationId"
              v-bind="{...step.props}"
              @updateConfiguration="updateConfiguration"
            )
          fpui-modal-footer
</template>

<script>
import EngineSelection from './EngineSelection'
import ProviderInformations from './ProviderInformations'
import InstanceInformations from './InstanceInformations'
import ProviderInstanceInformationsEdit from './ProviderInstanceInformationsEdit'
import Validation from './Validation'

export default {
  components: {
    EngineSelection,
    ProviderInformations,
    InstanceInformations,
    ProviderInstanceInformationsEdit,
    Validation
  },
  props: {
    item: { type: Object, default: () => ({}) },
    afterComplete: { type: Function, default: () => null },
    organizationId: { type: String, required: true },
    engine: { type: String, required: false, default: () => null },
    doNotCreate: { type: Boolean, required: false },
    lightMode: { type: Boolean, required: false }
  },
  data () {
    return {
      tmpTrackFpu: this.item?.desired_state?.fpu || this.item?.fpu,
      confirm: false,
      loading: false,
      steps: [],
      defaultConfiguration: {
        data_mart: false,
        data_prim: false,
        data_ml: false
      }
    }
  },
  computed: {
    isEditing () {
      // This is used to disabled some fields when editing an instance
      return !!this.item.id
    }
  },
  mounted () {
    this.item.backup()

    if (this.engine) this.item.update('engine', this.engine, false)

    // Init steps
    this.steps = [
      {
        component: 'provider-informations',
        title: this.isEditing ? 'organization.data_storage_engine.modal_edit' : 'organization.data_storage_engine.modal_new',
        subtitle: `organization.data_storage_engine.modal_subtitle_provider_${this.isEditing ? 'new' : 'edit'}`,
        props: {
          lightMode: this.lightMode,
          title: this.lightMode ? this.$t('dataplant.creation.engine.new') : null
        },
        validate: () => {
          return this.lightMode ? (this.item.display_name && this.item.provider && this.item.region && (this.item?.engine === 'polar-data' || (this.item.desired_state?.fpu || this.item.fpu))) : this.item.provider && this.item.region && (this.item?.engine === 'polar-data' || (this.item.desired_state?.fpu || this.item.fpu))
        }
      }
    ]
    if (!this.lightMode) {
      this.steps.push({
        component: 'instance-informations',
        title: this.isEditing ? 'organization.data_storage_engine.modal_edit' : 'organization.data_storage_engine.modal_new',
        subtitle: `organization.data_storage_engine.modal_subtitle_provider_${this.isEditing ? 'new' : 'edit'}`,
        organizationId: this.organizationId,
        validate: () => this.item.display_name
      })
    }
    if (!this.engine) {
      this.steps.unshift({
        component: 'engine-selection',
        title: this.isEditing ? 'organization.data_storage_engine.modal_edit' : 'organization.data_storage_engine.modal_add',
        subtitle: 'organization.data_storage_engine.modal_subtitle_engine',
        validate: () => this.item.engine
      })
    }
    if (this.isEditing) {
      this.steps = [
        {
          component: 'provider-instance-informations-edit',
          title: 'organization.data_storage_engine.modal_edit',
          subtitle: 'organization.data_storage_engine.modal_subtitle_provider_edit',
          organizationId: this.organizationId,
          validate: () => this.item.display_name
        }
      ]
    }
    if (!this.lightMode) {
      this.steps.push({
        component: 'validation',
        title: this.isEditing ? 'organization.data_storage_engine.modal_edit' : 'organization.data_storage_engine.modal_new',
        subtitle: 'organization.data_storage_engine.modal_subtitle_validation'
      })
    }
  },
  destroyed () {
    if (!this.confirm) this.item.restore()
  },
  methods: {
    hasChanges () {
      if (this.item.hasChanges) return true
      return false
    },
    async onComplete () {
      this.confirm = true
      this.loading = true
      let configuration = null
      try {
        if (this.doNotCreate) {
          configuration = Object.keys(this.defaultConfiguration).filter(d => this.defaultConfiguration[d])
        } else if (this.isEditing) {
          // Get key where id is in default configuration
          configuration = Object.keys(this.defaultConfiguration).filter(d => this.defaultConfiguration[d])
          const newItem = await this.item.save(configuration)
          this.$store.commit('STORAGE_INSTANCES_UPDATE', newItem)
          this.$analytics.track('Storage Engine Edited', {
            instance_id: this.item.id,
            size_increase: (this.item.desired_state?.fpu || this.item.fpu) - this.tmpTrackFpu,
            organization_id: this.organizationId,
            high_availability: this.item.ha
          })
        } else {
          configuration = Object.keys(this.defaultConfiguration).filter(d => this.defaultConfiguration[d])
          const newInstance = await this.item.create(configuration)
          this.$analytics.track('Storage Engine Created', {
            instance_id: newInstance.id,
            cloud_provider_name: newInstance.provider,
            cloud_provider_location: newInstance.region,
            resources_allocated: newInstance.fpu,
            resources_type: newInstance.fpu_type,
            engine: newInstance.engine,
            origin: 'Organization',
            organization_id: this.organizationId,
            high_availability: newInstance.ha
          })
          this.$store.commit('STORAGE_INSTANCES_CREATE', newInstance)
        }
        this.afterComplete(this.item, configuration)
        this.$emit('close')
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        this.confirm = false
      }
      this.loading = false
    },
    updateConfiguration (key, value) {
      this.defaultConfiguration[key] = value
    }
  }
}
</script>

<style lang="less">
.data-storage-engine-instance-modal {
  .fpui-modal {
    background-image   : url('./img/modal_background.png');
    background-color   : white;
    background-repeat  : no-repeat;
    background-position: center 70%;
    background-size    : 100%;

    .modal-body {
      .header {
        .title {
          text-align: center;
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 40px;
          color: #3E4550;
          margin-top: -40px;
        }
        .subtitle {
          color: #3E4550;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
  .modal-footer {
    padding: 0;
  }

  .fpui-steps {
    .fpui-steps-footer {
      position: relative;
      padding: 16px 0;
      .actions {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        .next {
          &:only-child {
            margin-left: auto;
          }
          button:not(.reverse) {
            background-color: #00CCF9;
          }
        }
      }
      .stepper {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        .step {
          opacity: 0.5;
          background: #00CCF9;
          width: 5px;
          height: 5px;
          margin: 0 6px;
          &.active {
            width: 11px;
            height: 11px;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
