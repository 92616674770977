<template lang="pug">
  .orga-cell-name.cell-tags.td.fp-tree-cell
    img(style='vertical-align: middle;height: 31px; width: 31px; border-radius: 3px; margin-right: 13px; object-fit: contain;' :src="imageProfile")
    span {{ fullName }}
</template>

<script>
import neutralImg from '@/core/assets/img/users/neutral@2x.png'
import man1Img from '@/core/assets/img/users/man_1@2x.png'
import man2Img from '@/core/assets/img/users/man_2@2x.png'
import woman1Img from '@/core/assets/img/users/woman_1@2x.png'
import woman2Img from '@/core/assets/img/users/woman_2@2x.png'

export default {
  props: {
    value: { type: Object, default: () => {} }
  },
  computed: {
    fullName () {
      let fullname = ''
      const usr = this.value.object.account || this.value.object.user
      const mainProfile = usr?.profile.find(p => p.authentication === '5910ea60000eaf1000000001')
      if (mainProfile?.given_name || mainProfile?.family_name) {
        fullname = [mainProfile?.given_name, mainProfile?.family_name].filter(e => e).join(' ')
      } else {
        fullname = this.value.object.email
      }
      return fullname
    },
    imageProfile () {
      const manImages = [man1Img, man2Img]
      const womanImages = [woman1Img, woman2Img]
      const usr = this.value.object.account || this.value.object.user
      const mainProfile = usr?.profile.find(p => p.authentication === '5910ea60000eaf1000000001')
      if (mainProfile) {
        if (mainProfile.picture) {
          return mainProfile.picture
        }
        if (mainProfile.gender === 'male') {
          return manImages[mainProfile.family_name.length % 2]
        }
        if (mainProfile.gender === 'female') {
          return womanImages[mainProfile.family_name.length % 2]
        }
        if (mainProfile.gender === 'other') {
          return neutralImg
        }
      } else {
        return neutralImg
      }
      return neutralImg
    }
  }
}
</script>

<style lang="less">
.orga-cell-name {
  line-height: 45px;
}
</style>
