<template lang="pug">
  .access-manager
    .title-content(
      :style="{ 'border-bottom': opened ? '1px solid #E9ECF0' : 'none' }"
    )
      .title-helper
        .title {{ $t('organization.users.acess_manager.resource.'+resource) }}
        fpui-helper(
          position='top'
          :text="$t('organization.users.acess_manager.helper.'+resource)"
        )
      i.fp4.fp4-angle-up(
        @click="setOpen"
        :style="{ transform: `rotate(${opened ? '180' : '90'}deg)` }"
      )
    .content(
      v-if="opened"
    )
      //- fpui-input-checkbox(
      //-   v-for="(action,index) in ruleActions"
      //-   :key="index"
      //-   v-model="checked[action.value]"
      //- ) {{ action.label }}
      fpui-table.fp-no-margin(
        :columns='columns'
        :actions='actions'
        :data='data'
      )
      fpui-button(
        :auto-width="true"
        icon='fp4 fp4-plus'
        icon-left
        color="white",
        @click="addAccess") {{ 'organization.users.edit_user.permission.button.add_new_rule' | translate }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import AccessManagerAddRule from './AccessManagerAddRule.vue'
export default {
  components: {
    AccessManagerAddRule
  },
  props: {
    organizationId: { type: String, required: true },
    value: { type: Array, default: () => [] },
    resource: { type: String, required: true }
  },
  data () {
    const actions = {
      dataplants: ['read', 'create', 'update', 'delete', 'deploy', 'logs'],
      'store-packages': ['update', 'delete', 'buy'],
      'store-versions': ['update', 'delete'],
      dns: ['update', 'create', 'delete'],
      storage_config: ['update', 'delete'],
      storage_instance: ['create', 'read', 'update', 'delete']
    }
    const ruleActions = actions[this.resource]
    const checked = {}
    ruleActions.forEach(a => {
      checked[a] = false
    })
    return {
      ruleActions: ruleActions.map(a => {
        return {
          label: a,
          value: a
        }
      }),
      checked,
      opened: true
    }
  },
  computed: {
    data () {
      return this.value?.filter(row => {
        return row.Resource === this.resource
      })
    },
    actions () {
      return [
        {
          id: 'edit',
          icon: 'fp4 fp4-pencil',
          if (item) {
            return item.Effect === 'Allow'
          },
          click: (item) => {
            const idx = this.value.findIndex(r => r === item)
            if (idx === -1) return
            this.$modal.show(AccessManagerAddRule, {
              resource: this.resource,
              organizationId: this.organizationId,
              parentRule: item,
              actions: this.ruleActions,
              onConfirm: (access) => {
                const value = _cloneDeep(this.value)
                value[idx] = access
                this.$emit('input', value)
              }
            }, {
              height: 'auto',
              width: 538
            })
          }
        },
        {
          id: 'remove',
          icon: 'fp4 fp4-trash-can',
          click: (item) => {
            const idx = this.value.findIndex(r => r === item)
            if (idx === -1) return
            const value = _cloneDeep(this.value)
            value.splice(idx, 1)
            this.$emit('input', value)
          }
        }
      ]
    },
    columns () {
      return [{
        name: 'name',
        label: this.$t('organization.users.edit_user.permission.tab.title.name'),
        align: 'left',
        target: 'Name'
      }, {
        name: 'effect',
        label: this.$t('organization.users.edit_user.permission.tab.title.effect'),
        align: 'left',
        target: 'Effect'
      }, {
        name: 'action',
        label: this.$t('organization.users.edit_user.permission.tab.title.action'),
        align: 'left',
        target: (row) => {
          if (row.Action === '*') return '-- All --'
          return row.Action
        }
      }, {
        name: 'attributes',
        label: this.$t('organization.users.edit_user.permission.tab.title.attributes'),
        align: 'left',
        target: (row) => {
          if (row.Resource === 'dataplants') {
            if (!row.Attributes?.dataplantId) return '-- All --'
            const dp = this.$store.getters.DATAPLANT_BY_ID(row.Attributes.dataplantId)
            if (dp) return dp.name
            if (row.Attributes.dataplantId === '*') return '-- All --'
            return row.Attributes.dataplantId
          }
          return '--All --'
        }
      }]
    }
  },
  mounted () {
    // this.data.forEach(row => {
    //   if (row.Effect === 'Allow' && row.Action !== '*') {
    //     this.checked[row.Action] = true
    //   }
    // })
    // this.$watch('checked', {
    //   deep: true,
    //   handler: this.updateFromChecked
    // })
  },
  methods: {
    updateFromChecked () {
      // for (const action in this.checked) {
      //   const val = this.checked[action]
      // }
    },
    addAccess () {
      this.$modal.show(AccessManagerAddRule, {
        resource: this.resource,
        actions: this.ruleActions,
        organizationId: this.organizationId,
        onConfirm: (access) => {
          const value = _cloneDeep(this.value)
          value.push(access)
          this.$emit('input', value)
        }
      }, {
        height: 'auto',
        width: 538
      })
    },
    setOpen () {
      this.opened = !this.opened
    }
  }
}
</script>

<style lang="less">
.access-manager {
  text-transform: capitalize;
  font-family: "Source Sans Pro";
  border: 1px solid #E9ECF0;
  border-radius: 8px;
  margin-top: 12px;

  .title-content {
    height: 42px;
    padding: 12px 21px;
    display: flex;
    justify-content: space-between;

    .title-helper{
      align-items: center;
      display: flex;

      .title {
        color: #3E4550;
        font-size: 15px;
        font-weight: 600;
      }
    }

    i.fp4.fp4-angle-up {
      cursor: pointer;
      font-size: 16px;
      color: #3E4550;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .content {
    padding: 0 21px;
    padding-bottom: 12px;

    .button-container {
      margin-top: 10px;
    }
  }
}
</style>
