<template lang="pug">
.subscription-new
  fpui-modal
    fp-loader(v-if="loading || priceLoading")
    fpui-modal-header(
      @close='$emit("close")'
      closeTopButton
    )
    fpui-modal-body(v-if="!priceLoading")
      fpui-steps(
        @complete='onComplete()'
        nextColor="blue-flash"
        :disabledNext="!plan"
        :stepper="!!plan"
        :validate="validateSteps"
      )
        fpui-step
          step-plan(
            :plan="plan" @plan="v => plan = v"
            :billing-period="billingPeriod" @billing-period="v => billingPeriod = v"
            :trial="trial"
          )
        fpui-step(v-if="!trial")
          step-custom(:plan="plan" :support="support" :billing-period="billingPeriod" @support="v => support = v")
          estimated-price(:plan="plan" :support="support" :billing-period="billingPeriod")
          hr
        fpui-step(v-if="!changingSubscription")
          step-committed(:committeds="committeds" @committeds="v => committeds = v")
          hr
        fpui-step(v-if="!changingSubscription")
          step-account(
            :customer-account-id="customerAccountId"
            @customer-account-id="caId => changeCustomerAccountId(caId)"
          )
          hr
        fpui-step(:on-next="onPaymentDone" :disabled="!customerAccountId" v-if="!trial")
          step-payment(
            ref="payment"
            :plan="plan"
            :payment-source="paymentSource"
            @payment-source="ps => paymentSource = ps"
            :customer-account-id="customerAccountId"
            @customer-account-id="caId => customerAccountId = caId"
            :from-trial="trial"
          )
          hr
        fpui-step(:on-next="onBillingContactDone" :disabled="!customerAccountId" v-if="!trial")
          step-billing-contact(:customer-account-id="customerAccountId" ref="billingContact")
          hr
        fpui-step.shortened-funnel__payment(v-if="trial")
          step-payment(
            ref="payment"
            :plan="plan"
            :payment-source="paymentSource"
            @payment-source="ps => paymentSource = ps"
            :customer-account-id="customerAccountId"
            @customer-account-id="caId => customerAccountId = caId"
            :from-trial="trial"
          )
          step-billing-contact(:customer-account-id="customerAccountId" ref="billingContact" :condensed="true")
          //- if: status === in_trial or cancelled
          .in_trial_cancelled {{ $t(subscriptionStatus === 'in_trial' ? 'subscription.reactivate.in_trial' : 'subscription.reactivate.cancelled' ) }}
          hr
        fpui-step(:disabled="!customerAccountId || !this.billingContactValid" v-if="!trial")
          step-confirm(
            :customer-account-id="customerAccountId"
            :plan="plan"
            :committeds="committeds"
            :support="support"
            :billing-period="billingPeriod"
            @billing-period="v => billingPeriod = v"
            :payment-source="paymentSource"
            :from-trial="trial"
          )
          hr
</template>
<script>
import StepPlan from './Steps/Plan.vue'
import StepCustom from './Steps/Custom.vue'
import StepCommitted from './Steps/Committed.vue'
import StepAccount from './Steps/Account.vue'
import StepPayment from './Steps/Payment.vue'
import StepBillingContact from './Steps/BillingContact.vue'
import StepConfirm from './Steps/Confirm.vue'
import EstimatedPrice from './EstimatedPrice.vue'
import _get from 'lodash/get'

export default {
  components: {
    StepPlan,
    StepCustom,
    StepCommitted,
    StepAccount,
    StepPayment,
    StepBillingContact,
    StepConfirm,
    EstimatedPrice
  },
  props: {
    organizationId: {
      type: String,
      required: true
    },
    trial: {
      type: Boolean,
      required: false,
      default: false
    },
    refreshAfterComplete: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      paymentSource: null,
      customerAccountId: null,
      plan: null,
      support: 'basic',
      committeds: [],
      billingPeriod: 'month'
    }
  },
  computed: {
    priceLoading () {
      return !this.$store.getters.READY('core_prices')
    },
    account () {
      return this.$store.getters.ACCOUNT_BY_ID(this.customerAccountId)
    },
    billingContactValid () {
      if (!this.account) return false
      const mandatoryKeys = [
        'billing_address.company',
        'billing_address.email',
        'billing_address.last_name',
        'billing_address.first_name',
        'billing_address.line1',
        'billing_address.phone',
        'billing_address.zip',
        'billing_address.city',
        'billing_address.country'
      ]
      for (const key of mandatoryKeys) {
        if (!_get(this.account, key)) {
          return false
        }
      }
      return true
    },
    currencyCode () {
      return this.$store.getters.SESSION.currency
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    buttons () {
      return [{
        label: this.$t('cancel'),
        type: 'close',
        handler: () => {
          this.$emit('close')
        }
      }, {
        label: this.$t('confirm'),
        color: 'blue-flash',
        async handler () {
          this.$emit('close')
        }
      }]
    },
    changingSubscription () {
      return this.organization.subscription
    },
    validateSteps () {
      if (this.trial) {
        return this.onPaymentDone && this.onBillingContactDone && this.customerAccountId && this.billingContactValid
      }
      return true
    },
    subscriptionStatus () {
      return this.organization?.subscription?.status
    }
  },
  async mounted () {
    if (this.changingSubscription) this.customerAccountId = this.organization.subscription?.customer_account_id
  },
  methods: {
    async onPaymentDone () {
      await this.$refs.payment.onDone()
    },
    async onBillingContactDone () {
      await this.$refs.billingContact.onDone()
    },
    async onComplete () {
      this.loading = true
      try {
        if (this.trial) {
          await this.onPaymentDone()
          await this.onBillingContactDone()
        }
        const subscriptionMethod = this.trial ? 'updateSubscription' : 'createSubscription'
        await this.organization[subscriptionMethod]({
          plan: this.plan,
          support: this.support,
          billing_period_unit: this.billingPeriod,
          currency_code: this.currencyCode.toLowerCase(),
          customer_account_id: this.customerAccountId,
          committeds: this.committeds.map(committed => ({
            plan: `${committed.resource_type}-${committed.provider}`,
            billing_period_unit: committed.billing_period_unit,
            term_commitment: committed.term_commitment,
            quantity: committed.quantity
          }))
        })
        if (this.refreshAfterComplete) { window.location.reload() }
        this.$emit('close')
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    changeCustomerAccountId (caId) {
      this.paymentSource = null
      this.customerAccountId = caId
    }
  }
}
</script>
<style lang="less">
.subscription-new {
  hr {
    margin: 0 -29px 20px;
    width: calc(100% + 58px);
  }
  .fpui-modal .modal-body {
    background:url('~./assets/modal_background.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .modal-header {
    height: 0;
  }

  .shortened-funnel__payment {
    .in_trial_cancelled {
      color: #B2BECA;
      font-size: 14px;
      text-align: center;
      padding-bottom: 25px;
    }
  }

  .header {
    text-align: center;
    h4 {
      font-size: 32px;
      margin: 10px 0;
      font-weight: bold;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .fpui-steps-footer {
    .actions {
      justify-content: space-between;
    }
  }
  .stepper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
