<template lang="pug">
  .appservice-gitlab
    fpui-button(
      color="blue-flash"
      v-if="!isLinked"
      auto-width
      @click="linkAccount"
    ) {{ $t(`appservice.create.link_account`,['Gitlab']) }}
    div(v-else)
      fpui-input-label {{$t('appservice.create.branch.repository')}}
      fpui-table(
        :height="'200px'"
        :columns='columns'
        :actions='actions'
        :data='repositories'
      )
      br
      fpui-input-select(
        :placeholder="$t('appservice.create.branch.placeholder')"
        v-if="branches"
        :options="branches.map(b=>({label:b,value:b}))"
        :label="$t('appservice.create.branch.label')"
        :value="value.repository.branch"
        @input="(v)=>value.update('repository.branch',v,false)"
      )
      br(v-if="branches")

</template>

<script>
import Config from '@/shared/Config'
import moment from 'moment'
import qs from 'qs'
export default {
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      token: null,
      branches: null,
      repository: null,
      repositories: []
    }
  },
  computed: {
    isLinked () {
      return !!this.$store.getters.SESSION.profile.find(p => p.authentication === '5910ea60000eaf1000000008')
    },
    actions () {
      return [{
        id: 'select',
        icon: 'fp4 fp4-eye',
        click: async (row) => {
          this.openRepo(row)
        }
      }]
    },
    columns () {
      return [{
        name: 'name',
        label: this.$t('appservice.repository.name'),
        align: 'left',
        sortable: true,
        target: (row) => {
          return row.name
        }
      }, {
        name: 'url',
        label: this.$t('appservice.repository.url'),
        align: 'left',
        sortable: true,
        target: (row) => {
          return row.url
        }
      }, {
        name: 'created_at',
        label: this.$t('appservice.repository.created_at'),
        align: 'left',
        sortable: (row) => row.created_at,
        target: (row) => {
          return moment(row.created_at).format(this.$t('date.formatter.short'))
        }
      }]
    }
  },
  async created () {
    window.addEventListener('message', this.eventListener, false)
  },
  destroyed () {
    // On destroy, remove message event listener
    window.removeEventListener('message', this.eventListener)
  },
  mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      if (!this.isLinked) return
      this.token = await this.$api.KING.getToken('5910ea60000eaf1000000008')
      const { repositories } = await this.$api.APPSERVICE.request({
        method: 'GET',
        url: '/repo/explore',
        params: {
          provider: 'gitlab',
          access_token: this.token
        }
      })
      this.repositories = repositories
    },
    async openRepo (repository) {
      this.branches = null
      this.repository = repository
      this.value.update('repository.url', this.repository.url, false)
      this.value.update('repository.authentication', false, false)
      this.value.update('repository.type', 'git', false)
      const { branches } = await this.$api.APPSERVICE.request({
        method: 'GET',
        url: '/repo/git',
        params: {
          url: encodeURIComponent(repository.url),
          provider: 'gitlab',
          access_token: this.token
        }
      })
      this.branches = branches
    },
    eventListener (event) {
      if (event?.data?.id === 'auth' && event?.data?.op === 'ADD_LINKED_ACCOUNT') {
        this.$store.dispatch('REFRESH')
        this.refresh()
      }
    },
    async linkAccount () {
      const config = await Config()
      const queryString = qs.stringify({
        email: this.$store.getters.SESSION.email,
        popup: true,
        link: true,
        id: 'gitlab',
        redirect_uri: window.location.origin + '/auth/#/iframe-callback'
      })
      const url = `${config.KING}/v1/login?${queryString}`
      window.open(url, '', 'menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=500')
    }
  }
}
</script>

<style lang="less">
</style>
