<template lang="pug">
  .dataplant-deletion-modal
    fp-loader(v-if="loading")
    .fpui-modal
      fpui-modal-header(
        :title="$t('dataplant.deletion.title', [dataplant.name])"
        @close='$emit("close")'
        closeTopButton
      )
      fpui-modal-body.no-padding
        .content
          span {{ $t('dataplant.deletion.content') }}
          fpui-input-text(
            :id="`cy-input-dataplant-delete`"
            v-model="userInput"
          )
      fpui-modal-footer(:buttons="buttons")
</template>

<script>

export default {
  props: {
    organization: { type: Object, required: true },
    dataplant: { type: Object, required: true }
  },
  data () {
    return {
      userInput: '',
      loading: false,
      buttons: [{
        label: this.$t('button.cancel'),
        type: 'close',
        handler: () => {
          this.$emit('close')
        }
      }, {
        label: this.$t('button.delete'),
        color: 'red',
        disabled: () => {
          if (this.userInput.toLowerCase().replace(/ /g, '') === this.dataplant.name.toLowerCase().replace(/ /g, '')) return false
          return true
        },
        handler: this.sendEmail
      }]
    }
  },
  async mounted () {
  },
  methods: {
    async sendEmail () {
      this.loading = true
      try {
        const email = await this.organization.sendDataplantRemovalEmail(this.dataplant)
        if (email) {
          this.$fpuiMessageBlock.pop('success', 'An email has been sent to your adress with instruction to confirm the deletion of the Dataplant')
          this.$emit('close')
        }
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
  .dataplant-deletion-modal {
    position: relative;
    .content {
      text-align: center;
      .fpui-input-text {
        margin-top: 15px;
      }
    }
  }
</style>
