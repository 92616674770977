<template lang="pug">
  .no-organization
    fp-loader(v-if="loading")
    h4 {{ $t('organization.create') }}
      .form-create-orga
        label {{ $t('organization.create.name') }}
        fpui-input-text(
          color="blue"
          :placeholder="$t('organization.create.name_placeholder')"
          :value="newOrganization.name"
          @input="value => newOrganization.update('name', value, false)"
        )
      .button
        fpui-button(
          color="green"
          @click="createOrganization()"
          :disabled="!newOrganization.name"
        ) {{ $t('organization.create.valid') }}
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      newItem: this.$api.KING.organizations.new()
    }
  },
  computed: {
    newOrganization () {
      return this.newItem
    }
  },
  methods: {
    async createOrganization () {
      this.loading = true
      try {
        // Create orga in back
        const response = await this.newOrganization.create(this.newOrganization.name)
        // Get new orga and put it in store
        const newOrga = await this.$api.KING.organizations.get(response.id)
        this.$store.commit('CREATE_ORGANIZATION', newOrga)
        await this.$store.dispatch('REFRESH_CURRENT_USER')
        // redirect to new orga
        this.$router.push(`/home/${newOrga._id}`)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.no-organization {
  display: flex;
  justify-content: center;
  height: 100%;
  background-image: url('./assets/organization@2x.png');
  background-position: 50% 50px;
  background-repeat: no-repeat;
  background-size: 682px 256px;

  h4 {
    font-size: 32px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    line-height: 40px;
    color: #3E4550;
    margin-bottom: 21px;
    margin-top: 295px !important;
  }
  .form-create-orga {
    label {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      color: #97a7b7;
      font-weight: normal;
      padding: 0;
    }
    .fpui-input-text {
      margin-top: -10px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    .fpui-button {
      width: 124px;
      margin-top: 44px;
    }
  }
}
</style>
