<template lang="pug">
  .receipts
    .fp-box
      .box-title {{ $t('accounts.receipts') }}
      fpui-table.fp-no-margin(
        v-if="tableData.data.length"
        :columns='tableData.columns'
        :actions='tableData.actions'
        :data='tableData.data'
      )
      .no-receipts(v-if='!tableData.data.length')
        img(src='@/core/assets/img/placeholder.png')
        .message {{ $t('accounts.receipts.table.no_data') }}
</template>
<script>
import Price from '@/shared/filters/Price'
import moment from 'moment'
import _sortBy from 'lodash/sortBy'
import Config from '@/shared/Config'

export default {
  props: {
    id: { type: String, default: () => { '' } },
    // type = account/organization
    type: { type: String, default: () => { '' } }
  },
  data () {
    return {
      receipts: []
    }
  },
  computed: {
    currencyCode () {
      return this.$store.getters.SESSION.currency
    },
    tableData () {
      const currencyCode = this.currencyCode
      return {
        data: _sortBy(this.receipts, 'date').reverse(),
        actions: [{
          id: 'download',
          icon: 'fp4 fp4-pdf',
          click: async (row) => {
            const config = await Config()
            let typeUrl = 'customer_accounts'
            if (this.type === 'organization') typeUrl = 'organizations'
            const url = `${config.KING}/v1/${typeUrl}/${this.id}/invoices/${row.id}`
            window.open(url)
          }
        }],
        columns: [{
          name: 'name',
          label: this.$t('accounts.receipts.table.name'),
          align: 'left',
          if () {
            return this.type === 'account'
          },
          target: 'organization_name',
          paddingLeft: 50
        }, {
        //   name: 'id',
        //   label: this.$t('accounts.receipts.table.id'),
        //   align: 'left',
        //   target: 'id',
        //   paddingLeft: 50
        // }, {
          name: 'date',
          label: this.$t('accounts.receipts.table.date'),
          align: 'left',
          target: (row) => {
            return moment(row.date * 1000).format(this.$t('date.formatter.short'))
          },
          paddingLeft: 50
        }, {
          name: 'paid',
          label: this.$t('accounts.receipts.table.paid_at'),
          align: 'left',
          target: (row) => {
            if (!row.paid_at) return this.$t('accounts.receipts.table.paid_at.not_paid')
            return moment(row.paid_at * 1000).format(this.$t('date.formatter.short'))
          },
          paddingLeft: 20
        }, {
          name: 'total',
          label: this.$t('accounts.receipts.table.total'),
          align: 'left',
          target (row) { return Price(row.total, currencyCode, 2) },
          paddingLeft: 20
        }]
      }
    }
  },
  async mounted () {
    await this.initReceipts()
  },
  methods: {
    async initReceipts () {
      try {
        if (this.type === 'account') {
          const account = this.$store.getters.ACCOUNT_BY_ID(this.id)
          this.receipts = await account.getInvoices()
        }
        if (this.type === 'organization') {
          const organization = this.$store.getters.ORGANIZATION_BY_ID(this.id)
          this.receipts = await organization.getInvoices()
        }
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    }
  }
}
</script>
<style lang="less">
  .receipts {
    .fp-box {
      padding-bottom: 30px;
      .fpui-table section.fpui-table_body > .fpui-table_row .fpui-table_row-actions {
        opacity: 1;
        .fp4-pdf {
          color: @red;
          padding-right: 20px;
        }
      }
      .no-receipts {
        text-align: center;
        color: #B2BECA;
        font-size: 12px;
        font-weight: 600;
        padding-bottom: 18px;
        user-select: none;
        img {
          width: 99px;
          padding-bottom: 10px;
        }
      }
    }
  }
</style>
