<template lang="pug">
  .instance-informations
    .header(v-if="!isEditing")
      .title {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}
        img(
          :src="require(`@/core/assets/img/engines/${item.engine}@2x.png`)"
          :class="item.engine"
        )
        span.engine {{ $t(`organization.data_storage_engine.${item.engine}`) }}
    .content
      .row
        fpui-input-text(
          :label="$t('organization.data_storage_engine.modal.display_name')"
          :placeholder="$t('organization.data_storage_engine.modal.display_name')"
          :value="item.display_name"
          @input="(value) => item.update('display_name', value, false)"
        )
      .row
        fpui-input-text(
          :label="$t('organization.data_storage_engine.modal.description')"
          :placeholder="$t('organization.data_storage_engine.modal.description')"
          :value="item.description"
          @input="(value) => item.update('description', value, false)"
        )
      .row
        fpui-input-tags(
          :label="$t('organization.data_storage_engine.modal.tags')"
          :placeholder="$t('organization.data_storage_engine.modal.tags')"
          :value="item.tags"
          @input="item.update('tags', $event, false)"
        )
      .row(v-if="!isEditing")
        label.default-label {{ $t('organization.data_storage_engine.modal.use_as_default') }}
      .instance-informations-checkbox-container(v-if="!isEditing")
        .row
          fpui-input-checkbox(
            :value="configuration.data_prim"
            @input="(value) => $emit('updateConfiguration', 'data_prim', value)"
            :disabled="!$acl(organizationId).can('core', 'storage_config', 'update')"
          ) {{ $t('organization.data_storage_engine.modal.primary') }}
        .row
          fpui-input-checkbox(
            :value="configuration.data_mart"
            @input="(value) => $emit('updateConfiguration', 'data_mart', value)"
            :disabled="!$acl(organizationId).can('core', 'storage_config', 'update')"
          ) {{ $t('organization.data_storage_engine.modal.mart') }}
        .row
          fpui-input-checkbox(
            :value="configuration.data_ml"
            @input="(value) => $emit('updateConfiguration', 'data_ml', value)"
            :disabled="!$acl(organizationId).can('core', 'storage_config', 'update')"
          ) {{ $t('organization.data_storage_engine.modal.ml') }}
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => ({}) },
    configuration: { type: Object, default: () => ({}) },
    title: { type: String, default: () => '' },
    subtitle: { type: String, default: () => '' },
    isEditing: { type: Boolean, default: false },
    organizationId: { type: String, required: true }
  }
}
</script>

<style lang="less">
.instance-informations {
  display        : flex;
  flex-direction : column;
  flex-wrap      : nowrap;
  justify-content: center;
  align-content  : center;
  align-items    : center;
  margin         : 0;

  .subtitle {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
      margin-left: 5px;

      &.snowflake {
        width: 15px;
        margin-left: 7px;
      }
    }

    .engine {
      font-weight: 600;
      margin-left: 5px;
    }
  }

  > .content {
    border          : 0.5px solid rgba(151, 167, 183, 0.21);
    border-radius   : 5px;
    width           : 470px;
    min-height      : auto;
    padding         : 25px 30px 5px 30px;
    background-color: white;
    margin          : 30px 0 47px 0;

    &.no-padding {
      padding       : 0px 24px 0px 26px;
    }

    .row {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::before, &::after {
        display: none;
      }

      .fpui-input-text, .fpui-input-tags {
        width: 100%;
      }

      .fpui-input-tags {
        .vue-tags-input {
          height: 37px;
          overflow-y: auto;
        }
      }

      .default-label {
        color: #97A7B7;
        font-size: 12px;
        font-weight: normal;
        padding-bottom: 0px;
        margin-bottom: 5px;
      }
    }

    .instance-informations-checkbox-container {
      display: flex;
      flex-wrap: wrap;
      .row {
        width: 50%;
      }
    }
  }
}
</style>
