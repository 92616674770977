<template lang="pug">
  .dataplant-creation__provider-selection__personalize
    .dp-personalize-container(:class="{ 'show-content': options.configuration.dataplant.provider }")
      .provider-location-informations
        .provider-logo
          img(v-if="options.configuration.dataplant.provider" :src="providerImage")
        .provider-name {{ providerName }}
        .provider-region {{ regionName }}
        .provider-region-selection(v-if='this.options.configuration.dataplant.provider !== "automatic"')
          fpui-input-select(
            v-model='region',
            name='id',
            :options='regions.map( r => ({ label: r.id, value: r }))'
          )

      .provider-personalize
        .form(v-if="canUseCurrentProvider")
          .row
            .col-xs-12
              fpui-input-text(
                v-model="dataplantName"
                :label="$t('dataplant.creation.dataplant_details.form.name.label')"
                :placeholder="$t('dataplant.creation.dataplant_details.form.name.placeholder')"
              )
          .row
            .col-xs-12
              fpui-input-text(
                v-model="dataplantDescription"
                :label="$t('dataplant.creation.dataplant_details.form.description.label')"
                :placeholder="$t('dataplant.creation.dataplant_details.form.description.placeholder')"
              )
          .row
            .col-xs-12
              fpui-input-tags(
                v-model="dataplantTags"
                :label="$t('dataplant.creation.dataplant_details.form.tags.label')"
                :placeholder="$t('dataplant.creation.dataplant_details.form.tags.placeholder')"
              )
          .row.domain-row
            .col-xs-6.subdomain
              img.loading(
                src='@/core/assets/img/ic_spinner.png'
                :class="{ 'is-loading': checkingDomain }"
              )
              fpui-input-text(
                v-model="dataplantSubdomain"
                :label="$t('dataplant.creation.dataplant_details.form.subdomain.label')"
                :placeholder="$t('dataplant.creation.dataplant_details.form.subdomain.placeholder')"
                :error="!!errorDomain"
              )
            .col-xs-6.domain
              fpui-input-text(
                v-if="availableDomains.length < 2"
                v-model="dataplantDomain"
                :label="$t('dataplant.creation.dataplant_details.form.domain.label')"
                disabled
              )
              fpui-input-select(
                v-else
                v-model='dataplantDomain'
                name='domain'
                direction='top'
                :options='availableDomains.map(ad => ({ label: ad.domain, value: ad.domain }))'
                :clearable='false'
                :label="$t('dataplant.creation.dataplant_details.form.domain.label')"
              )
            .col-xs-12.error-placeholder(:class="{ error: errorDomain }")
              span {{ errorDomain }}
        .not-available(v-else)
          h3 {{ $t(`dataplant.creation.details.provider_not_available.title.${isFreeTrial ? 'free_trial' : 'upgrade' }`) }}
          span.description {{ $t(`dataplant.creation.details.provider_not_available.description.${isFreeTrial ? 'free_trial' : 'upgrade' }`) }}
          fpui-button(
            color="blue-flash"
            :auto-width='true'
            @click="upgradePlan()"
          ) {{ $t('onboarding.blocks.scale.cta') }}
    .provider-not-selected(v-show="!options.configuration.dataplant.provider") {{ $t('dataplant.creation.provider.empty_provider_subtitle') }}

</template>

<script>
import _find from 'lodash/find'
import _debounce from 'lodash/debounce'
import SubdomainName from '@/shared/filters/SubdomainName'
import SubscriptionNew from '@/core/components/Subscription/New.vue'

export default {
  props: {
    options: { type: Object, required: true },
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      checkingDomain: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    },
    availableDomains () {
      const authorizedDomains = this.organization.authorized_domain || []
      return authorizedDomains.filter(ad => ad.forepaas)
    },
    region: {
      get () {
        return this.options.configuration.dataplant.region
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.region': value })
      }
    },
    regionName () {
      return this.region?.regionName || ''
    },
    dataplantName: {
      get () {
        return this.options.configuration.dataplant.name
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.name': value })
        this.$emit('update', { 'configuration.dataplant.subdomain': SubdomainName(value) })
        this.checkDomain()
      }
    },
    dataplantDescription: {
      get () {
        return this.options.configuration.dataplant.description
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.description': value })
      }
    },
    dataplantTags: {
      get () {
        return this.options.configuration.dataplant.tags
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.tags': value })
      }
    },
    dataplantSubdomain: {
      get () {
        return this.options.configuration.dataplant.subdomain
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.subdomain': value })
        this.checkDomain()
      }
    },
    dataplantDomain: {
      get () {
        return this.options.configuration.dataplant.domain
      },
      set (value) {
        this.$emit('update', { 'configuration.dataplant.domain': value })
      }
    },
    errorDomain () {
      if (!this.options.configuration.dataplant.subdomain) return false
      if (this.options.configuration.dataplant.subdomain.length < 3) return this.$t('dataplant.creation.details.subdomain.error_length')
      if (!this.options.domainAvailable && !this.checkingDomain) return this.$t('dataplant.creation.details.subdomain.error_exists')
      return false
    },
    providerName () {
      if (!this.options.configuration.dataplant.provider) return null
      return _find(this.options.providers, { name: this.options.configuration.dataplant.provider })?.display_options?.display_name
    },
    providerImage () {
      try {
        return require(`@/core/assets/img/providers/${this.options.configuration.dataplant.provider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    },
    regions () {
      const regions = this.options.providers.filter((p) => {
        return p.name === this.options.configuration.dataplant.provider
      }).map((p) => {
        return p.regions.map((r) => {
          return {
            id: r.name,
            provider: r.provider,
            providerName: p.display_options?.display_name,
            regionName: r.display_options.display_name
          }
        })
      })
      return regions.reduce((a, b) => a.concat(b), [])
    },
    isFreeTrial () {
      return this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled'
    },
    isStandardPlan () {
      return this.subscription?.plan === 'standard'
    },
    canUseCurrentProvider () {
      const provider = this.options.configuration.dataplant.provider
      if (provider !== 'automatic' && (this.isFreeTrial || this.isStandardPlan)) return false
      return true
    }
  },
  mounted () {
    if (!this.options.configuration.dataplant.domain && this.availableDomains.length) this.dataplantDomain = this.availableDomains[0].domain
  },
  methods: {
    checkDomain: _debounce(async function () {
      this.checkingDomain = true
      if (this.options.configuration.dataplant.subdomain.length < 3) {
        this.checkingDomain = false
        return this.$emit('update', { domainAvailable: false })
      }
      const checkDomain = await this.$api.FPAPI.checkDomainAvailability(this.dataplantSubdomain.toLowerCase(), this.dataplantDomain, this.organizationId)
      if (checkDomain.state === 404) this.$emit('update', { domainAvailable: true })
      else this.$emit('update', { domainAvailable: false })
      this.checkingDomain = false
    }, 300),
    upgradePlan () {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: true,
        refreshAfterComplete: true
      }, {
        height: 'auto',
        width: 900
      })
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__provider-selection__personalize {
    position: absolute;
    bottom: 39px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 736px;
    z-index: 1;
    .dp-personalize-container {
      background-color: #FFF;
      border-radius: 5px;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.17);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      padding: 15px 0 17px 0;
      z-index: 2;
      opacity: 0;
      transition: opacity 100ms ease-out;
      &.show-content {
        opacity: 1;
      }
      > .provider-location-informations {
        position: relative;
        width: 263px;
        flex: 0 0 263px;
        padding: 0 33px;
        text-align: right;
        &:after {
          content: "";
          position: absolute;
          width: 1px;
          margin-top: 17px;
          top: 0;
          bottom: 0;
          right: 0;
          background-color: #E9ECF0;
        }
        > .provider-logo {
          user-select: none;
          height: 73px;
          img {
            height: 70px;
          }
        }
        > .provider-name {
          color: #3E4550;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 23px;
          min-height: 23px;
        }
        > .provider-region {
          color: #B2BECA;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 17px;
          height: 18px;
        }
        > .provider-region-selection {
          user-select: none;
          margin-top: 5px;
          .fpui-input-select-container {
            .content {
              .default {
                .fpui-input-select {
                  background-color: rgba(151, 167, 183, 0.06);
                  font-size: 13px;
                  font-style: italic;
                  color: #B2BECA;
                  height: 23px;
                  padding: 2px 25px 4px 10px;
                  justify-content: flex-end;
                  display: inline-flex;
                  span {
                    padding-right: 5px;
                  }
                  .fp4-angle-down {
                    font-size: 13px;
                    top: 5px;
                  }
                }
                .fp4-circle-xmark {
                  font-size: 13px;
                  top: 5px;
                }
              }
            }
          }
        }
      }
      > .provider-personalize {
        width: 100%;
        > .not-available {
          background-image: url("~@/core/assets/img/rocket_create_dataplant_unable@2x.png");
          background-repeat: no-repeat;
          background-size: contain;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;
          h3 {
            margin-top: 120px;
            margin-bottom: 8px;
            color: #3E4550;
            font-size: 20px;
            font-weight: 600;
          }
          span.description {
            font-size: 14px;
            color: #3E4550;
            margin-bottom: 26px;
          }
        }
        .row {
          &:not(:first-child) {
            margin-top: 10px;
          }
          &.domain-row {
            img.loading {
              animation: rotating 1s infinite;
              -webkit-animation: rotating 1s infinite;
              width: 20px;
              position: absolute;
              right: 22px;
              bottom: 8px;
              opacity: 0;
              transition: opacity 20ms linear;
              &.is-loading {
                opacity: 1;
              }
            }
            .error-placeholder {
              display: flex;
              padding: 0 0 0 15px;
              margin: 0;
              height: 0;
              opacity: 0;
              transition: opacity 100ms ease-out, padding-top 500ms ease-out, height 500ms ease-out;
              span {
                color: #c93256;
              }
              &.error {
                opacity: 1;
                padding-top: 5px;
                height: auto;
              }
            }
          }
        }
      }
    }
    .provider-not-selected {
      color: rgba(255,255,255,0.5);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
</style>
