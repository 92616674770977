<template lang="pug">
.dataplant-dashboard-block(:class="cls")
  fp-loader(v-if="loading || blockLoading")
  a.pointer(:href="link" @click="onClick")
    .content
      .inner-content
        img.new-feature(v-if="isNewFeature" :src="newFeatureIcon")
        .error-container(v-if="error")
          .title-container
            i.fp4.fp4-circle-xmark
            .text {{ $t('dashboard.error.service_unavailable') }}
          .error-tooltip-container
            .desc {{ $t('dashboard.error.service_unavailable.desc') }}
            .stack-container
              .stack {{ error.stack }}
        .hover
        .name {{ $t(name) }}
        img.icon(:src="require(`./assets/${service}.svg`)",v-if="hasAccess")
        img.icon(:src="require(`./assets/${service}_dark.svg`)",v-else)
        i.restricted.fp4.fp4-lock-keyhole(v-tooltip="$t('dashboard.access.tooltip')",v-if="!loading&&!hasAccess&&!error")

      slot.slot
  a.try-beta.pointer(
    v-if="!!beta"
    :href="betaLink"
    :style="{ cursor: 'pointer !important' }"
    @click.stop="tryBeta"
  )
    i.fp4.fp4-stars
    .text(v-html="betaText")
    i.fp4.fp4-stars
</template>

<script>
export default {
  props: {
    error: {
      type: Error,
      default: null
    },
    hasAccess: {
      type: Boolean,
      required: true
    },
    isNewFeature: {
      type: Boolean,
      default: false
    },
    beta: {
      type: String,
      required: false,
      default: null
    },
    betaText: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    },
    service: {
      type: String,
      required: true
    },
    dataplantId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'bulk'
    },
    line: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      blockLoading: false,
      newFeatureIcon: require('./NEW_STATIC_CORNER.svg')
    }
  },
  computed: {
    cls () {
      const cls = []
      if (this.line) {
        cls.push('line')
        cls.push(`line-${this.line}`)
      }
      if (!this.hasAccess) cls.push('locked')
      if (this.loading) cls.push('loading')
      return cls.join(' ')
    },
    dataplant () {
      return this.$store.getters.DATAPLANT_BY_ID(this.dataplantId)
    },
    link () {
      if (!this.hasAccess) return 'javascript:void(0)'
      if (this.service === 'cc') {
        return `/cc/#/${this.dataplant.organization_id}/${this.dataplantId}`
      }
      if (this.mode === 'bulk') {
        return `/${this.service}/#/${this.dataplantId}`
      } else if (this.mode === 'redirect') {
        return `https://${this.dataplant?.url_proxy}/${this.service}/hq/`
      } else if (this.mode === 'iframe') {
        return `#/${this.service}/${this.dataplantId}`
      } else if (this.mode === 'internal') {
        return `#/${this.service}/${this.dataplantId}`
      }
      return 'javascript:void(0)'
    },
    betaLink () {
      if (!this.beta) return 'javascript:void(0)'
      return `/${this.beta}/#/${this.dataplantId}`
    },
    currentUser () {
      return this.$store.getters.SESSION
    }
  },
  methods: {
    async onClick (event) {
      try {
        event.preventDefault()
        await this.sendTrack()
        window.open(this.link, '_self')
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.pop('error', err)
        this.blockLoading = false
      }
    },
    async tryBeta (event) {
      try {
        event.preventDefault()
        await this.sendTrack()
        window.open(this.betaLink, '_self')
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.pop('error', err)
        this.blockLoading = false
      }
    },
    async sendTrack () {
      if (this.service === 'dpe') {
        this.$analytics.track('Open DPE Component', {
          from: 'home'
        })
        this.currentUser.update('onboarding.access.dpe', true, false)
      }
      if (this.service === 'dwh' || this.service === 'dm') {
        this.$analytics.track('Open Data Manager Component', {
          from: 'home'
        })
        this.currentUser.update('onboarding.access.dm', true, false)
      }
      if (this.service === 'qb' || this.service === 'am') {
        this.$analytics.track('Open QB Component', {
          from: 'home'
        })
        this.currentUser.update('onboarding.access.qb', true, false)
      }
      if (this.service === 'cc') this.$analytics.track('Open CC Component', { from: 'home' })
      if (this.service === 'iam') this.$analytics.track('Open IAM Component', { from: 'home' })
      if (this.service === 'ml') {
        this.$analytics.track('Open MLM Component', {
          from: 'home'
        })
        this.currentUser.update('onboarding.access.ml', true, false)
      }
      if (this.service === 'api') {
        this.currentUser.update('onboarding.access.api', true, false)
      }
      if (this.service === 'app') {
        this.currentUser.update('onboarding.access.app', true, false)
      }
      if (this.currentUser.hasChanges) {
        this.blockLoading = true
        await this.currentUser.save()
        this.blockLoading = false
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-dashboard-block {
    height: 100%;
    position: relative;
    padding: 6px;
    .fp-loader-container {
      left: 5px;
      top: 5px;
      right: 5px;
      bottom: 5px;
    }
    &.app, &.api {
      a.pointer {
        cursor: inherit;
      }
      i.pointer {
        cursor: pointer;
      }
    }
    &.locked {
      .pointer {
        cursor: not-allowed!important;
      }
    }
    &.loading {
      .pointer {
        cursor: progress;
      }
    }
    &.line {
      .inner-content {
        &:before {
          transition: all 300ms;
          height: 2px;
          z-index: 2;
          background:#0089c0;
          width: 100%;
          content:"";
          position: absolute;
          top: 166px;
          left:0;
        }
      }
      &.line-first {
        .inner-content {
          &:after {
            transition: all 300ms;
            position: absolute;
            content: '';
            top: 160px;
            left: 25px;
            background:white;
            z-index: 3;
            margin: auto;
            height: 20px;
            border: 3px solid;
            width: 15px;
            border-color: #0089c0;
            height: 15px;
            background-color: #fff;
            border-radius: 15px;
          }
          &:before {
            left:25px;
            width: calc(100% - 25px);
          }
        }
      }
      &.line-last {
        .inner-content {
          &:after {
            transition: all 300ms;
            position: absolute;
            content: '';
            top: 160px;
            right: 25px;
            background:white;
            z-index: 3;
            margin: auto;
            height: 20px;
            border: 3px solid;
            width: 15px;
            border-color: #0089c0;
            height: 15px;
            background-color: #fff;
            border-radius: 15px;
          }
          &:before {
            right:25px;
            width: calc(100% - 25px);
          }
        }
      }
    }
    &.locked.line {
      .inner-content:before {
        background: #3E4550;
      }
      .inner-content:after {
        border-color: #3E4550;
      }
    }
    .content {
      position: relative;
      height: 100%;
      background: white;
      box-shadow: 0 3px 13px 0 rgb(151 167 183 / 30%);
    }
    .inner-content {
      overflow: hidden;
      text-align: center;
      height: 100%;
      position: relative;
      .hover {
        top:100%;
        z-index: 1;
        transition: all 300ms;
        height: 100%;
        left:0;
        right:0;
        position: absolute;
        background: #E6F8FD;
      }
      .new-feature {
        position: absolute;
        left: -26px !important;
        top: 0;
        height: 51px;
        z-index: 2;
      }
      img.icon {
        width: 110px;
        position: absolute;
        z-index:3;
        top: 100px;
        transition: all 300ms;
        left: 50%;
        transform: translateX(-50%);
      }
      &:hover {
        .hover {
          top: 0;
        }
        &:before {
          top: 100px;
        }
        &:after {
          top: 95px!important;
        }
        img.icon {
          width: 70px;
          top: 60px;
        }
      }
      i.restricted {
        position: absolute;
        z-index: 100;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 2;
        color: #3E4550;
        cursor: pointer;
        font-size: 24px;
      }
      .error-container {
        transition: all 300ms;
        position: absolute;
        z-index: 100!important;
        width: 100%;
        height: 20px;
        .title-container {
          width: 100%;
          height: 30px;
          color: @red;
          font-size: 22px;
          display: flex;
          align-items: center;
          background-color: #fdddeb;
          cursor: pointer;
          .fp4-circle-xmark {
            margin-left: 10px;
          }
          .text {
            font-size: 13px;
            font-weight: 600;
            margin-left: 10px;
          }
        }
        .error-tooltip-container {
          transition: all 300ms;
          height: 0;
          width: 100%;
          background-color: #fdddeb;
          font-size: 13px;
          .desc {
            transition: all 300ms;
            opacity: 0;
            margin-left: 43px;
            margin-right: 30px;
            color: @text-color;
            font-weight: 600;
            text-align: left;
          }
          .stack-container {
            transition: all 300ms;
            height: 0;
            margin: 10px 30px 5px 43px;
            background:#3e434f;
            color: white;
            border-radius: 5px;
            overflow-y: auto;
            ::-webkit-scrollbar {
              display: none;
            }
            .stack {
              font-family: Roboto;
              padding: 20px 15px;
              text-align: left;
              white-space: pre-wrap;
            }
          }
        }
        &:hover{
          height: 100%;
          .error-tooltip-container{
            user-select: text;
            cursor: initial;
            height: 100%;
            .desc {
              opacity: 1;
            }
            .stack-container {
              height: 85%;
            }
          }
        }
      }

      .name {
        text-align: left;
        display: inline-block;
        font-size: 13px;
        z-index: 2;
        color: #3E4550;
        position: relative;
        font-weight: 600;
        text-transform: uppercase;
        font-family: 'Source Sans Pro', sans-serif;
        letter-spacing: 1.5px;
        max-width: 190px;
      }
    }
    &.qb,&.dm,&.dpe,&.api,&.app,&.am,&.ml {
      .name {
        text-align: center;
        padding-top: 41px;
      }
    }
    &.ml {
      .inner-content img.icon {
        padding-top: 20px;
        width: 90px;
      }

      .inner-content:hover img.icon {
        width: 60px;
      }
    }
    &.api,&.app {
      .inner-content {
        height: 100%;
      }
    }
    &.iam,&.infra,&.cc {
      .name {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%,-50%);
      }
      img.icon {
        top:40%!important;
        transform: translateY(-50%);
        left: 65%;
      }
    }

    .try-beta {
      position: absolute;
      bottom: 6px;
      width: calc(~"100% - 12px");
      height: 46px;
      background: linear-gradient(268.29deg, #00CCF9 0%, #035599 100%);
      border-radius: 8px 8px 0px 0px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      .text {
        font-size: 12px;
        line-height: 15px;
        color: #fff;
        text-align: center;
      }
      i {
        font-size: 26px;

        &:first-child {
          color: #00CCF9;
        }
        &:last-child {
          color: #035599;
        }
      }
    }
  }
</style>
