<template lang='pug'>
.fp-header(v-if="$store.getters.READY('core_organizations')")
  .left(v-if="!displayFromDataplant")
    .logo.logo-organization
      a.home-link(:href="homeLink")
        img.forepaas-logo(src="@/core/assets/img/logo.png")
    .logo.logo-dataplant(
      v-if="fromDataplant"
    )
      a.dataplant-link(:href="homeDataplantLink")
        i.fp4.fp4-dataplants
    .dataplant-selector-name-container
      a.organization-name(
        v-if="fromDataplant"
        :href="`#/home/${organization._id}`"
      ) {{ organization.name }}
      .dataplant-selector-container(
        :class="{ 'from-organization': !fromDataplant }"
      )
        dataplant-selector(
          v-if="organization && displayDataplantSelector"
          :from-organization="fromOrganization"
          :from-dataplant="fromDataplant"
          :from-support="fromSupport"
        )

      .profile-title(v-if="fromProfile")
        .name-container
          span.name {{ $t('header.title_profile') }}
      .account-title(v-if="fromAccount")
        .name-container
          span.name {{ $t('header.title_accounts') }}

  //- Infra Monitoring and App service and old QB are from Dataplant
  .left(v-if="displayFromDataplant")
    .logo.logo-organization
      a.home-link(:href="homeLink")
        img.forepaas-logo(src="@/shared/assets/img/logo.png")
    .logo.logo-dataplant
      a.dataplant-link(:href="homeDataplantLink")
        i.fp4.fp4-dataplants
    .logo.logo-service
      .service-link
        img(
          v-if="fromInfra"
          :src="require('@/core/components/Dataplant/assets/infra_dark_no_background.svg')"
        )
        img(
          v-if="fromApi"
          :src="require('@/core/components/Dataplant/assets/api_dark_no_background.svg')"
        )
        img(
          v-if="fromApp"
          :src="require('@/core/components/Dataplant/assets/app_dark_no_background.svg')"
        )
        img.am(
          v-if="fromOldQB"
          :src="require('@/core/components/Dataplant/assets/am_dark_no_background.svg')"
        )
    .service-name-container
      .organization-dataplant-name-container
        a.organization-name(
          :href="homeLink"
        ) {{ organization.name }}
        span {{ ' | ' }}
        a.dataplant-name(
          :href="homeDataplantLink"
        ) {{ dataplantName }}
      .service-selector-container
        service-selector(
          :service="getService"
        )

  .right
    .actions
      button.button.button-lang
        .current {{ lang }}
        ul.dropdown
          li.lang(:class="{active:isActiveLang(l)}" @click="setLang(l)" v-for="l in locales") {{ l | translate }}
      button.button.button-profile
        i.fp4.fp4-gear
        ul.dropdown
          li.organization.organization-name(v-if="joinableOrganization")
            a.no-flex(:href="`#/organization/${joinableOrganization._id}`")
              .organization-container
                .organization-name {{ organization.name }}
                .dataplants-length(v-if="dataplantsByOrganizationId && dataplantsByOrganizationId.length") {{ dataplantsByOrganizationId.length }} {{ `Dataplant${dataplantsByOrganizationId.length > 1 ? 's' : ''}` }}
                .dataplants-length(v-else) {{ $t('header.your_current_organization') }}
              .flex
                span {{ $t('header.my_organization') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`#/organization/${joinableOrganization._id}/members`")
              span {{ $t('header.my_organization_members') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`#/organization/${joinableOrganization._id}/member`")
              i.fp4.fp4-plus-grid-2
              span {{ $t('header.my_organization_member') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`#/organization/${joinableOrganization._id}/plan`")
              span {{ $t('header.my_organization_billing') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`cc/#/${joinableOrganization._id}`")
              span {{ $t('header.control_center') }}
      button.button.button-help
        i.fp4.fp4-circle-info-small
        ul.dropdown
          li.docs
            a(:href="links.docs" target="blank" v-if="links.docs")
              i.fp4.fp4-book
              span {{ $t('header.docs') }}
          li.status
            a(:href="links.status" target="blank" v-if="links.status")
              i.fp4.fp4-heart-pulse
              span {{ $t('header.status') }}
          li.changelogs
            a(href="https://docs.forepaas.io/#/en/changelog" target="blank")
              i.fp4.fp4-arrows-rotate
              span {{ $t('header.changelog') }}
          li.roadmap
            a(href="#/features" target="blank")
              i.fp4.fp4-rocket
              span {{ $t('header.roadmap') }}
          li.support-link(v-if="joinableOrganization")
            a(:href="`#/support/${joinableOrganization._id}`" target="blank")
              i.fp4.fp4-life-ring
              span {{ $t('header.support') }}
      button.button.button-user
        img.profile-picture(:src="picture")
        ul.dropdown
          li.profile-container
            a(:href="'#/user'")
              .profile-info(v-if="localProfile")
                img.profile-picture(:src="picture")
                span {{ localProfile.given_name }} {{ localProfile.family_name }}
              .profile-info(v-else)
                i.fp4.fp4-circle-user
                span {{ $t('header.my_profile') }}
          li.account
            a(href="#/accounts")
              span {{ $t('header.my_account') }}
          li.logout
            span(@click="logout") {{ $t('header.logout') }}
</template>

<script>
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import Config from '@/shared/Config'
import ServiceSelector from '@/shared/components/app/ServiceSelector.vue'
import DataplantSelector from './Dataplant/Selector.vue'
import SubscriptionStatus from './Subscription/Status.vue'

export default {
  components: {
    ServiceSelector,
    DataplantSelector,
    SubscriptionStatus
  },
  props: {},
  data () {
    return {
      config: null,
      pageWithoutLogo: [
        { name: 'organization', title: 'header.title_organization', icon: 'fp4-organization-header-circle' },
        { name: 'changelog', title: 'header.title_changelog', icon: 'fp4-list' },
        { name: 'user', title: 'header.title_profile', icon: 'fp4-user-circles' },
        { name: 'accounts', title: 'header.title_accounts', icon: 'fp4-money-card-circle' },
        { name: 'support', title: 'header.title_support', icon: 'fp4-life-ring' },
        { name: 'infra', title: 'header.title_infra', image: require('@/core/components/Dataplant/assets/infra_dark_no_background.svg') },
        { name: 'qb', title: 'header.title_qb', image: require('@/core/components/Dataplant/assets/qb_dark_no_background.svg') },
        { name: 'app', title: 'header.title_app', image: require('@/core/components/Dataplant/assets/app_dark_no_background.svg') },
        { name: 'api', title: 'header.title_api', image: require('@/core/components/Dataplant/assets/api_dark_no_background.svg') }
      ],
      localProfile: null,
      picture: require('@/core/assets/img/default_avatar.png')
    }
  },
  computed: {
    user () {
      return this.$store.getters.SESSION
    },
    homeLink () {
      return this.organization ? `#/home/${this.organization._id}` : '#/home'
    },
    homeDataplantLink () {
      return this.dataplant ? `#/dataplant/${this.dataplant.id}` : ''
    },
    locales () { return this.$i18n.locales() },
    lang () { return this.$i18n.locale().toUpperCase() },
    links () {
      return {
        docs: this.config?.DOCS,
        status: this.config?.STATUS
      }
    },
    dataplant () {
      if (this.$route.params.dataplantId) return this.$store.getters.DATAPLANT_BY_ID(this.$route.params.dataplantId)
      return null
    },
    dataplantName () {
      return this.dataplant?.name
    },
    organization () {
      return this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params)
    },
    dataplantsByOrganizationId () {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organization._id) || []
    },
    joinableOrganization () {
      return this.requestToJoinPending ? this.$store.getters.ORGANIZATIONS.find(orga => !orga.pending_request) : this.organization
    },
    routeDetails () {
      return this.$route.path.split('/')
    },
    requestToJoinPending () {
      return this.organization?.pending_request
    },
    pageWithoutLogoDetails () {
      if (this.routeDetails.length < 2) return null
      return this.pageWithoutLogo.find(p => p.name === this.routeDetails[1])
    },
    displayDataplantSelector () {
      return !this.pageWithoutLogoDetails || this.fromOrganization || this.fromSupport
    },
    fromOrganization () {
      return this.pageWithoutLogoDetails?.name === 'organization'
    },
    fromDataplant () {
      return !!this.$route.params.dataplantId
    },
    fromSupport () {
      return this.pageWithoutLogoDetails?.name === 'support'
    },
    fromInfra () {
      return this.pageWithoutLogoDetails?.name === 'infra'
    },
    fromApi () {
      return this.pageWithoutLogoDetails?.name === 'api'
    },
    fromApp () {
      return this.pageWithoutLogoDetails?.name === 'app'
    },
    fromOldQB () {
      return this.pageWithoutLogoDetails?.name === 'qb'
    },
    fromProfile () {
      return this.pageWithoutLogoDetails?.name === 'user'
    },
    fromAccount () {
      return this.pageWithoutLogoDetails?.name === 'accounts'
    },
    displayFromDataplant () {
      return this.fromInfra || this.fromApi || this.fromApp || this.fromOldQB
    },
    getService () {
      if (!this.displayFromDataplant) return null
      return this.pageWithoutLogoDetails?.name
    }
  },
  created () {
    this.localProfile = _cloneDeep(this.user.profile.find(p => p.main) || this.user.profile[0])
    if (this.localProfile.picture) this.picture = this.localProfile.picture
  },
  async mounted () {
    moment.locale(this.lang)
    this.config = await Config()
  },
  methods: {
    setLang (lang) {
      this.$i18n.set(lang)
      moment.locale(lang)
      window.localStorage.setItem('hq_lang', lang)
    },
    isActiveLang (lang) {
      return lang === this.lang.toLowerCase()
    },
    logout () {
      this.$store.dispatch('LOGOUT_CMP')
    },
    redirectHome () {
      let path = '/home/'
      if (this.organization) path += this.organization?._id
      if (this.routeDetails.length >= 2 && ['infra', 'qb', 'app', 'api'].includes(this.routeDetails[1])) path = `/dataplant/${this.routeDetails[2]}`
      this.$router.push(path)
    }
  }
}
</script>

<style lang='less'>
// Style in src/shared/styles/header.less
</style>
