<template lang="pug">
  .profile
    fp-loader(v-if="loading")
    .profile-header
      fp-title(:parent="userName")
      fpui-button(
        color='blue-flash'
        @click="save"
        :disabled="!user.hasChanges"
        auto-width
      ) {{ $t('button.save') }}
    fpui-tabs
      fpui-tab-if(:header="$t('profile.tab.preferences')")
        preferences(
          :key="loading"
          :user="user"
        )
      fpui-tab-if(:header="$t('profile.tab.security')")
        security(:user="user")
</template>

<script>
import Preferences from './Preferences'
import Security from './Security'

export default {
  components: {
    Preferences,
    Security
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    userName () {
      const mainUser = this.user.profile.find(p => p.main) || this.user.profile[0]
      return `${mainUser.given_name} ${mainUser.family_name}`
    },
    user () {
      return this.$store.getters.SESSION
    }
  },
  methods: {
    async save () {
      this.loading = true
      await this.user.save()
      // Reload organization
      await this.$store.dispatch('LOAD_ORGANIZATIONS')
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.profile {
  margin-left: 84px;
  margin-right: 83px;
  box-shadow: 0 3px 13px 0 rgb(151 167 183 / 30%);
  background-color: white;
  height: calc(~"100% - 44px");
  overflow-y: hidden;
  overflow-x: hidden;
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-container {
      padding-left: 60px;
    }
    .fpui-button {
      margin-right: 30px;
    }
  }

  .fpui-tabs {
    height: 100%;

    .fpui-tabs-contents {
      overflow: auto;
      height: calc(~"100% - 116px");
    }
  }
}
</style>
