<template lang="pug">
  .dataplants
    fp-loader(v-if="loading")
    div(v-else)
      waiting-for-approval(
        v-if="requestToJoinPending"
        :organization-id="organizationId"
      )
      no-subscription(
        v-if="displayLegacyNoSubscription && !requestToJoinPending"
        :organization-id="organizationId"
      )
      .dataplants-list(v-if="dataplants.length")
        dataplant-block(
          v-for="dataplant in dataplants"
          :key="dataplant.dataplant_id"
          :subscription="subscription"
          :dataplant-id="dataplant.dataplant_id"
          :organization="organization"
        )
        dataplant-block-subscription-paused-cancelled(
          v-if="subscriptionStatus === 'paused' || subscriptionStatus === 'cancelled'"
          :organization-id="organizationId"
        )
        dataplant-block-subscription-non-renewing(
          v-if="subscriptionStatus === 'non_renewing'"
          :organization-id="organizationId"
        )
        dataplant-block-new(
          v-if="subscriptionStatus === 'active' || subscriptionStatus === 'non_renewing' || subscriptionStatus === 'future' || subscriptionStatus === 'in_trial'"
          :organizationId="organizationId"
        )

      onboarding(
        v-if="displayOnBoarding"
        :organization-id="organizationId"
      )

</template>

<script>
import DataplantBlock from '@/core/components/Dataplant/HomeBlock/Dataplant'
import DataplantBlockNew from '@/core/components/Dataplant/HomeBlock/NewDataplant'
import DataplantBlockSubscriptionPausedCancelled from '@/core/components/Dataplant/HomeBlock/SubscriptionPausedCancelled'
import DataplantBlockSubscriptionNonRenewing from '@/core/components/Dataplant/HomeBlock/SubscriptionNonRenewing'
import Onboarding from './Onboarding.vue'
import NoSubscription from './NoSubscription.vue'
import WaitingForApproval from './WaitingForApproval.vue'

export default {
  components: {
    DataplantBlock,
    DataplantBlockNew,
    DataplantBlockSubscriptionNonRenewing,
    DataplantBlockSubscriptionPausedCancelled,
    Onboarding,
    NoSubscription,
    WaitingForApproval
  },
  props: {
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    },
    dataplants () {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    userOrganizationsHasSubscriptionsNotInTrial () {
      return this.$store.getters.ALL_ORGANIZATIONS_SUBSCRIPTIONS.filter(sub => sub.status !== 'in_trial').length
    },
    displayLegacyNoSubscription () {
      if (!this.userOrganizationsHasSubscriptionsNotInTrial && !this.subscription && !this.organization) return true

      return !this.subscription && !this.dataplants.length && !this.displayOnBoarding
    },
    displayOnBoarding () {
      const { steps_displayed, trials_left } = this.currentUser.onboarding
      if (!trials_left && !this.subscription && !this.userOrganizationsHasSubscriptionsNotInTrial) return false

      if (!this.dataplants.length && !trials_left && !this.subscription) return false
      return !this.userOrganizationsHasSubscriptionsNotInTrial || (steps_displayed?.connect_data || steps_displayed?.invite_members) || !this.dataplants.length
    },
    requestToJoinPending () {
      return this.organization?.pending_request
    }
  },
  watch: {
    async $route () {
      await this.loadDataplants()
    }
  },
  async mounted () {
    await this.loadDataplants()
  },
  async destroyed () {
    clearTimeout(this.timemoutdp)
  },
  methods: {
    async loadDataplants () {
      this.timemoutdp = null
      this.loading = true
      await this.watchDataplant()
      if (!this.organization) {
        return this.$router.push('/home')
      }
      await this.organization.loadSubscription()
      this.loading = false
    },
    async watchDataplant () {
      clearTimeout(this.timemoutdp)
      try {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', {
          organizationId: this.$route.params.organizationId,
          ignoreCache: true
        })
      } catch (err) {
        console.error(err)
      }
      this.timemoutdp = setTimeout(() => {
        this.watchDataplant()
      }, 30000)
    }
  }
}
</script>

<style lang="less">
.dataplants {
  .dataplants-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
