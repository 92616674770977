<template lang="pug">
  .dataplant-block(@mouseover="hovering = true" @mouseleave="hovering = false")
    fp-loader(v-if="loadingReactivate")
    .header
      .name
        .days-left-expiration(
          v-if="hasDayIndicator"
          :style="{ background: dayBackground }"
        )
          .days-left(v-if="!isExpired")
            .number(v-html="formatExpirationTime")
            .text(:style="formatExpirationStyle") {{ formatExpirationText }}
          .expiration(v-if="isExpired")
            i.fp4.fp4-triangle-exclamation-small
            .text(:style="formatExpirationStyle") {{ $t('home.dataplant.expired')}}
        h2.name {{dataplant.name}}
        i.icon.fp4.fp4-trash-can-small(
          :id="`${dataplant.name}`"
          v-tooltip="$t('home.dataplant.remove')"
          @click="deleteDataplant()"
          v-if="$acl(organization._id).can('core', 'dataplants', 'delete', { dataplantId: dataplant.dataplant_id })"
        )
      .informations
        .fpu
          .label FPU
          .value {{fpu}}
        .storage
          .label {{ $t('home.dataplant.storage') }}
          .value {{storage}}
    .body
      home-block-chart(
        :dataplant="dataplant"
        :subscription="subscription"
        v-if="!dataplant.isAborted"
        :hover='hovering'
      )
      i.aborted.fp4.fp4-triangle-exclamation-small(v-if="dataplant.isAborted",v-tooltip="$t('home.dataplant.aborted.tooltip')")
    .footer
      .created-at
        .label {{ $t('home.dataplant.created_at') }}
        .value {{ dataplantCreatedAt }}
      .users
        .label {{ $t('home.dataplant.users') }}
        .value {{ users.length }}

    //- Add Disable screen if dp needs mfa and user has no mfa set
    .block-mfa(v-if="dataplant.block_mfa")
      .header
        .text {{dataplant.name}}
        .actions(:style="{ 'padding-right': dataplant.hasBeenCreatedInLast15MIn ? '27px' : '0' }")
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id).can('core', 'dataplants', 'delete', { dataplantId: dataplant.dataplant_id })"
          )
      .body
        .description {{ $t('home.dataplant.block-mfa.description') }}
        fpui-button(
          color="blue-flash"
          @click="goToProfile()"
        ) {{ $t('home.dataplant.block-mfa.go_to_profile') }}

    //- Add disable screen if subscription paused or cancelled
    .subscription-inactive(v-if="subscription && ['cancelled', 'paused'].includes(subscription.status)")
      .header
        .text {{dataplant.name}}
        .actions
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id).can('core', 'dataplants', 'delete', { dataplantId: dataplant.dataplant_id })"
          )
      .body
        .title {{ $t('home.dataplant.inactive_subscription.title' )}}
        .description {{ $t('home.dataplant.inactive_subscription.description') }}
        fpui-button(
          color="blue-flash",
          @click="reactivateSubscription",
        ) {{ $t('home.dataplant.inactive_subscription.reactivate') }}
        //- .or {{ $t('home.dataplant.or') }}
        //- .link(@click="openLink") {{ $t('home.dataplant.get_in_touch') }}

    //- Add disable screen if subscription is in_trial and trial_ended
    .subscription-inactive(v-if="subscription && ['in_trial'].includes(subscription.status) && isExpired")
      .header
        .text {{dataplant.name}}
        .actions
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id).can('core', 'dataplants', 'delete', { dataplantId: dataplant.dataplant_id })"
          )
      .body
        .title {{ $t('home.dataplant.trial_ended_subscription.title' )}}
        .description {{ $t('home.dataplant.trial_ended_subscription.description') }}
        fpui-button(
          color="blue-flash",
          @click="updateTrialToRealPlan",
        ) {{ $t('home.dataplant.trial_ended_subscription.reactivate') }}
        .or {{ $t('home.dataplant.or') }}
        .link(@click="openLink") {{ $t('home.dataplant.link') }}

    //- Disabled access to dp if voucher has expired
    .dataplant-expired(v-if="dataplant.voucher && daysLeft < 0")
      .header
        .text {{ dataplant.name }}
        .actions
          i.fp4.fp4-trash-can-small(
            v-tooltip="$t('home.dataplant.remove')"
            @click='deleteDataplant()'
            v-if="$acl(organization._id).can('core', 'dataplants', 'delete', { dataplantId: dataplant.dataplant_id })"
          )
      .body
        .title {{ $t('home.dataplant.voucher.expired' )}}
        .description {{ $t('home.dataplant.voucher.expired.description') }}
        fpui-button(
          v-if="!requestGetMoreSent"
          color="blue-flash",
          @click="getMore",
        ) {{ $t('home.dataplant.get_more') }}
        .request-sent(
          v-if="requestGetMoreSent"
        ) {{ $t('home.dataplant.get_more_sent') }}
        .or {{ $t('home.dataplant.or') }}
        .link(@click="openLink") {{ $t('home.dataplant.link') }}
</template>

<script>
import moment from 'moment'
import DataplantDeletionEmailModal from '@/core/components/Dataplant/DataplantDeletion/EmailModal.vue'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import HomeBlockChart from './Chart'

export default {
  components: {
    HomeBlockChart
  },
  props: {
    dataplantId: {
      type: String,
      required: true
    },
    subscription: {
      type: Object,
      required: false,
      default: () => null
    },
    organization: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      hovering: false,
      loadingReactivate: false,
      requestGetMoreSent: false
    }
  },
  computed: {
    dataplant () {
      return this.$store.getters.DATAPLANT_BY_ID(this.dataplantId)
    },
    dataplantCreatedAt () {
      if (!this.dataplant.created_at) return '-'
      return moment(this.dataplant.created_at).format('YYYY-MM-DD')
    },
    fpu () {
      let total = 0
      for (const sg in this.dataplant.budget) {
        total += this.dataplant.budget[sg].instances * this.dataplant.budget[sg].fpu
      }
      return total
    },
    storage () {
      // TODO Get the real storage data.
      return '-'
    },
    users () {
      return this.organization?.accounts || this.organization?.users
    },
    hasDayIndicator () {
      return this.dataplant.voucher || (this.subscription && ['non_renewing', 'cancelled', 'paused', 'in_trial'].includes(this.subscription.status))
    },
    daysLeft () {
      if (this.subscription) {
        if (this.subscription.status === 'in_trial' && this.subscription.trial_end) return moment(this.subscription.trial_end * 1000).diff(moment(), 'days') + 1
        return moment(this.subscription.current_term_end * 1000).diff(moment(), 'days') + 1
      }
      return moment(this.dataplant.valid_until).diff(moment(), 'days') + 1
    },
    hoursLeft () {
      if (this.subscription) {
        if (this.subscription.status === 'in_trial' && this.subscription.trial_end) return moment(this.subscription.trial_end * 1000).diff(moment(), 'hours') + 1
        return moment(this.subscription.current_term_end * 1000).diff(moment(), 'hours') + 1
      }
      return moment(this.dataplant.valid_until).diff(moment(), 'hours') + 1
    },
    isExpired () {
      if (this.subscription && this.subscription.status === 'in_trial' && this.subscription.trial_end) return moment(this.subscription.trial_end * 1000) < moment()
      if (!this.dataplant.voucher || !this.dataplant.valid_until) return false
      return moment(this.dataplant.valid_until) < moment()
    },
    formatExpirationTime () {
      if (this.subscription && this.subscription.status === 'cancelled') return '<i class="fp4 fp4-triangle-exclamation icon"></i>'
      return this.daysLeft > 1 ? this.daysLeft : this.hoursLeft
    },
    formatExpirationText () {
      if (this.subscription && this.subscription.status === 'cancelled') return this.$t('home.dataplant.inactive_subscription')
      return this.daysLeft > 1 ? this.$t('home.dataplant.days_left') : this.hoursLeft > 1 ? this.$t('home.dataplant.hours_left') : this.$t('home.dataplant.hour_left')
    },
    formatExpirationStyle () {
      if (this.isExpired) {
        return {
          bottom: 25
        }
      }
      const lang = this.$i18n.locales()
      if (this.daysLeft > 1) {
        return {
          left: lang === 'en' ? 8 : 0
        }
      }
      if (this.hoursLeft > 1) {
        return {
          left: lang === 'en' ? 3 : 0
        }
      }
      return {
        left: lang === 'en' ? 5 : 0
      }
    },
    dayBackground () {
      if (this.subscription) {
        if (['non_renewing', 'paused'].includes(this.subscription.status)) return '#FFA312'
        if (this.subscription.status === 'cancelled') return '#F62172'
      }
      if (this.isExpired) return '#FFA312'
      return '#00CCF9'
    }
  },
  methods: {
    getMore () {
      this.requestGetMoreSent = true
      this.$analytics.track('Dataplant expired Get More', {
        organization_id: this.subscription?.organization_id,
        dataplant_id: this.dataplantId
      })
    },
    openLink () {
      window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=nDWzgpGTfE2g8oFnUal2QQ9d-P8xWc5AgNsykYKKK71UOVpZT1RUMFBRUk5BWjNLSzBCNVhDSVgyNSQlQCN0PWcu')
    },
    deleteDataplant () {
      if (!this.$acl(this.organization._id).can('core', 'dataplants', 'delete', { dataplantId: this.dataplant.dataplant_id })) return
      this.$modal.show(DataplantDeletionEmailModal, {
        organization: this.organization,
        dataplant: this.dataplant
      }, {
        height: 'auto',
        width: '500px'
      })
    },
    goToProfile () {
      this.$router.push('/user')
    },
    async reactivateSubscription () {
      if (this.organization?.subscription?.status === 'non_renewing') {
        this.loadingReactivate = true
        try {
          await this.organization.reactivateSubscription()
        } catch (err) {
          this.$fpuiMessageBlock.error(err)
        }
        this.loadingReactivate = false
      } else {
        this.updateTrialToRealPlan()
      }
    },
    async updateTrialToRealPlan () {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organization._id,
        trial: true
      }, {
        height: 'auto',
        width: 900
      })
    }
  }
}
</script>

<style lang="less">
  .dataplant-block {
    box-shadow: 0 6px 12px 0 rgb(52 65 78 / 20%);
    border-radius: 5px;
    height: 431px;
    width: 340px;
    background: white;
    margin: 24px;
    overflow: hidden;
    display: inline-block;
    text-align: left;
    position: relative;

    i.aborted {
      font-size: 100px;
      cursor: pointer;
      color: @red;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    &:hover {
      .header {
        > .name {
          > i.icon {
            opacity: 1;
          }
        }
      }
      .dataplant-expired, .block-mfa, .subscription-inactive {
        display: block;
      }
    }
    .header {
      > .name {
        position: relative;
        border-bottom: 1px solid #F9FAFB;
        height: 68px;
        text-align: center;
        > h2.name {
          font-size: 22px;
          font-weight: 600;
          color: #3E4550;
          margin: 20px 0 10px 0px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
        }
        > i.icon {
          position: absolute;
          right: 23px;
          top: 20px;
          font-size: 28px;
          color: @grey_helper;
          cursor: pointer;
          opacity: 0;
          transition: opacity 100ms linear, color 50ms linear;
          &:hover {
            color: @red;
          }
        }
        .days-left-expiration {
          position: absolute;
          top: 0;
          left: 0;
          height: 64px;
          width: 84px;
          clip-path: polygon(0 0, 0 100%, 100% 0);
          text-align: left;
          z-index: 10;
          .expiration, .days-left {
            color: white;
            position: relative;
            height: 100%;
            width: 100%;
            .number {
              font-size: 22px;
              font-weight: 600;
              position: absolute;
              top: 2px;
              left: 10px;
            }
            i {
              font-size: 20px;
              position: absolute;
              top: 7px;
              left: 8px;
              transform: rotate(-38deg) translate(-8px, -12px);
            }
            .text {
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 1px;
              transform: rotate(-38deg);
              position: absolute;
              bottom: 28px;
              left: 10px;
              padding-left: 6px;
            }
          }
        }
      }
      > .informations {
        height: 50px;
        border-bottom: 1px solid #F9FAFB;
        display: flex;
        .fpu, .storage {
          padding: 5px 0;
          width: 50%;
          height: 100%;
          text-align: center;
          .label {
            color: @grey-chart;
            font-size: 13px;
            font-weight: 600;
            line-height: 17px;
          }
          .value {
            font-weight: 600;
            line-height: 8px;
            margin-top: 2px;
            font-size: 20px;
          }
        }
        .fpu {
          border-right: 1px solid #F9FAFB;
          .value {
            color: #0089c0;
          }
        }
        .storage {
          .value {
            color: #00ccf9;
          }
        }
      }

    }
    .body {
      height: 243px;
      // TODO
    }
    .footer {
      display: flex;
      height: 70px;
      border-top: 1px solid #F9FAFB;
      .created-at, .users {
        width: 50%;
        height: 100%;
        padding-top: 10px;
        text-align: center;
        .label {
          color: @grey-chart;
          line-height: 15px;
          font-size: 12px;
          letter-spacing: 0;
          padding-bottom: 0;
        }
        .value {
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 28px;
          color: #3E4550;
          margin-top: 2px;
          text-transform: uppercase;
        }
      }
      .created-at {
        border-right: 1px solid #F9FAFB;
      }
    }
    .dataplant-expired, .subscription-inactive, .block-mfa {
      display: none;
      background: rgba(62,69,80,0.9);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
      color: white;
      text-align: center;
      .header {
        font-size: 22px;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .text {
          margin-top: 20px;
        }
        .actions {
          i {
            position: absolute;
            right: 23px;
            top: 20px;
            font-size: 28px;
            cursor: pointer;
            transition: opacity 100ms linear, color 50ms linear;
            &:hover {
              color: @red;
            }
          }
        }
      }
      .body {
        margin: auto;
        margin-top: 90px;
        font-size: 14px;
        width: 231px;
        display: flex;
        flex-direction: column;
        align-items: center;
        letter-spacing: 0;
        line-height: 20px;
        .title {
          font-weight: 900;
        }
        .description {
          text-align: center;
        }
        .fpui-button {
          margin-top: 22px;
          width: 196px;
          height: 32px;
        }
        .request-send {
          margin-bottom: 0;
          color: #9EDF10;
          font-weight: bold;
        }
        .or {
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .link {
          text-decoration: underline;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
</style>
