<template lang="pug">
  .organization-plan-overview
    .fp-container
      .fp-column
        .fp-row
          .fp-box
            .box-title {{ $t('organization.plan.overview.subscription.title') }}
            .box-line.flex-line
              .plan-type
                .label {{ $t('organization.plan.overview.subscription.plan_type') }}
                .value(v-if="subscription.plan")
                  img(
                    v-if="subscription && subscription.plan"
                    :src="require(`@/core/assets/img/plan/${subscription.plan}.png`)"
                  )
                  span {{ subscription.plan || 'Custom' | Capitalize }}
              .current-term-end
                .label {{ $t('organization.plan.overview.subscription.current_term_end') }}
                .value(v-if="!isCustom") {{ (subscription.current_term_end || subscription.next_billing_at || subscription.trial_end) * 1000 | date('utc','locale',$t('date.formatter.short')) }}
                .value(v-else) -
              .current-price
                .label {{ $t('organization.plan.overview.subscription.price') }}
                .value(v-if="!isCustom") {{ subscription.plan_unit_price | price(subscription.currency_code) }} {{ $t(`organization.plan.by_${billingPeriod}`) }}
                .value(v-else) -
            hr
            .box-line.flex-line(v-if="subscription && !isCustom")
              .dataplant-included
                .label {{ $t('organization.plan.overview.subscription.dataplant_included') }}
                .value {{ included.dataplant || $t('organization.plan.overview.subscription.dataplant_unlimited') }}
              .storage-included
                .label {{ $t('organization.plan.overview.subscription.storage_included') }}
                .value {{ included.storage | bytes(0) }} {{ $t('organization.plan.by_month') }}
              .egress-included
                .label {{ $t('organization.plan.overview.subscription.egress_included') }}
                .value {{ included.egress | bytes(0) }} {{ $t('organization.plan.by_month') }}
            br
        .fp-row
          .fp-box.fp-no-padding
            .box-title {{ $t('organization.plan.overview.committed.title') }}
            .box-line
              fpui-table(
                :columns='committed.columns'
                :actions='committed.actions'
                :data='committed.data'
              )
              .text-center
                fpui-button(
                  color="blue-flash"
                  @click="addCommitted"
                  auto-width
                ) {{ $t('organization.plan.overview.committed.add_committed_resources') }}
            br
</template>

<script>
import Committeds from './Committeds.vue'
import moment from 'moment'
import Price from '@/shared/filters/Price'
import CannotDoInTrialSubscription from '@/core/components/CannotDoInTrialSubscription.vue'

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  computed: {
    currencyCode () {
      return this.$store.getters.SESSION.currency
    },
    isCustom () {
      return this.subscription?.plan === 'custom'
    },
    account () {
      if (!this.organization?.customerAccountId) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.organization.customerAccountId)
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription
    },
    billingPeriod () {
      return this.subscription?.billing_period_unit
    },
    included () {
      return {
        standard: {
          dataplant: 1,
          storage: 50 * 1024 * 1024 * 1024,
          egress: 50 * 1024 * 1024 * 1024,
          fpu: 0
        },
        premium: {
          dataplant: 2,
          storage: 250 * 1024 * 1024 * 1024,
          egress: 250 * 1024 * 1024 * 1024,
          fpu: 8
        },
        dedicated: {
          storage: 1024 * 1024 * 1024 * 1024,
          egress: 1024 * 1024 * 1024 * 1024,
          fpu: 32
        },
        custom: {
          storage: 0,
          egress: 0,
          fpu: 0
        }
      }[this.subscription?.plan] || {
        storage: 0,
        egress: 0,
        fpu: 0
      }
    },
    committed () {
      return {
        data: this.organization.committeds,
        actions: [],
        columns: [{
          name: 'quantity',
          label: this.$t('organization.plan.overview.committed.quantity'),
          align: 'left',
          target: 'quantity'
        }, {
          name: 'resource_type',
          label: this.$t('organization.plan.overview.committed.resource_type'),
          align: 'left',
          target: (row) => 'FPU-S'
        }, {
          name: 'provider',
          label: this.$t('organization.plan.overview.committed.provider'),
          align: 'left',
          target: (row) => row.plan_id.split('-')[3]
        }, {
          name: 'commitment_end_date',
          label: this.$t('organization.plan.overview.committed.commitment_end_date'),
          align: 'left',
          target: (row) => row.contract_term?.contract_end ? moment(row.contract_term.contract_end * 1000).format(this.$t('date.formatter.short')) : '-'
        }, {
          name: 'created_at',
          label: this.$t('organization.plan.overview.committed.created_at'),
          align: 'left',
          target: (row) => moment(row.contract_term.created_at * 1000).format(this.$t('date.formatter.short'))
        }, {
          name: 'next_billing_at',
          label: this.$t('organization.plan.overview.committed.next_billing_at'),
          align: 'left',
          target: (row) => row.next_billing_at ? moment(row.next_billing_at * 1000).format(this.$t('date.formatter.short')) : '-'
        }, {
          name: 'price',
          label: this.$t('organization.plan.overview.committed.price'),
          align: 'left',
          target: (row) => Price(row.plan_unit_price * row.quantity, this.currencyCode)
        }]
      }
    }
  },
  methods: {
    addCommitted () {
      if ((['active', 'non_renewing'].includes(this.subscription?.status)) || (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')) {
        this.$modal.show(Committeds, {
          organizationId: this.organizationId
        }, {
          height: 'auto',
          width: 1000
        })
      } else {
        this.$modal.show(CannotDoInTrialSubscription, {
          organizationId: this.organizationId,
          text: 'home.dataplant.cannot_add_committed_in_trial_description'
        }, {
          height: '323px',
          width: '472px'
        })
      }
    }
  }
}
</script>

<style lang="less">
.organization-plan-overview {
  hr {
    margin: 5px 0;
  }
  .flex-line {
    display: flex;
    padding: 20px 0;
    > div {
      width: 33.3333%;
    }
    .plan-type {
      .value {
        font-size: 22px;
        font-weight: bold;
        img,span {
          display: inline-block;
          vertical-align: middle;
        }
        img {
          height: 32px;
          width: 32px;
          margin-right: 10px;
        }
      }
    }
    .value {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .button-container button {
    margin: 0 auto;
  }
}
</style>
