<template lang="pug">
  .engine-selection
    .header
      .title {{ $t(title) }}
      .subtitle {{ $t(subtitle) }}

    .content
      .engine(
        v-for="engine in engines"
        @click="setEngine(engine)"
        :class="{ active: engine === item.engine, disabled: isEditing }"
      )
        .content
          .img
            img(
              :src="require(`@/core/assets/img/engines/${engine}@2x.png`)"
              :class="engine"
            )
          .description
            i.fp4.fp4-circle-check
            .name {{ $t(`organization.data_storage_engine.${engine}`)}}
            .engine-description {{ $t(`organization.data_storage_engine.${engine}.description`, '') }}
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => ({}) },
    title: { type: String, default: () => '' },
    subtitle: { type: String, default: () => '' },
    isEditing: { type: Boolean, default: false }
  },
  computed: {
    engines () {
      const engines = this.$store.getters.STORAGE_ENGINES_BY_ORGANIZATION_ID(this.$route.params.organizationId)
      // Get unique list of engines
      return [...new Set(engines.map(engine => engine.engine))]
    }
  },
  mounted () {
    // If no engine set, we set the first one by default
    if (!this.item.engine) this.item.update('engine', this.engines[0], false)
    // Force HA toggle reset when returning to the first step
    // It will prevent displaying wrong information in ProviderInformations
    this.item.update('ha', undefined, false)
  },
  methods: {
    setEngine (value) {
      if (this.isEditing || value === this.item.engine) return
      this.item.update('engine', value, false)

      // Reset value when we update engine of instance
      this.item.update('provider', '', false)
      this.item.update('region', '', false)
      this.item.update('fpu', '', false)
      this.item.update('desired_state.fpu', '', false)
      this.item.update('price', '', false)
    }
  }
}
</script>

<style lang="less">
.engine-selection {
  > .content {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 13px;
    padding: 0 75px;
    margin: 100px 0 90px 0;

    .engine {
      border          : 1px solid rgba(151, 167, 183, 0.21);
      border-radius   : 5px;
      background-color: #FFFFFF;
      cursor          : pointer;
      box-shadow      : 0 2px 4px 0 rgba(52, 65, 78, 0.05);
      display         : inline-block;

      &.active {
        border          : 1px solid #00CCF9;
        background-color: #EFF8FB;
        box-shadow      : 0 4px 11px 0 rgba(62, 69, 80, 0.09);
      }

      .content {
        height     : 125px;
        display    : flex;
        align-items: center;

        .img {
          width       : 105px;
          min-width   : 105px;
          height      : 100%;
          position    : relative;
          border-right: 1px solid rgba(151, 167, 183, 0.21);

          img {
            vertical-align: middle;
            position      : absolute;
            width         : 80%;
            top           : 50%;
            left          : 50%;
            transform     : translate(-50%, -50%);

            &.snowflake {
              width: 60%;
            }
          }
        }

        .description {
          padding   : 17px;
          text-align: left;
          width     : 200px;

          > i {
            color: #9EDF10;
            font-size: 19px;
          }

          .name {
            color        : #3E4550;
            font-size    : 16px;
            line-height  : 20px;
            margin: 4px 0;
          }

          > .engine-description {
            color: #97A7B7;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
