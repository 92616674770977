<template lang="pug">
  .home
</template>

<script>
export default {
  components: {
  },
  computed: {
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    currentUser () {
      return this.$store.getters.SESSION
    }
  },
  watch: {
    organizations () {
      this.setOrganizationIfEmpty()
    }
  },
  async mounted () {
    if (!this.$store.getters.AVAILABLE_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
    await this.setOrganizationIfEmpty()
  },
  methods: {
    async setOrganizationIfEmpty () {
      const org = this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params)
      if (org) {
        this.$router.push(`/home/${org._id}`)
      } else {
        const teammates = this.$store.getters.AVAILABLE_ORGANIZATIONS.length
        if (teammates) this.$router.push('/home/teammates')
        else this.$router.push('/home/onboarding')
      }
    }
  }
}
</script>

<style lang="less">

</style>
