import Vue from 'vue'

export default {
  state: {
    engines: {}
  },
  mutations: {
    STORAGE_ENGINES_INIT (state, { engines, organizationId }) {
      Vue.set(state.engines, organizationId, engines)
    }
  },
  actions: {
    // List engines
    async REFRESH_STORAGE_ENGINES_BY_ORGANIZATION ({ commit }, organizationId) {
      const data = await Vue.api.STORAGE.engines.listByOrganization(organizationId)
      commit('STORAGE_ENGINES_INIT', data)
    }
  },
  getters: {
    // Return all engines
    STORAGE_ENGINES (state) {
      return state.engines
    },
    // Return engines for an organization
    STORAGE_ENGINES_BY_ORGANIZATION_ID (state) {
      return (organizationId) => {
        return state.engines[organizationId] || []
      }
    }
  }
}
