<template lang="pug">
.users
  fp-title(
    :border="false"
    :parent="$t('organization.users')"
  )
    .actions-buttons
      fpui-input-search(
        v-model='searchValue'
      )
      fpui-button(
        :auto-width="true"
        color='blue-flash'
        icon='fp4 fp4-plus'
        icon-left
        @click="add"
      ) {{ 'users.new' | translate }}
  .request-join(v-if="requests.length")
    .request(v-for="request in requests")
      .image
        img(:src="getUserPicture(request)")
      .informations
        .user
          .name {{ (request.account||request.user).profile[0].given_name || (request.account||request.user).profile[0].family_name }}
          .email {{ (request.account||request.user).email }}
        .actions
          fpui-button(
            color="green"
            @click="acceptJoin(request)"
          ) Approve
          fpui-button(
            color="white"
            @click="deleteJoin(request)"
          ) Deny
  .resource-content
    fp-loader(v-if='loading')
    fpui-tree-view(
      :no-header='true'
      :columns='columns'
      :value="value"
      :actions="actions"
      :draggable="false"
      :beforeRemove="beforeRemove"
      :placeholder="{image: null,iconButton: 'fp4 fp4-plus', text: $t('users.treeview.placeholder'), button: $t('users.new')}"
      :parser="{ id: 'email', path: 'tags.path', name: 'display_name' }"
      icon="fp4 fp4-users"
      type="users"
      @add="add"
      @remove="remove"
      @edit="edit"
    )
</template>

<script>
import CellName from './CellName'
import CellStatus from './CellStatus'
import ConfirmModal from '@/shared/components/modals/Confirm'
import moment from 'moment'

import neutralImg from '@/core/assets/img/users/neutral@2x.png'
import man1Img from '@/core/assets/img/users/man_1@2x.png'
import man2Img from '@/core/assets/img/users/man_2@2x.png'
import woman1Img from '@/core/assets/img/users/woman_1@2x.png'
import woman2Img from '@/core/assets/img/users/woman_2@2x.png'

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      searchValue: '',
      loading: false,
      requests: []
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    roleInOrganization () {
      if (this.organizationId === '') return ''
      return this.currentUser?.organizations.find(orga => orga._id === this.organizationId)?.role || ''
    },
    value () {
      const usersFromOrgaId = this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)?.accounts || this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)?.users || []
      if (!this.searchValue) return usersFromOrgaId
      return usersFromOrgaId.filter(user => {
        if (user.email.toLowerCase().includes(this.searchValue.toLowerCase())) return true
        const usr = user?.account || user?.user
        const mainProfile = usr?.profile.find(p => p.authentication === '5910ea60000eaf1000000001')
        const name = `${mainProfile?.given_name} ${mainProfile?.family_name}`.toLowerCase()
        return name.includes(this.searchValue.toLowerCase())
      })
    },
    columns () {
      return [
        {
          label: this.$t('organization.users.name'),
          grow: 2,
          sortable: (row) => this.fullName(row),
          target: CellName
        },
        {
          label: this.$t('organization.users.role'),
          grow: 1,
          sortable: (row) => row.role,
          target: (row) => {
            return this.$t(`organization.users.role.${row.role}`)
          }
        },
        {
          label: this.$t('organization.users.lastactive'),
          grow: 1,
          sortable: (row) => {
            return row.last_connected_at
          },
          target: (row) => {
            return row.last_connected_at ? moment(row.last_connected_at).format('DD/MM/YYYY HH:mm') : ''
          }
        },
        {
          label: this.$t('organization.users.status'),
          grow: 1,
          target: CellStatus
        }
      ]
    },
    actions () {
      return {
        folder: {
          if () { return false }
        },
        copy: {
          if () { return false }
        },
        remove: { // TODO can't suppr your onw user
          if: (value, selected) => {
            return this.roleInOrganization === 'admin'
          }
        },
        send: {
          icon: 'fp4 fp4-paper-plane',
          tooltip: this.$t('organization.send_invit'),
          order: 1,
          click: async (item) => {
            const confirmResult = await ConfirmModal(this, {
              contentHtml: true,
              title: this.$t('organization.send_invit.modal.title'),
              content: this.$t('organization.send_invit.modal.content', [item.object.email]),
              yes: {
                text: this.$t('organization.send_invit.modal.yes'),
                color: 'blue'
              }
            })
            if (!confirmResult) return
            this.loading = true
            try {
              await this.organization.sendInvitation(item.object.email)
              this.$fpuiMessageBlock.success(this.$t('organization.send_invit.success'))
            } catch (err) {
              console.error(err)
              this.$fpuiMessageBlock.error(err)
            }
            this.loading = false
          },
          if (item) {
            return !item.object.accepted || !item.object._id
          }
        },
        edit: { // TODO if admin edit ok and can't edit your onw user
          if: (value, selected) => {
            return this.roleInOrganization === 'admin'
          }
        }
      }
    }
  },
  async mounted () {
    if (this.roleInOrganization === 'admin') this.requests = await this.organization.listRequests()
  },
  methods: {
    fullName (user) {
      let fullname = ''
      const usr = user.account || user.user
      const mainProfile = usr?.profile.find(p => p.authentication === '5910ea60000eaf1000000001')
      if (mainProfile?.given_name || mainProfile?.family_name) {
        fullname = [mainProfile?.given_name, mainProfile?.family_name].filter(e => e).join(' ')
      } else {
        fullname = user.email
      }
      return fullname
    },
    async beforeRemove (items) {
      const title = `${this.$t('treeview.users.remove.modal.title')}`
      let content = []

      content.push(`<span style="font-size:14px">${this.$t('organization.remove.user.modal_text')} <span style="font-weight:600"> ${items.length > 10 ? this.$t('treeview.remove.modal.items', [items.length > 100 ? '100+' : items.length], items.length) : items.map(item => this.fullName(item))} </span>${this.$t('organization.remove.user.modal_text_end')}</span>`)
      content = ['<div>', ...content, '</div>']
      content = content.join('')

      return {
        contentHtml: true,
        content,
        title
      }
    },
    async remove (item, callback) {
      try {
        const response = await this.organization.removeUser(item.email)
        this.$fpuiMessageBlock.pop('success', response.message)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      callback()
    },
    edit (item) {
      this.$router.push(`/organization/${this.organizationId}/member/${item._id ? item._id : item.email}`)
    },
    add () {
      this.$router.push(`/organization/${this.organizationId}/member`)
    },
    async acceptJoin (request) {
      this.loading = true
      try {
        const response = await this.organization.acceptJoin(request.account || request.user)
        this.requests = this.requests.filter(r => r.user_id !== request.user_id)
        await this.$store.dispatch('LOAD_ORGANIZATIONS')
        this.$fpuiMessageBlock.pop('success', response.message)
        this.$analytics.track('Join organization', {
          organization_id: this.organization._id,
          accepted_user: (request.account || request.user).email
        })
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      }
      this.loading = false
    },
    async deleteJoin (request) {
      this.loading = true
      try {
        const response = await this.organization.deleteJoin(request.account?._id || request.user?._id)
        this.requests = this.requests.filter(r => r.user_id !== request.user_id)
        this.$fpuiMessageBlock.pop('success', response.message)
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      }
      this.loading = false
    },
    getUserPicture (user) {
      const manImages = [man1Img, man2Img]
      const womanImages = [woman1Img, woman2Img]
      const usr = user.account || user.user
      const mainProfile = usr?.profile.find(p => p.authentication === '5910ea60000eaf1000000001')
      if (mainProfile) {
        if (mainProfile.picture) {
          return mainProfile.picture
        }
        if (mainProfile.gender === 'male') {
          return manImages[mainProfile.family_name.length % 2]
        }
        if (mainProfile.gender === 'female') {
          return womanImages[mainProfile.family_name.length % 2]
        }
        if (mainProfile.gender === 'other') {
          return neutralImg
        }
      } else {
        return neutralImg
      }
      return neutralImg
    }
  }
}
</script>

<style lang="less">
  .users {
    height: 100%;
    overflow: hidden;
    .request-join {
      border-top: 1px solid rgba(151,167,183,0.21);
      padding: 14px 43px;
      display: flex;
      flex-wrap: wrap;
      > .request {
        width: 311px;
        height: 105px;
        display: flex;
        align-items: center;
        border: 0.5px solid rgba(151,167,183,0.21);
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(52,65,78,0.05);
        margin: 5px 10px;
        > .image {
          flex: 0 0 105px;
          height: inherit;
          position: relative;
          height: 103px;
          > img {
            height: inherit;
            object-fit: cover;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }
        > .informations {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          flex: 0 0 206px;
          padding-left: 18px;
          .user {
            padding-bottom: 11px;
            .name {
              color: #3E4550;
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 20px;
            }
            .email {
              color: #97A7B7;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 18px;
            }
          }
          .actions {
            display: flex;
            .button-container {
              flex: 0 0 50%;
              .btn.fpui-button.v4 {
                width: auto;
              }
            }
          }
        }
      }
    }
    .resource-content {
      height: calc(100% - 87px);
    }
  }
</style>
