<template lang="pug">
.subscription-status

  .has-sub-plan(v-if="subscription && plan")

    a.subscription-status-plan(
      :href="`#/organization/${organization._id}/plan`"
      v-if="status !== 'in_trial' && scheduled !== 'cancelled'"
    )
      img.icon(:src="require(`@/core/assets/img/plan/${plan}.png`)")
      span {{ $t(`subscription.status.${plan}`) }}

    a.subscription-status-message(:href="`#/organization/${organization._id}/plan`")
      .subscription-status-future(v-if="status === 'future'")
        i.fp4.fp4-circle-info
        span(v-html="$t('subscription.status.future',[formatDate(subscription.start_date*1000)])")

      .subscription-status-in-trial(v-if="status === 'in_trial'")
        i.fp4.fp4-circle-info
        span(v-html="$t('subscription.status.in-trial',[formatDate(subscription.trial_end*1000)])")

      .subscription-status-non-renewing(v-if="status === 'non_renewing'")
        i.fp4.fp4-triangle-exclamation
        span(v-html="$t('subscription.status.non-renewing',[formatDate(subscription.current_term_end*1000)])")

      .subscription-status-paused(v-if="status === 'paused'", @click='reactivate()')
        i.fp4.fp4-triangle-exclamation
        span(v-html="$t('subscription.status.paused')")

      .subscription-status-cancelled(v-if="status === 'cancelled'")
        i.fp4.fp4-triangle-exclamation
        span(v-html="$t('subscription.status.cancelled')")

  .no-sub(v-if="organization && !subscription")

    .subscription-status-no-subscription
      span(v-html="$t('subscription.status.none')")
</template>

<script>
import moment from 'moment'
export default {
  computed: {
    organization () {
      const org = this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params)
      return org
    },
    subscription () {
      return this.organization?.subscription
    },
    plan () {
      return this.organization?.subscription?.plan
    },
    status () {
      return this.organization?.subscription?.status
    },
    scheduled () {
      return this.organization?.subscription?.scheduled_status
    }
  },
  watch: {
    async organization () {
      if (this.organization) await this.organization.loadSubscription()
    }
  },
  async mounted () {
    if (this.organization) await this.organization.loadSubscription()
  },
  methods: {
    reactivate () {

    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>
<style lang="less">
.subscription-status {
  .has-sub-plan, .no-sub {
    display: flex;
    padding: 2px 15px 2px 0;
    float: left;
    align-items: center;
    height: 34px;
    .subscription-status-plan {
      margin-right: 11px;
      text-decoration: none;
      display: flex;
      img {
        height: 15px;
        margin: 0 4px 0 0;
      }
      span {
        color: #3E4550;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .subscription-status-no-subscription {
      position: relative;
      display: block;
      color: #CBD3DB;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      padding-left: 19px;
      z-index: 0;
      user-select: none;
      &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 14px;
        width: 14px;
        border: 1px solid #B2BECA;
        border-radius: 50%;
      }
      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 5px;
        width: 20px;
        border: 2px solid #F5F9FC;
        background-color: #9BAEBF;
        transform: rotate(-45deg) translate(1px, -5px);
      }
    }
    .subscription-status-message {
      display: block;
      text-decoration: none;
      > div {
        display: flex;
        align-items: center;
      }
      i {
        font-size: 22px;
      }
      i,span {
        display: block;
      }
      span {
        font-size: 12px;
      }
      .subscription-status-non-renewing {
        color: #FFA312;
      }
      .subscription-status-cancelled {
        color: #F62172;
      }
      .subscription-status-paused {
        color: #F62172;
      }
      .subscription-status-future {
        color: #FFA312;
      }
      .subscription-status-in-trial {
        color: #00CCF9;
      }
    }
  }
}
</style>
