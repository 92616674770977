<template lang="pug">
  .dataplant-creation__storage-engine__choices
    fp-loader(v-if="loading")
    .storage-engine__choices
      .storage-engine__choice(
        v-for="engine in options.engines"
        :class='{ active: engine === options.configuration.storageEngine.engine }'
        v-if='canSeeEngine(engine)'
      )
        .storage-engine__choice__unopened(@click="selectEngine(engine)")
          .storage-engine__choice__img
            img(:src="engineImage(engine)")
          .storage-engine__choice__informations
            .storage-engine__choice__informations__title
              span(v-if='engine === "shared"') {{ $t(`dataplant.creation.engine.${engine}`) }}
              span(v-else-if='engine === "default"') {{ $t('dataplant.creation.storage_engine.engine_title.default') }}
              span(v-else) {{ $t('dataplant.creation.storage_engine.engine_title', [$t(`dataplant.creation.engine.${engine}`)]) }}
              .free-trial(v-if="isFreeTrialEngine(engine)")
                i.fp4.fp4-circle-check
                span {{ $t('dataplant.creation.engine.free_trial') }}
            .storage-engine__choice__informations__description
              span(v-if='engine === "shared"') {{ $t('dataplant.creation.storage_engine.engine_description.shared') }}
              span(v-else-if='engine === "default"') {{ $t('dataplant.creation.storage_engine.engine_description.default') }}
              span(v-else) {{ $t('dataplant.creation.storage_engine.engine_description.other', [$t(`dataplant.creation.engine.${engine}`)]) }}
        .storage-engine__choice__instances(v-if="(engine === options.configuration.storageEngine.engine) && (!['shared', 'default'].includes(engine)) && !canBeFreeTrial")
          .storage-engine__choice__instances-list(v-if='filterInstancesByEngine(engine).length')
            .storage-engine__choice__instance(
              v-for='engineInstance in filterInstancesByEngine(engine)'
              :class='{ active: (options.configuration.storageEngine.instance === engineInstance.id || options.configuration.storageEngine.instance === engineInstance.tmpId), available: (engineInstance.status === "Available" || engineInstance.tmpId) }'
            )
              .instance-checkbox(@click='selectInstance(engineInstance)')
              .storage-engine__instance-informations(@click='selectInstance(engineInstance)')
                .instance-informations__title {{ engineInstance.display_name }}
                .instance-informations__details
                  .instance-informations__details-table.instance-informations__details-header(role='rowgroup')
                    .instance-informations__details-row(role='columnheader') {{ $t('dataplant.creation.engine.instance.table.provider') }}
                    .instance-informations__details-row(role='columnheader') {{ $t('dataplant.creation.engine.instance.table.size') }}
                    .instance-informations__details-row(role='columnheader') {{ $t('dataplant.creation.engine.instance.table.region') }}
                  .instance-informations__details-table.instance-informations__details-row(role='rowgroup')
                    .instance-informations__details-row(role='cell')
                      img(:src="providerImage(engineInstance.provider)")
                      span {{ $t(`organization.data_storage_engine.${engineInstance.provider}`) }}
                    .instance-informations__details-row(role='cell')
                      span(v-if="engineInstance.engine !== 'polar-data'") {{ instanceCount(engineInstance) || 1 }} x {{ fpu(engineInstance) }} FPU
                      span(v-else).free {{ $t('dataplant.fpu.free') }}
                    .instance-informations__details-row(role='cell') {{ engineInstance.display_region || '-' }}
              .instance-actions(
                @click='deleteInstance(engineInstance)'
                v-if='engineInstance.tmpId'
              )
                i.fp4.fp4-trash-can
          .storage-engine__choice__instances-list(v-else)
            .storage-engine__choice__no-instance
              span {{ $t('dataplant.creation.engine.instance.empty') }}
          .storage-engine__choice__instances-actions
            .instances-action(@click="addStorageEngineInstance(engine)")
              i.fp4.fp4-plus
              span {{ $t('dataplant.creation.engine.new') }}
            .instances-action.disabled
              i.fp4.fp4-link
              span {{ $t('dataplant.creation.engine.connect_account') }}
              span.coming-soon {{ $t('dataplant.creation.engine.coming_soon') }}

</template>

<script>
import StorageEngineAddEditInstanceModal from '@/core/components/Organization/Storage/StorageEngineAddEditInstanceModal'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  props: {
    options: { type: Object, required: true },
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    },
    canBeFreeTrial () {
      return this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled'
    }
  },
  methods: {
    engineImage (engine) {
      try {
        return require(`@/core/assets/img/engines/${engine}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/engines/default.png')
      }
    },
    providerImage (provider) {
      try {
        return require(`@/core/assets/img/providers/${provider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    },
    selectEngine (engine) {
      this.$emit('update', { 'configuration.storageEngine.engine': engine })
      if (this.canBeFreeTrial && engine !== 'shared') return
      const instances = this.filterInstancesByEngine(engine)
      if (instances.length) this.selectInstance(instances[0])
      else this.selectInstance()
    },
    addStorageEngineInstance (engine) {
      const newInstance = this.$api.STORAGE.instances.new({ organization_id: this.organizationId })
      newInstance.update('display_name', this.$t('dataplant.creation.engine.new'), false)
      this.$modal.show(StorageEngineAddEditInstanceModal, {
        item: newInstance,
        engine: engine,
        doNotCreate: true,
        lightMode: true,
        afterComplete: (instance) => this.temporaryInstance(instance),
        organizationId: this.organizationId
      }, {
        height: 'auto',
        width: 620,
        clickToClose: false
      })
    },
    temporaryInstance (instance) {
      // This is used to create the instance when the user confirms
      // the Dataplant creation
      const instances = this.options.instances
      instance.tmpId = `tmp_instance-${(new Date()).getTime()}`
      instances.push(instance)
      this.$emit('update', { instances: instances })
      this.selectInstance(instance)
    },
    filterInstancesByEngine (engine) {
      return this.options.instances.filter((instance) => {
        return instance.engine === engine
      })
    },
    selectInstance (instance) {
      if (!instance) {
        switch (this.options.configuration.storageEngine.engine) {
          case 'default':
            this.$emit('update', {
              'configuration.storageEngine.configuration': this.options.defaultStorageEngineConfigurations.default
            })
            break
          case 'shared':
            this.$emit('update', {
              'configuration.storageEngine.configuration': this.options.defaultStorageEngineConfigurations.shared
            })
            break
          default:
            this.$emit('update', { 'configuration.storageEngine.instance': null })
            this.$emit('update', { 'configuration.storageEngine.configuration': null })
            break
        }
      } else {
        if ((instance.id === this.options.configuration.storageEngine.instance) || (instance.tmpId === this.options.configuration.storageEngine.instance)) return
        if (instance.status === 'Available' || instance.tmpId) {
          this.$emit('update', { 'configuration.storageEngine.instance': instance.id || instance.tmpId })
          this.$emit('update', {
            'configuration.storageEngine.configuration': {
              data_mart: instance.id || instance.tmpId,
              data_prim: instance.id || instance.tmpId,
              data_ml: instance.id || instance.tmpId
            }
          })
        }
      }
    },
    async deleteInstance (instance) {
      // const existingInstances = this.$store.getters.STORAGE_INSTANCES_BY_ORGANIZATION_ID(this.organizationId)
      let confirmDeletion = null
      const title = this.$t('organization.data_storage_engine.remove.modal.title')
      let content = []

      content.push(`<span style="font-size:14px">${this.$t('organization.data_storage_engine.remove.modal.content_head')} <span style="font-weight:600"> ${instance.display_name}</span>${this.$t('organization.data_storage_engine.remove.modal.question_mark')}<br />${this.$t('organization.data_storage_engine.remove.modal.content_tail')} </span><br>`)
      content.push('</br>')
      content = ['<div>', ...content, '</div>']
      content = content.join('')

      confirmDeletion = await ConfirmModal(this, {
        title,
        content,
        contentHtml: true,
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      })
      if (!confirmDeletion) return

      this.loading = true

      try {
        const instances = this.options.instances
        const instanceIndex = instances.findIndex((item) => {
          return item.tmpId === instance.tmpId
        })
        instances.splice(instanceIndex, 1)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    isFreeTrialEngine (engine) {
      return engine === 'shared' && this.canBeFreeTrial
    },
    canSeeEngine (engine) {
      if (engine === 'default' && this.canBeFreeTrial) return false
      return true
    },
    instanceCount (instance) {
      return instance ? (instance.desired_stated?.instance_count || instance?.instance_count) : (this.instance?.desired_state?.instance_count || this.instance?.instance_count)
    },
    fpu (instance) {
      return instance ? (instance?.desired_state?.fpu || instance?.fpu) : (this.instance?.desired_state?.fpu || this.instance?.fpu)
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__storage-engine__choices {
    height: 100%;
    mask-image: linear-gradient(to bottom, transparent 0px, black 45px, black calc(~"100% - 80px"), transparent 100%);
    background-image:url("~@/core/assets/img/dataplantCreation/background_storage_engine@2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    > .storage-engine__choices {
      padding: 43px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      overflow: auto;
      > .storage-engine__choice {
        min-height: 94px;
        width: 504px;
        background-color: rgba(255, 255, 255, 0.75);
        border: 3px solid transparent;
        filter: drop-shadow(0 12px 8px rgba(0, 0, 0, 0.2));
        border-radius: 7px;
        margin-bottom: 15px;
        transition: filter 800ms, border-color 250ms, background-color 500ms;
        cursor: pointer;
        user-select: none;
        &.active, &:hover {
          filter: drop-shadow(0 12px 8px rgba(0, 0, 0, 0.2)) drop-shadow(0 0px 8px rgba(255, 255, 255, 0.5));
          border-color: #00CFFE;
          background-color: #FFF;
        }
        &.active {
          min-height: auto;
        }
        > .storage-engine__choice__unopened {
          display: flex;
          align-items: flex-start;
          padding: 11px 21px 11px 14px;
          > .storage-engine__choice__img {
            flex: 0 0 72px;
            height: 72px;
            border-radius: 10px;
            background-color: #fff;
            margin-right: 19px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            align-self: center;
            img {
              height: 27px;
            }
          }
          > .storage-engine__choice__informations {
            > .storage-engine__choice__informations__title {
              color: #3E4550;
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 20px;
              padding-bottom: 5px;
              display: flex;
              // justify-content: center;
              align-items: center;
              .free-trial {
                margin-left: 8px;
                display: inline-flex;
                align-items: center;
                background-color: #9EDF10;
                border-radius: 8px;
                padding: 2px 6px 2px 2px;
                > span {
                  font-size: 9px;
                  text-transform: uppercase;
                  color: #fff;
                  font-weight: bold;
                  margin-left: 2px;
                  line-height: 0;
                }
                > i {
                  font-size: 10px;
                  color: #fff;
                  vertical-align: 10%;
                }
              }
            }
            > .storage-engine__choice__informations__description {
              color: rgba(62, 69, 80, 0.75);
              font-size: 12px;
              letter-spacing: 0;
              line-height: 18px;
            }
          }
        }
        > .storage-engine__choice__instances {
          background-color: #F9FAFB;
          padding-top: 7px;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
          cursor: auto;
          > .storage-engine__choice__instances-list {
            padding: 10px 14px;
            max-height: 240px;
            overflow-y: scroll;
            mask-image: linear-gradient(to bottom, transparent 0px, black 10px, black calc(~"100% - 10px"), transparent 100%);
            > .storage-engine__choice__no-instance {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #B2BECA;
              line-height: 18px;
              padding: 15px 130px 10px 130px;
              text-align: center;
            }
            > .storage-engine__choice__instance {
              display: flex;
              background-color: #FFF;
              border-radius: 5px;
              box-shadow: 0 1px 4px 0 #E9ECF0;
              padding: 10px 14px;
              border: 2px solid transparent;
              opacity: 0.5;
              transition: border-color 100ms ease-out;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              &.available {
                opacity: 1.0;
              }
              > .instance-checkbox  {
                width: 13px;
                position: relative;
                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 3px;
                  width: 13px;
                  height: 13px;
                  background-color: #B2BECA;
                  border-radius: 50%;
                }
                &:after {
                  content: "";
                  position: absolute;
                  left: 1px;
                  top: 4px;
                  width: 11px;
                  height: 11px;
                  background-color: #F9FAFB;
                  border-radius: 50%;
                  border: 1px solid white;
                  transition: all 100ms;
                }
              }
              &.active, &.available:hover {
                > .instance-checkbox {
                  &:before {
                    background-color: #0089C0;
                  }
                  &:after {
                    background-color: #00CCF9;
                  }
                }
              }
              &.active {
                border-color: #00CCF9;
              }
              > .storage-engine__instance-informations {
                flex: 0 1 100%;
                padding: 0 15px;
                cursor: pointer;
                > .instance-informations__title {
                  color: #3E4550;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 18px;
                }
                > .instance-informations__details {
                  display: block;
                  width: 100%;
                  > .instance-informations__details-table {
                    display: grid;
                    // grid-template-columns: repeat(auto-fill, 33.33333%);
                    grid-template-columns: 50% 25% 25%;
                    grid-template-rows: 100% auto;
                    &.instance-informations__details-header {
                      > .instance-informations__details-row {
                        color: rgba(62, 69, 80, 0.75);
                      }
                    }
                    > .instance-informations__details-row {
                      display: flex;
                      width: 100%;
                      font-size: 12px;
                      letter-spacing: 0;
                      line-height: 18px;
                      align-items: center;
                      > img {
                        height: 15px;
                        margin-right: 4px;
                      }
                      span.free {
                        text-transform: capitalize;
                      }
                    }
                  }
                }
              }
              > .instance-actions {
                cursor: pointer;
                i {
                  color: #CBD3DB;
                  font-size: 14px;
                  transition: color 150ms;
                  &:hover {
                    color: @red;
                  }
                }
              }
            }
          }
          > .storage-engine__choice__instances-actions {
            padding: 17px 16px;
            display: flex;
            > .instances-action {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              &.disabled {
                color: @grey;
                cursor: auto;
              }
              &:not(:last-child) {
                margin-right: 37px;
              }
              i {
                font-size: 20px;
                padding: 5px;
                background-color: @blue_flash;
                border-radius: 50%;
                color: white;
                margin-right: 11px;
                box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0), 0 3px 6px 0 rgba(0,0,0,0.24);
                transition: box-shadow 250ms ease-out;
                &.fp4-plus {
                  padding: 15px 15px;
                  font-size: 23px;
                  position: relative;
                  &:before {
                    position: absolute;
                    left: 5px;
                    top: 3px;
                  }
                }
              }
              &:hover:not(.disabled) {
                i {
                  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0), 0 0 6px 0 rgba(0,0,0,0.4);
                }
              }
              > span {
                font-size: 11px;
                font-weight: 600;
                text-transform: uppercase;
                &.coming-soon {
                  color: #00CCF9;
                  font-size: 9px;
                  margin-left: 8px;
                  text-transform: unset;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
