<template lang="pug">
.dataplant-dashboard(v-if="dataplant")
  .left
    .data
      dataplant-dashboard-block.dm(
        :dataplant-id="dataplantId"
        :loading="loading"
        :has-access="hasAccess.dm || hasAccess.datastore"
        service="dm",
        line="first"
        name="dashboard.dm.title"
      )
      dataplant-dashboard-block.qb(
        v-if="!displayAM"
        :dataplant-id="dataplantId"
        :loading="loading"
        :has-access="hasAccess.qb"
        :beta="hasAccess.am ? 'am' : null"
        :beta-text="$t('dashboard.new_anayltics_manager')"
        mode="iframe"
        service="qb",
        line="middle"
        name="dashboard.qb.title"
      )
      dataplant-dashboard-block.am(
        v-if="displayAM"
        :dataplant-id="dataplantId"
        :loading="loading"
        :has-access="hasAccess.am"
        :mode="'bulk'"
        service="am",
        line="middle"
        name="dashboard.am.title"
      )
    .processing
      dataplant-dashboard-block.dpe(
        :dataplant-id="dataplantId"
        :loading="loading"
        :has-access="hasAccess.dpe"
        service="dpe",
        name="dashboard.dpe.title"
      )
      dataplant-dashboard-block.ml(
        :dataplant-id="dataplantId"
        :loading="loading"
        :has-access="hasAccess.ml"
        service="ml",
        name="dashboard.ml.title"
      )
    dataplant-dashboard-block.cc(
      :dataplant-id="dataplantId"
      :loading="loading"
      :has-access="hasAccess.cc"
      service="cc"
      name="dashboard.cc.title"
    )
  .right
    .apps
      dataplant-dashboard-block.api(
        :dataplant-id="dataplantId"
        :loading="loadingApps"
        :has-access="hasAccess.appservice"
        mode="manual"
        :error="errors.app"
        line="middle"
        service="api",
        name="dashboard.api.title"
      )
        i.fp4.fp4-plus-circle.create.pointer(@click="create('api')")
        .applications
          .application(v-for="app in applications" v-if="app.type==='api'")
            span.app_name {{app.display_name}}
            i.fp4.fp4-circle-check-small.status(v-if="app.isDeployed")
            .hover
              span.app_name {{app.display_name}}
              .button-container
                fpui-button(
                  v-if="app.isDeployed"
                  color="blue"
                  @click="openApp(app)"
                )
                  a(:href="`https://${dataplant.url_proxy}/${app.app_name}/`" target="_blank") {{ 'dashboard.application.view' | translate }}
                fpui-button(
                  color="blue"
                  @click="editApp(app)"
                )
                  a(:href="`#/${app.type}/${dataplant.dataplant_id}/${app.app_name}`") {{ 'dashboard.application.edit' | translate }}

      dataplant-dashboard-block.app(
        :dataplant-id="dataplantId"
        :loading="loadingApps"
        :error="errors.app"
        :has-access="hasAccess.appservice"
        mode="manual"
        line="last"
        service="app",
        name="dashboard.app.title"
      )
        i.fp4.fp4-plus-circle.create.pointer(@click="create('app')")
        .applications
          .application(v-for="app in applications" v-if="app.type==='app'")
            span.app_name {{app.display_name}}
            i.fp4.fp4-circle-check-small.status(v-if="app.isDeployed")
            .hover
              span.app_name {{app.display_name}}
              .button-container
                fpui-button(
                  v-if="app.isDeployed"
                  color="blue"
                  @click="openApp(app)"
                )
                  a(:href="`https://${dataplant.url_proxy}/${app.app_name}/`" target="_blank") {{ 'dashboard.application.view' | translate }}
                fpui-button(
                  color="blue"
                  @click="editApp(app)"
                )
                  a(:href="`#/${app.type}/${dataplant.dataplant_id}/${app.app_name}`") {{ 'dashboard.application.edit' | translate }}

    dataplant-dashboard-block.iam(
      :dataplant-id="dataplantId"
      :loading="loading"
      :has-access="hasAccess.iam"
      :error="errors.session"
      service="iam",
      name="dashboard.iam.title"
    )
</template>

<script>
import moment from 'moment'
import AppServiceCreateModal from '@/core/components/AppService/CreateModal'
import Store from '@/shared/components/store'
import DataplantDashboardBlock from './DashboardBlock.vue'

export default {
  components: {
    DataplantDashboardBlock
  },
  props: {
    dataplantId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      hasAccess: {
        dpe: true,
        ml: true,
        iam: true,
        dm: true,
        am: true,
        appservice: true,
        qb: true,
        datastore: true,
        cc: true
      },
      session: null,
      applications: null,
      errors: {
        app: null,
        session: null
      },
      loading: true,
      loadingApps: true
    }
  },
  computed: {
    displayAM () {
      // we display the QBv3 only for mutualized dp and dp created after the release of QB v3 or if dp has tag 'analytics_manager'
      // 13 january 2022 is the date of QB v3 release
      const createdAfterRelease = moment(this.dataplant.created_at).isAfter('2022-01-13')
      const isMutualized = this.dataplant.offer === 'mutualized'

      return (createdAfterRelease && isMutualized) || this.dataplant?.tags?.includes('analytics_manager')
    },
    dataplant () {
      return this.$store.getters.DATAPLANT_BY_ID(this.dataplantId)
    }
  },
  watch: {
    async 'dataplant.dataplant_id' (dataplantId) {
      await this.loadDashboard()
    }
  },
  async mounted () {
    await this.loadDashboard()
  },
  methods: {
    createSecondStep (type, tpl) {
      const context = this
      this.$modal.show(AppServiceCreateModal, {
        template: tpl.id,
        applications: context.applications,
        type,
        onCreate (app) {
          this.applications.push(app)
          this.$router.push(`/${app.type}/${context.dataplantId}/${app.app_name}`)
        }
      }, {
        width: 980,
        height: 'auto'
      })
    },
    create (type) {
      const context = this
      this.$modal.show(Store, {
        name: this.$t(`appservice.store.add.${type}`),
        type: `${type}.templates`,
        items: {
          import: {
            name: this.$t('appservice.store.import.name'),
            description: this.$t('appservice.store.import.description'),
            long_description: this.$t('appservice.store.import.long_description'),
            category: [
              'custom'
            ],
            owner: 'forepaas',
            image: require('@/core/components/AppService/assets/store_custom.png')

          }
        },
        onSelect (tpl) {
          context.createSecondStep(type, tpl)
          this.$emit('close')
        }
      }, {
        width: 980,
        height: 640
      })
    },
    openApp (app) {
      window.open(`https://${this.dataplant.url_proxy}/${app.app_name}/`)
    },
    editApp (app) {
      this.$router.push(`/${app.type}/${this.dataplant.dataplant_id}/${app.app_name}`)
    },
    async loadAcls () {
      await Promise.all(Object.keys(this.hasAccess).map(async srv => {
        this.hasAccess[srv] = await this.$api.IAM.can(srv)
      }))
    },
    async loadDashboard () {
      if (!this.dataplant) {
        this.$router.push('/home')
        return
      }
      this.$analytics.track('Open Dataplant', {
        dataplant_id: this.dataplantId
      })

      // Open create api modal when there is a query new_appservice === 'api'
      if (this.$route.query?.new_appservice === 'api') {
        this.create('api')
      }

      this.loading = true
      this.session = null
      this.applications = null
      this.loadingApps = true
      this.errors = {
        app: null,
        session: null
      }
      // Connect to the IAM, to refresh or generate a session inside
      try {
        const { token } = await this.$api.KING.users.me()
        this.session = await this.$api.IAM.reply(token.access_token)
        this.loading = false
        await this.loadAcls()
        await this.loadApps()
      } catch (err) {
        this.errors.session = err
        console.error(err)
      }
      this.loading = false
      this.loadingApps = false
    },
    async later (ms = 5000) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async loadApps () {
      try {
        this.applications = this.hasAccess.appservice ? await this.$api.APPSERVICE.applications.list({ tags: true }) : []
        this.errors.app = null
      } catch (err) {
        this.errors.app = err
        console.error(err)
      }
      this.loadingApps = false
      // Auto refresh list
      await this.later(60000)
      return this.loadApps()
    }
  }
}
</script>

<style lang="less">
  .dataplant-dashboard {
    padding: 20px;
    height: 100%;
    .left,.right {
      width: 50%;
      height: 100%;
      display: inline-block;
      vertical-align: top;
    }
    .app,.api {
      .create {
        display: block;
        color: #3E4550;
        font-size: 64px;
        position: absolute;
        left:50%;
        z-index: 2;
        top: 210px;
        transform: translateX(-50%);
      }
      .applications {
        position: absolute;
        top: 260px;
        text-align: left;
        margin : 20px 0 0;
        left: 0;
        z-index: 2;
        right: 0;
        bottom: 0;
        overflow: auto;
        .application {
          position: relative;
          color: #3E4550;
          font-size: 13px;
          line-height: 40px;
          overflow: hidden;
          height: 40px;
          .app_name {
            display: inline-block;
            padding-left: 35px;
            overflow: hidden;
            white-space: nowrap;
          }
          i {
            float: right;
            padding-right: 35px;
            &.fp4-circle-check-small {
              color: #9EDF10;
              font-size: 20px;
              line-height: 40px;
            }
          }
          .hover {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top:0;
            bottom:0;
            left:100%;
            width: 100%;
            transition: all 300ms;
            background: #E6F8FD;
            padding-right: 5px;
            text-align: right;
            .button-container {
              margin: 5px;
              flex-wrap: nowrap;
              white-space: nowrap;
              display: inline-block;
              .fpui-button {
                padding: 0 20px;
                font-weight: normal;
                font-size: 13px;
                width: auto;
                margin: 0;
                border-radius: 3px;
                height: 30px;
                line-height: 30px;

                a {
                  color: white;
                }
              }
            }
          }
          &:hover {
            .hover {
              left: 0;
            }
          }
        }
      }
    }
    .left {
      .data {
        height: 40%;
        .qb, .dm, .am {
          width: 50%;
          display: inline-block;
          height: 100%;
        }
      }
      .processing {
        height: 40%;
        .dpe, .ml {
          width: 50%;
          display: inline-block;
          height: 100%;
        }
      }
      .infra, .cc {
        height: 20%;
        .name {
          left: 35%;
        }
        img {
          left: 60%;
          width: 100px;
        }
      }
    }
    .right {
      .apps {
        height: 80%;
        .api,.app {
          width: 50%;
          height: 100%;
          display: inline-block;
        }
      }
      .iam {
        height: 20%;
        .name {
          left: 35%;
          width: 150px;
        }
        img {
          left: 60%;
          width: 100px;
        }
        .error-container {
          .stack-container {
            max-height: 90px;
          }
        }
      }
    }
  }
</style>
