<template lang="pug">
  .dataplant-selector(
    @click="$event.stopPropagation()"
  )
    .closed(v-if="!opened",@click="open")
      .name-container
        span.name(v-if="item") {{item.name}}
      i.fp4.fp4-chevron-down
    .opened(
      v-if="opened"
      :style="{ top: fromDataplant ? '-33px' : '0' }"
    )
      .search-container
        fpui-input-search(
          v-model="search"
          autofocus
        )
      ul.items
        li(v-for="i in items")
          .item(
            @click="goToItem(i)"
            :class="{ active: isActive(i) }"
          )
            span.name {{i.name}}
            i.fp4.fp4-hourglass(
              v-if="!fromDataplant && requestToJoinPending(i)"
            )
        li.buttons
          fpui-button(
            color="blue-flash"
            @click="newItem"
          ) {{ $t(fromDataplant ? 'dataplant.new' : 'organizations.new') }}
</template>

<script>
import DataplantCreationFullscreen from '@/core/components/Dataplant/DataplantCreationFullscreen/DataplantCreationFullscreen.vue'
import CannotDoInTrialSubscription from '@/core/components/CannotDoInTrialSubscription.vue'

export default {
  props: {
    fromOrganization: { type: Boolean, default: false },
    fromDataplant: { type: Boolean, default: false },
    fromSupport: { type: Boolean, default: false }
  },
  data () {
    return {
      opened: false,
      search: ''
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters.SESSION
    },
    organizations () {
      return this.$store.getters.ORGANIZATIONS.filter(organization => {
        if (!this.search) return true
        if ((organization._id + '-' + organization.name).toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
        if (this.dataplantsByOrganizationId(organization._id).length) return true
        return false
      }).sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
    },
    dataplant () {
      if (this.$route.params.dataplantId) return this.$store.getters.DATAPLANT_BY_ID(this.$route.params.dataplantId)
      return null
    },
    organization () {
      return this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params) || this.$store.getters.ORGANIZATION_BY_ID(this.dataplant.organization_id)
    },
    subscription () {
      return this.organization?.subscription
    },
    items () {
      if (this.fromDataplant) return this.dataplantsByOrganizationId(this.dataplant.organization_id)
      return this.organizations
    },
    item () {
      if (this.fromDataplant) return this.dataplant
      return this.organization
    }
  },
  async mounted () {
    window.addEventListener('click', () => {
      this.opened = false
    })
    if (!this.subscription) await this.organization.loadSubscription()
  },
  methods: {
    open () {
      this.opened = true
    },
    dataplantsByOrganizationId (organizationId) {
      const organization = this.$store.getters.ORGANIZATION_BY_ID(organizationId)
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(organizationId).filter(dataplant => {
        if (!this.search) return true
        if ((organization._id + '-' + organization.name).toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
        if ((dataplant.id + '-' + dataplant.name).toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
        return false
      })
    },
    isActive (item) {
      if (this.fromDataplant) return item.id === this.item.id
      return item._id === this.item._id
    },
    newItem () {
      this.opened = false
      if (this.fromDataplant) {
        if ((['active', 'non_renewing'].includes(this.subscription?.status)) || (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')) {
          if (!this.$acl(this.organization._id).can('core', 'dataplants', 'create')) return
          this.$modal.show(DataplantCreationFullscreen, {
            organizationId: this.organization._id,
            afterConfirm: () => {
              this.$router.push(`home/${this.organization._id}`)
            }
          }, {
            height: '100%',
            width: '100%',
            adaptative: true,
            classes: []
          })
        } else {
          this.$modal.show(CannotDoInTrialSubscription, {
            organizationId: this.organization._id
          }, {
            height: '323px',
            width: '472px'
          })
        }
      } else {
        this.$router.push('/home/new')
      }
    },
    goToItem (item) {
      this.opened = false
      if (this.fromDataplant) {
        this.$router.push(`/dataplant/${item.id}`)
      } else {
        this.$store.commit('SET_ACTIVE_ORGANIZATION', item._id)
        if (this.fromOrganization && !this.requestToJoinPending(item)) this.$router.push(`/organization/${item._id}`)
        else if (this.fromSupport && !this.requestToJoinPending(item)) this.$router.push(`/support/${item._id}`)
        else this.$router.push(`/home/${item._id}`)
      }
    },
    requestToJoinPending (organization) {
      return organization.pending_request
    }
  }
}
</script>

<style lang="less">
.dataplant-selector {
  float:left;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  margin-left: 14px;
  user-select: none;
  color: #3E4550;
  position: relative;
  ul, li {
    list-style: none;
    padding:0;
    margin:0;
    text-align: left;
  }
  .items {
    max-height: 500px;
    overflow: auto;
  }
  .item {
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    padding-left: 20px;
    color: #3E4550;
    font-weight: 400;
    background:white;
    z-index: 2;
    position: relative;
    border-top: 1px solid #F6F9FC;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    margin: 0;
    .name {
      max-width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .fp4-hourglass {
      color: #FFA312;
      font-size: 24px;
    }
    &.active {
      color: #0089C0;
    }
    &:hover {
      background: rgba(246, 249, 252);
      box-shadow: inset 0px -1px 0px #F6F9FC;
    }
    i.fp4 {
      line-height: 50px;
      padding: 0 13px;
      float: right;
    }
  }
  .buttons {
    border-top: 1px solid #F6F9FC;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    .button-container {
      display: inline-block;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
  .opened {
    position: absolute;
    top: 0;
    border: 0px;
    min-width: 263px;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
    border-radius: 7px;
    .search-container {
      padding: 10px 20px;
    }
  }
  .closed {
    cursor: pointer;
    border-radius: 5px;
    line-height: 34px;
    height: 26px;
    position: relative;
    max-width: 300px;
    display: flex;
    align-items: center;
    .name-container {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      .name {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 600;
        font-size: 15px;
      }
    }
    i {
      float: right;
      line-height: 34px;
      padding: 0 10px;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
</style>
