<template lang="pug">
  .dataplant-creation__summary
    .dp-personalize-container
      .provider-location-informations
        .provider-logo
          img(:src='providerImage')
        .provider-name {{ providerName }}
        .provider-region {{ regionName }}

      .dataplant-summary
        .row.dataplant-name
          label {{ $t('dataplant.creation.summary.dataplant_name') }}
          span {{ dataplantName }}
        .row.dataplant-description
          label Description
          span {{ dataplantDescription }}
        .row.dataplant-tags(v-if="dataplantTags.length")
          label {{ $t('dataplant.creation.dataplant_details.form.tags.label') }}
          .tags
            span.tag(v-for="tag in dataplantTags") {{ tag }}
        .row.dataplant-tags(v-else)
          label {{ $t('dataplant.creation.dataplant_details.form.tags.label') }}
          div
            span.tag -
        .row.dataplant-uri
          label URL
          span {{ dataplantURI }}
        //- .row.dataplant-cost
        //-   label Dataplant cost
        //-   span {{ dataplantCost }}
      .storage-engine-summary
        .row.instance-name
          label {{ $t('dataplant.creation.engine.summary.title') }}
          div
            img(:src="engineImage(storageEngine)")
            span {{ storageEngineInstanceName }}
        .row.storage-size(v-if='storageEngineFPU && storageEngine !== "polar-data"')
          label {{ $t('dataplant.creation.engine.summary.fpu') }}
          span {{ storageEngineFPU }} FPU
        //- .row.storage-cost
        //-   label Storage cost
        //-   span {{ storageEngineCost }}

</template>

<script>
import _find from 'lodash/find'

export default {
  props: {
    options: { type: Object, required: true }
  },
  data () {
    return {
      instance: null
    }
  },
  computed: {
    // provider-related
    providerName () {
      return _find(this.options.providers, { name: this.options.configuration.dataplant.provider })?.display_options?.display_name
    },
    providerImage () {
      try {
        return require(`@/core/assets/img/providers/${this.options.configuration.dataplant.provider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    },
    regionName () {
      return this.options.configuration.dataplant.region?.regionName || '-'
    },
    // dataplant-related
    dataplantName () {
      return this.options.configuration.dataplant.name
    },
    dataplantDescription () {
      return this.options.configuration.dataplant.description || '-'
    },
    dataplantTags () {
      return this.options.configuration.dataplant.tags
    },
    dataplantURI () {
      return `https://${this.options.configuration.dataplant.subdomain}.${this.options.configuration.dataplant.domain}`
    },
    // storage engine-related
    storageEngine () {
      return this.options.configuration.storageEngine.engine
    },
    storageEngineInstanceName () {
      switch (this.options.configuration.storageEngine.engine) {
        case 'shared':
          return this.$t('dataplant.creation.engine.shared')
        case 'default':
          return this.$t('dataplant.creation.engine.summary.default')
        default:
          return this.instance?.display_name || ''
      }
    },
    storageEngineFPU () {
      return this.instance?.fpu ? `${this.instance.desired_state?.instance_count || this.instance.instance_count} x ${this.instance.desired_state?.fpu || this.instance?.fpu}` : null
    },
    storageEngineCost () {
      return 0.05
    }
  },
  mounted () {
    this.instance = {}
    if (['default', 'shared'].includes(this.options.configuration.storageEngine.engine)) {
      this.instance = false
    } else {
      this.instance = _find(this.options.instances, (instance) => {
        return this.options.configuration.storageEngine.instance === (instance.id || instance.tmpId)
      })
    }
  },
  methods: {
    engineImage (engine) {
      try {
        return require(`@/core/assets/img/engines/${engine}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/engines/default.png')
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__summary {
    position: absolute;
    bottom: 155px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 780px;
    z-index: 1;
    .dp-personalize-container {
      background-color: #FFF;
      border-radius: 5px;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.17);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      padding: 31px 0 29px 0;
      z-index: 2;
      opacity: 1;
      > .provider-location-informations {
        position: relative;
        width: 232px;
        flex: 0 0 232px;
        padding: 0 33px 0 10px;
        text-align: right;
        &:after {
          content: "";
          position: absolute;
          width: 1px;
          top: 0;
          bottom: 0;
          right: 7px;
          background-color: #E9ECF0;
        }
        > .provider-logo {
          user-select: none;
          height: 73px;
          img {
            height: 70px;
          }
        }
        > .provider-name {
          color: #3E4550;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 23px;
          min-height: 23px;
        }
        > .provider-region {
          color: #B2BECA;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 17px;
          height: 18px;
        }
      }
      > .dataplant-summary, > .storage-engine-summary {
        width: 100%;
        padding-left: 26px;
        padding-right: 26px;
        position: relative;
        .row {
          &:not(:first-child) {
            margin-top: 11px;
          }
          > label {
            color: rgba(151, 167, 183, 0.5);
            display: block;
            font-weight: 600;
            font-size: 11px;
            text-transform: uppercase;
            line-height: 14px;
            margin-bottom: 5px;
          }
          > span {
            color: #3E4550;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
          }

          &.dataplant-name, &.instance-name {
            margin-bottom: 22px;
            label {
              margin-bottom: 2px;
            }
            span {
              color: #3E4550;
              font-size: 22px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 28px;
            }
            img {
              height: 25px;
              margin-right: 8px;
            }
            &.instance-name {
              > div {
                display: flex;
                align-items: center;
                margin-top: 4px;
              }
            }
          }
          &.dataplant-tags {
            > .tags {
              display: flex;
              flex-wrap: wrap;
              > span.tag {
                background-color: #e4e7ec;
                color: rgba(62, 69, 80, 0.65);
                font-size: 13px;
                line-height: 24px;
                margin: 5px 6px;
                padding: 0 8px;
                border-radius: 12px;
                box-shadow: inset 0 -1px 0 0 rgb(62 69 80 / 10%), inset 0 1px 0 0 rgb(255 255 255 / 30%);
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
      > .dataplant-summary {
        &:after {
          content: "";
          position: absolute;
          width: 1px;
          top: 0;
          bottom: 0;
          right: 7px;
          background-color: #E9ECF0;
        }
      }
    }
  }
</style>
