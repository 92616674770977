<template lang="pug">
.data-storage-engine-table-instances.fp-container
  fp-loader(v-if="loading")
  .fp-column
    .fp-box
      .box-title {{ $t('organization.data_storage_engine.storage_engines_panel') }}
      .box-content
        fpui-table.fp-no-margin(
          :columns='table.columns'
          :actions='table.actions'
          :data='instancesFormatted'
          :pagination='paginationOption'
          row-key='id'
        )
        fpui-button.add-storage-engine(
          color="blue-flash"
          @click="addEditInstance()"
          reverse
          auto-width
          icon-left
          noshadow
          icon="fp4 fp4-plus"
          v-if="$acl(organizationId).can('core', 'storage_instance', 'create')"
        ) {{ $t('organization.data_storage_engine.new_storage_engine') }}
</template>

<script>
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  props: {
    search: { type: String, default: '' },
    instances: { type: Array, default: () => [] },
    configuration: { type: Object, default: () => ({}) },
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      loading: false,
      table: {
        actions: [
          {
            id: 'edit',
            icon: 'fp4 fp4-pencil',
            if: row => row.id !== 'shared' && this.$acl(this.organizationId).can('core', 'storage_instance', 'update'),
            click: (row) => this.addEditInstance(row)
          },
          {
            id: 'remove',
            icon: 'fp4 fp4-trash-can',
            if: row => row.id !== 'shared' && this.$acl(this.organizationId).can('core', 'storage_instance', 'delete'),
            click: (row) => this.removeInstance(row)
          }
        ],
        columns: [
          {
            name: 'name',
            label: this.$t('data_storage_engine.name'),
            target: row => `<span class='${row.id}'>${row.display_name}</span>`,
            // We want to always have the shared on top of the list
            sortable: (a, b) => a.id === 'shared' - b.id === 'shared',
            tooltip: row => row.display_name || null
          },
          {
            name: 'tags',
            label: this.$t('data_storage_engine.tags'),
            target: row => {
              if (!row.tags || row.tags?.length < 1) return '-'
              let tags = []
              if (row.tags.length <= 3) tags = row.tags
              else tags = row.tags.filter((v, i) => i < 2).concat(`+${row.tags.length - 2}`)
              let content = []
              for (const t of tags) {
                content.push(`<div class="tag"> ${t} </div>`)
              }
              content = ['<div class="tags-container">', ...content, '</div>']
              content = content.join('')
              return content
            },
            sortable: true
          },
          {
            name: 'engine',
            label: this.$t('data_storage_engine.engine'),
            target: row => {
              const img = require(`@/core/assets/img/engines/${row.engine}@2x.png`)
              return `
                <span class='${row.id} engine-container'>
                  <img class='engines ${row.engine}' src='${img}' />
                  ${this.$t(`organization.data_storage_engine.${row.engine}`)}
                </span>`
            },
            tooltip: row => this.$t(`organization.data_storage_engine.${row.engine}`),
            sortable: true,
            grow: 0.8
          },
          {
            name: 'location',
            label: this.$t('data_storage_engine.location'),
            target: row => {
              let img = ''
              try {
                img = require(`@/core/assets/img/providers/${row.provider}.png`)
              } catch {}
              return `
                <div class='region'>
                  ${row.provider && `<img class='provider' src='${img}' />`}
                  <div class='provider-label'>${row.display_region}</div>
                </div>`
            },
            tooltip: row => row.display_region,
            sortable: true
          },
          {
            name: 'size',
            label: this.$t('data_storage_engine.size'),
            target: row => {
              if (row.engine === 'polar-data') return this.$t('dataplant.fpu.free')
              const instanceCount = row.desired_state?.instance_count || row.instance_count
              return !instanceCount ? '-' : `${instanceCount} x ${row.desired_state?.fpu || row.fpu} ${this.$t(row.fpu_type)}`
            },
            sortable: true,
            grow: 0.6
          },
          {
            name: 'storage',
            label: this.$t('data_storage_engine.storage'),
            target: row => {
              const lang = this.$i18n.locale()
              let unit = 'GB'
              if (lang === 'fr') unit = 'Go'
              return row.storage ? `${row.storage} ${unit}` : '-'
            },
            sortable: true,
            grow: 0.6
          },
          {
            name: 'status',
            label: this.$t('data_storage_engine.status'),
            target: row => {
              switch (row.status) {
                case 'Available':
                  return "<i class='fp4 fp4-circle-check-small'></i>"
                case 'Maintenance':
                  return "<i class='fp4 fp4-hourglass'></i>"
                case 'Creating':
                  return `<div class="loading-text">
                    <img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />
                    <span class='text'>Creating</span>
                  </div>`
                case 'Deleting':
                  return `<div class="loading-text">
                    <img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />
                    <span class='text'>Terminated</span>
                  </div>`
                case 'Updating':
                  return `<div class="loading-text">
                    <img class='loading' src='${require('@/core/assets/img/ic_spinner.png')}' />
                    <span class='text'>Updating</span>
                  </div>`
                case 'Suspended':
                  return "<i class='fp4 fp4-hourglass'></i>"
                case 'Failed':
                  return "<i class='fp4 fp4-circle-xmark'></i>"
                default:
                  return ''
              }
            },
            sortable: true,
            grow: 0.8
          }
        ]
      }
    }
  },
  computed: {
    instancesFormatted () {
      if (this.search) {
        return this.instances.filter(instance => {
          let check = false
          if (instance?.display_name.toLowerCase().includes(this.search.toLowerCase())) check = true
          return check
        })
      }
      return this.instances
    },
    paginationOption () {
      if (this.pagination) return { perPage: this.pagination }
      return null
    },
    defaultConfiguration () {
      return this.$store.getters.STORAGE_DEFAULT_CONFIGURATION
    }
  },
  methods: {
    addEditInstance (instance = null) {
      this.$emit('addEditInstance', instance)
    },
    async removeInstance (instance) {
      let confirmResult = null
      const title = this.$t('organization.data_storage_engine.remove.modal.title')
      let content = []

      content.push(`<span style="font-size:14px">${this.$t('organization.data_storage_engine.remove.modal.content_head')} <span style="font-weight:600"> ${instance.display_name}</span>${this.$t('organization.data_storage_engine.remove.modal.question_mark')}<br />${this.$t('organization.data_storage_engine.remove.modal.content_tail')} </span><br>`)
      content.push('</br>')
      content = ['<div>', ...content, '</div>']
      content = content.join('')

      confirmResult = await ConfirmModal(this, {
        title,
        content,
        contentHtml: true,
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      })
      if (!confirmResult) return

      this.loading = true

      try {
        instance.status = 'Deleting'
        await instance.remove()
        this.$store.commit('STORAGE_INSTANCES_UPDATE', instance)
        this.$analytics.track('Storage Engine Deleted', {
          instance_id: instance.id,
          organization_id: this.organizationId
        })
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.data-storage-engine-table-instances {
  .fp-box {
    padding-bottom: 13px;
    .sandbox, .shared {
      color: #97a7b7;
    }

    .tags-container {
      display: flex;
      align-items: center;
      overflow: hidden;
      width: 100%;
      .tag {
        background-color: #e4e7ec;
        color: rgba(62, 69, 80, 0.65);
        font-family: "Source Sans Pro";
        font-size: 11px;
        line-height: 14px;
        height: 17px;
        margin: 5px 6px;
        padding: 1px 8px 0 7px;
        border-radius: 8.5px;
        box-shadow: inset 0 -1px 0 0 rgba(62,69,80,0.1), inset 0 1px 0 0 rgba(255,255,255,0.3);
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .engine-container {
      display: flex;
      align-items: center;
      .engines {
        width: 20px;
        margin-top: -2px;
        margin-right: 5px;
        &.snowflake {
          width: 15px;
          margin-left: 3px;
          margin-right: 7px;
        }
      }
    }

    .region {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .provider {
        width: 20px;
        margin-right: 7px;
      }
      .provider-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .fp4-circle-check-small {
      color: #9EDF10;
      font-size: 20px;
    }
    .fp4-circle-xmark {
      color: #F62172;
      font-size: 20px;
    }
    .fp4-hourglass {
      color: #FEBF2F;
      font-size: 20px;
    }

    .fpui-button {
      margin-top: 13px;
      padding-left: 15px !important;
    }

    .loading {
      animation: rotating 1s infinite;
      -webkit-animation: rotating 1s infinite;
      width: 20px;
    }
    .loading-text {
      display: flex;
      align-items: center;
      height: 18px;
      width: fit-content;
      padding-right: 8px;
      border-radius: 9px;
      font-size: 12px;
      color: @white;
      background-color: @blue_flash;
      img {
        animation: rotating 1s infinite;
        -webkit-animation: rotating 1s infinite;
        background-color: @white;
        width: 14px;
        border-radius: 8px;
        margin-left: 2px;
      }
      .text {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 12px;
        margin-left: 5px;
        line-height: 18px;
        letter-spacing: -0.01em;
      }
    }
  }

  @keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
  }
}
</style>
