<template lang="pug">
  .support
    fp-title(
      :parent="$t('tickets.header')"
      :border="false"
    )
      .actions-buttons
        fpui-input-select(
          :clearable="false"
          :value="$store.getters.TICKETS_LIST_FILTER"
          :options="filterOptions"
          @input="v => filterState(v)"
        ).state-select
        fpui-input-search(v-model='search')
        fpui-button(
          :auto-width="true"
          color='blue-flash'
          icon='fp4 fp4-plus'
          icon-left
          @click="add"
        ) {{ 'tickets.new' | translate }}
    fp-loader(v-if="loading")
    .support-content(v-else)
      fpui-tree-view(
        :no-header="true"
        v-if="value"
        :draggable="false"
        :columns='columns'
        icon=""
        :parser='parser'
        :row-classes="rowClasses"
        :value="value"
        :actions="actions"
        :default-sort="4"
        type="support"
        :placeholder="{button:'tickets.new', text:'treeview.placeholder.support'}"
        @edit="edit"
        @add="add"
      )

</template>

<script>

import Date from '@/shared/filters/Date'

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      search: ''
    }
  },
  computed: {
    filterOptions () {
      return [{ label: 'All status', value: null }, { label: 'Open', value: 'open' }, { label: 'Closed', value: 'closed' }]
    },
    parser () {
      return {
        id: 'id',
        path: 'path',
        name: 'title'
      }
    },
    value () {
      return this.$store.getters.SUPPORT_TICKETS
        .filter(ticket => {
          return !this.$store.getters.TICKETS_LIST_FILTER || ticket.state === this.$store.getters.TICKETS_LIST_FILTER
        })
        .filter(ticket => {
          if (!this.search) return true
          return !this.search || ticket.subject.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || (ticket.id + '').toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        })
    },
    columns () {
      return [
        {
          label: '',
          grow: 1,
          target: (item) => {
            if (item.read) return '<span class="no-bubble"></span>'
            return '<span class="bubble"></span>'
          }
        },
        {
          label: this.$t('tickets.id'),
          grow: 2,
          target: (item) => {
            return item.id.substr(-5)
          },
          sortable: true
        },
        {
          label: this.$t('tickets.subject'),
          grow: 12,
          target: 'subject',
          sortable: true
        },
        {
          label: this.$t('tickets.created'),
          grow: 6,
          target: (item) => {
            return Date(item.created_at * 1000, 'utc', 'locale', this.$t('date.formatter'))
          },
          sortable: item => item.created_at
        },
        {
          label: this.$t('tickets.last_active'),
          grow: 6,
          sortable: item => -item.updated_at,
          target: (item) => {
            return Date(item.updated_at * 1000, 'utc', 'locale', this.$t('date.formatter'))
          }
        },
        {
          label: this.$t('tickets.status'),
          grow: 4,
          target: (row) => `<span class="state-${row.state}">${row.state}</span>`,
          sortable: true
        }
      ]
    },
    actions () {
      return {
        copy: {
          if () { return false }
        },
        remove: {
          if () { return false }
        },
        folder: {
          if () { return false }
        }
      }
    }
  },
  async mounted () {
    if (!this.$store.getters.SUPPORT_TICKETS.length) {
      this.loading = true
      await this.$store.dispatch('LOAD_TICKETS')
    }
    this.loading = false
    this.$store.dispatch('WATCH_TICKETS')
  },
  destroyed () {
    this.$store.dispatch('UNWATCH_TICKETS')
  },
  methods: {
    edit (item) {
      this.$router.push(`/support/${this.organizationId}/ticket/${item.id}`)
    },
    add () {
      this.$router.push(`/support/${this.organizationId}/ticket`)
    },
    rowClasses (row) {
      if (!row.read) return ['bold']
      return []
    },
    async filterState (v) {
      this.$store.commit('SET_TICKETS_LIST_FILTER', v)
      this.loading = true
      await this.$store.dispatch('LOAD_TICKETS')
      this.loading = false
      if (v === 'open') this.$store.dispatch('WATCH_TICKETS')
      else this.$store.dispatch('UNWATCH_TICKETS')
    }
  }
}
</script>

<style lang="less">
  @import "~@/shared/styles/_variables.less";
  .support {
    height: 100%;
    min-width: 950px;
    .title-bordered {
      z-index:2;
      position: relative;
    }
    .support-content {
      z-index:1;
      position: relative;
    }
    .actions-buttons {
      width: 700px!important;
      .fpui-input-select {
        margin-right: 10px;
        width: 190px!important;
      }
    }
    .support-content {
      height: calc(~"100% - 87px");
    }
    .bubble,.no-bubble {
      width: 9px;
      display: inline-block;
      height: 9px;
      border-radius: 100%;
      margin-right: 11px;
    }
    .bubble {
      background: @blue_flash;
    }
    .bold .value {
      font-weight: bold;
    }
    .td.fp-tree-cell.first, .th.fp-tree-cell.first {
      padding:0!important;
      span.content {
        width: 100%!important;
        .value {
          width: 100%;
          padding:0!important;
          text-align:right;
        }
      }
    }
    .state-open {
      border-radius: 8.5px;
      color: #FFFFFF;
      font-size: 11px;
      display: inline-block;
      padding: 0 5px;
      text-transform: capitalize;
      line-height: 18px;
      background-color: #FFCD2F;
      box-shadow: inset 0 -1px 0 0 rgba(62,69,80,0.1), inset 0 1px 0 0 rgba(255,255,255,0.3);
    }
    .state-closed {
      border-radius: 8.5px;
      color: rgba(62,69,80,0.65);
      font-size: 11px;
      display: inline-block;
      padding: 0 5px;
      text-transform: capitalize;
      line-height: 18px;
      background-color: #E4E7EC;
      box-shadow: inset 0 -1px 0 0 rgba(62,69,80,0.1), inset 0 1px 0 0 rgba(255,255,255,0.3);
    }
    .state-select {
      margin-right: 10px;
    }
  }
</style>
